import React, { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../../layout/SubHeader/SubHeader';
import Page from '../../../../layout/Page/Page';
import Breadcrumb from '../../../../components/bootstrap/Breadcrumb';
import { demoPages } from '../../../../menu';
import Card, { CardBody } from '../../../../components/bootstrap/Card';
import ModalRegisterUsers from '../../../../components/modals/Users/ModalRegister';
import Button from '../../../../components/bootstrap/Button';
import { DefaultContext } from '../../../../contexts/default';
import SelectEstablishment from '../../../../components/MyCustom/SelectEstablishment';
import TableCustom from '../../../../components/MyCustom/TableCustom';
import api from '../../../../services/api';
import { ACCESS_LEVEL, convertRolesPTBR } from '../../../../types/roles';
import PreAlert from '../../../../helpers/utils/preAlert';
import Icon from '../../../../components/icon/Icon';

const Users = () => {
  const { accessLevel, establishment, storesDicionary, onShowAlert, onShowQuestion } = useContext(DefaultContext);

  const [openModalRegister, setOpenModalRegister] = useState(false);
  const [users, setUsers] = useState([])
  const [usersInEstab, setUsersInEstab] = useState([])
  const [usersUget, setUsersUget] = useState([])
  const [userSelected, setUserSelected] = useState(null)
  const [loading, setloading] = useState(false)

  useEffect(() => {
    getUsers();
  }, [])

  useEffect(() => {
    if (establishment && storesDicionary) {
     
      const usersFilter = users.filter(user => user.customClaims?.estabId === establishment.id);
      usersFilter.forEach(user => {
        let name = '';
        if (user.customClaims?.stores) {
          user.customClaims?.stores.forEach((storeId, index) => {
            if (index > 0) name += '; ';
            if (storesDicionary[storeId])
              name += storesDicionary[storeId]?.name
          })
        }
        if (user.customClaims?.storeId) {
          name = storesDicionary[user.customClaims?.storeId]?.name
        }
        user.store = name;
      })
      setUsersInEstab(usersFilter)
    }
  }, [establishment, storesDicionary, users])

  const getUsers = useCallback(() => {
    api.get('routes/users')
      .then(res => {
        const users = res.data.filter(user => user.customClaims?.role !== 'admin');
        const usersUget = res.data.filter(user => user.customClaims?.role === 'admin');
        setUsers(users)
        setUsersUget(usersUget)
      })
      .catch(error => onShowAlert({
        title: 'ERRO!',
        msm: 'Não foi possível obter os dados dos usuários',
        icon: 'Error',
        color: 'danger'
      }))
      .finally(() => setloading(false))
  }, [])

  const handleOpenModalRegister = useCallback(() => {
    setUserSelected(null)
    setOpenModalRegister(true);
  }, [])

  const handleCloseModalRegister = useCallback(() => {
    setOpenModalRegister(false);
  }, [])

  const handleOpenModalEdit = useCallback((item) => {
    setUserSelected(item);
    setOpenModalRegister(true)
  }, [])

  const handleOpenModalDelete = useCallback((row) => {
    onShowQuestion({
      title: 'Exclusão de Usuário',
      message: `Você está excluindo ${row.displayName}. Está ação é irreversível. Deseja continuar?`,
      onConfirm: () => {
        const data = { uid: row.uid };
        api
          .delete('routes/users', { data })
          .then(() => {
            onShowAlert(PreAlert.success('Usuário deletado com sucesso'))
            getUsers();
          })
          .catch(() => onShowAlert(PreAlert.error('Falhou ao deletar o usuário')))
      }
    })
  }, [])

  const columns = useMemo(() => ([
    {
      label: '',
      field: 'icon',
      format: row => <Icon icon="Person" size="3x" />,
      noExport: true
    },
    {
      label: 'Nome',
      field: '{row}',
      format: row => row.displayName,
      formatExport: row => row.displayName
    },
    {
      label: 'Email',
      field: 'email',
    },
    {
      label: 'Função',
      field: 'customClaims?.role',
      format: row => convertRolesPTBR(row.customClaims?.role),
      formatExport: value => convertRolesPTBR(value),
    },
    {
      label: 'Loja',
      field: 'stores',
    },
    {
      label: 'Status',
      field: 'disabled',
      format: row => (
        <Button
          isLink
          color={row.disabled ? 'danger' : 'success'}
          icon='Circle'
          className='text-nowrap'>
          {row.disabled ? 'Inativo' : 'Ativo'}
        </Button>
      )
    },
    {
      label: 'Ações',
      field: 'actions',
      format: row => (
        <div className='w-50 d-flex justify-content-between gap-2'>
          <Button
            color="light"
            icon="edit"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleOpenModalEdit(row)}
          />
          <Button
            color="danger"
            icon="Trash"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleOpenModalDelete(row)}
          />
        </div>
      ),
      noExport: true
    },
  ]), [handleOpenModalEdit, handleOpenModalDelete])


  return (
    <>
      <PageWrapper title={demoPages.pageLayout.subMenu.users.text}>
        <SubHeader>
          <SubHeaderLeft>
            <Breadcrumb
              list={[
                { title: 'Cadastros', to: '/registers/users' },
                {
                  title: 'Usuários',
                  to: '/registers/users',
                },
              ]}
            />
          </SubHeaderLeft>
          <SubHeaderRight>
            <Button
              color='primary'
              icon='plus'
              shadow="sm"
              hoverShadow="sm"
              onClick={handleOpenModalRegister}
            >
              Cadastrar
            </Button>
          </SubHeaderRight>
        </SubHeader>
        <Page container='fluid'>
          <Card>
            <CardBody className='table-responsive'>
              <SelectEstablishment />

              <TableCustom
                rows={usersInEstab}
                columns={columns}
                keyExtractor={item => item.uid}
                fileName={'Usuários'}
              />

              {accessLevel === ACCESS_LEVEL.ADMIN &&
                <>
                  <h3>Usuários uGet</h3>
                  <TableCustom
                    rows={usersUget}
                    columns={columns}
                    keyExtractor={item => item.uid}
                    fileName={'Usuários uGet'}
                  />
                </>}
            </CardBody>
          </Card>
        </Page>
      </PageWrapper>

      <ModalRegisterUsers
        open={openModalRegister}
        setIsOpen={setOpenModalRegister}
        setIsClose={handleCloseModalRegister}
        editData={userSelected}
        loadUsers={getUsers}
      />
    </>
  );
};

export default Users;
