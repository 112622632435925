import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../../layout/SubHeader/SubHeader';
import Page from '../../../../layout/Page/Page';
import Breadcrumb from '../../../../components/bootstrap/Breadcrumb';
import Button from '../../../../components/bootstrap/Button';
import Card, { CardBody } from '../../../../components/bootstrap/Card';
import { demoPages } from '../../../../menu';
import ModalRegisterEstablishments from '../../../../components/modals/Establishments/ModalRegister';
import TableCustom from '../../../../components/MyCustom/TableCustom';
// Database
import EstablishmentDB from '../../../../database/wrappers/establishment';
import { DefaultContext } from '../../../../contexts/default';
import { orderBy } from 'firebase/firestore';
import logo from '../../../../assets/img/logo_uget_black.png'

const Establishment = () => {

  const { onShowQuestion } = useContext(DefaultContext)

  const [openModalRegister, setOpenModalRegister] = useState(false);
  const [establishments, setEstablishments] = useState([])
  const [establishmentSelected, setEstablishmentSelected] = useState({})

  useEffect(() => {
    const onSubscribe = new EstablishmentDB().on(setEstablishments, orderBy('name', 'asc'))
    return onSubscribe;
  }, [])

  const handleOpenModalRegister = useCallback(() => {
    setEstablishmentSelected(null)
    setOpenModalRegister(true);
  }, [])

  const handleCloseModalRegister = useCallback(() => {
    setOpenModalRegister(false);
  }, [])

  const handleOpenModalEdit = useCallback((item) => {
    setEstablishmentSelected(item);
    setOpenModalRegister(true)
  }, [])

  const handleOpenModalDelete = useCallback((id) => {

  }, [])

  const columns = useMemo(() => ([
    {
      label: 'Imagem',
      field: 'image_url',
      format: row => (
        <>
          {row.image_url ? (
            <img src={row.image_url} alt="Imagem" width={54} height={54} />
          ) : (
            <img src={logo} alt="Imagem" width={60} height='auto' />
          )}
        </>
      ),
      noExport: true
    },
    {
      label: 'Nome',
      field: 'name',
    },
    {
      label: 'Nome Fantasia',
      field: 'fantasy_name',
    },
    {
      label: 'CNPJ',
      field: 'cnpj',
    },
    {
      label: 'Telefone',
      field: 'phone',
    },
    {
      label: 'E-mail',
      field: 'email',
    },
    {
      label: 'Código do Estab.',
      field: 'code_auth',
    },
    {
      label: 'Status',
      field: 'active',
      format: row => (
        <Button
          isLink
          color={row.active ? 'success' : 'danger'}
          icon='Circle'
          className='text-nowrap'>
          {row.active ? 'Ativo' : 'Inativo'}
        </Button>
      )
    },
    {
      label: 'Ações',
      field: 'actions',
      format: row => (
        <div className='w-50 d-flex justify-content-between'>
          <Button
            color="light"
            icon="edit"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleOpenModalEdit(row)}
          />
          {/* <Button
            color="danger"
            icon="Trash"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleOpenModalDelete(row.id)}
          /> */}
        </div>
      ),
      noExport: true
    },
  ]), [handleOpenModalEdit, handleOpenModalDelete])

  return (
    <>
      <PageWrapper title={demoPages.pageLayout.subMenu.establishment.text}>
        <SubHeader>
          <SubHeaderLeft>
            <Breadcrumb
              list={[
                { title: 'Cadastros', to: '/registers/establishment' },
                {
                  title: 'Estabelecimentos',
                  to: '/registers/establishment',
                },
              ]}
            />
          </SubHeaderLeft>
          <SubHeaderRight>
            <Button
              color='primary'
              icon='plus'
              shadow="sm"
              hoverShadow="sm"
              onClick={handleOpenModalRegister}>
              Cadastrar
            </Button>
          </SubHeaderRight>
        </SubHeader>
        <Page container='fluid'>
          <Card>
            <CardBody className='table-responsive p-5'>
              <TableCustom
                columns={columns}
                rows={establishments}
                fileName={'Estabelecimento'}
              />
            </CardBody>
          </Card>
        </Page>
      </PageWrapper>

      <ModalRegisterEstablishments
        open={openModalRegister}
        setIsOpen={setOpenModalRegister}
        setIsClose={handleCloseModalRegister}
        establishment={establishmentSelected}
      />
    </>
  );
};

export default Establishment;
