import React, { memo, useContext, useEffect, useState } from 'react'
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../../components/bootstrap/Card';
import Chart from '../../../components/extras/Chart';
import { ResultsContext } from '../../../contexts/results';
import { computeIfoodToHours, computeSalesToHours } from '../../../helpers/utils/functions/salesToHours';

const VendasPorHora = () => {

  const [hours, sethours] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])

  const { sales, ordersIfood, ordersRappi } = useContext(ResultsContext)

  useEffect(() => {
    if (!sales) return;
    const { hours } = computeSalesToHours(sales)
    sethours(hours.slice());
  }, [sales])

  // useEffect(() => {
  //   if(!ordersIfood) return;
  //   const { hours } = computeIfoodToHours(ordersIfood)
  //   sethours(hours.slice());
  // },[ordersIfood])

  const chartOptions = {
    chart: {
      height: 400,
      type: 'area',
      toolbar: {
        show: false,
      },
    },
    colors: [process.env.REACT_APP_SUCCESS_COLOR, process.env.REACT_APP_INFO_COLOR],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm',
      },
      theme: 'dark',
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 100],
      },
    },
  }

  const getHours = (day) => {
    const arr = [];
    for (let i = 0; i < day; i += 1) {
      arr.push(i);
    }
    return arr;
  }

  const DUMMY_DATA = {

    series: [
      {
        name: 'Valor (R$)',
        data: hours,
      },
    ],
    options: {
      ...chartOptions,
      xaxis: {
        categories: getHours(24),
      },
    },

  }

  return (
    <Card>
      <CardHeader>
        <CardLabel icon='AccessTime' iconColor='light'>
          <CardTitle>Vendas por Hora</CardTitle>
        </CardLabel>
      </CardHeader>
      <CardBody>
        <Chart
          series={DUMMY_DATA.series}
          options={DUMMY_DATA.options}
          type={DUMMY_DATA.options.chart.type}
          height={DUMMY_DATA.options.chart.height}
        />
      </CardBody>
    </Card>
  )
}

export default memo(VendasPorHora);
