import { Formik, useFormik } from "formik"
import Modal, { ModalBody, ModalHeader } from "../../../bootstrap/Modal"
import { useContext, useEffect } from "react"
import FormGroup from "../../../bootstrap/forms/FormGroup"
import Input from "../../../bootstrap/forms/Input"
import Button from "../../../bootstrap/Button"
import StoreDB from "../../../../database/wrappers/store"
import { DefaultContext } from "../../../../contexts/default"
import PreAlert from "../../../../helpers/utils/preAlert"

const ModalEditStore = ({ open, handleOpen, store }) => {

    const { establishment, onShowAlert } = useContext(DefaultContext);

    useEffect(() => {
        if (!open) return
        if (store) {
            const { name, id_catalog, id_loja_ifood, id_store_rappi } = store
            formik.setValues({
                name,
                id_catalog,
                id_loja_ifood,
                id_store_rappi
            });
        }
    }, [store, open])

    const formik = useFormik({
        initialValues: {
            name: "",
            id_catalog: "",
            id_loja_ifood: "",
            id_store_rappi: ""
        },
        onSubmit: values => {
            const { name, id_catalog, id_loja_ifood, id_store_rappi } = values;

            const data = {
                name: name,
                id_catalog: id_catalog || "",
                id_loja_ifood: id_loja_ifood || "",
                id_store_rappi: id_store_rappi || ""
            }

            const onSuccessUpdate = () => {
                onShowAlert(PreAlert.success('Registro atualizado com sucesso'))
                handleOpen()
            }

            const onError = () => {
                onShowAlert(PreAlert.error('Falhou no registro'))
                handleOpen()
            }

            if (store) {
                new StoreDB(establishment.id)
                    .update(store.id, data)
                    .then(() => onSuccessUpdate())
                    .catch(() => onError())
                    .finally(() => { })
            }
        }
    })

    return (
        <>
            <Modal
                id={'modal-edit-store'}
                titleId={'Modal All Products'}
                isOpen={open}
                setIsOpen={() => handleOpen()}
                sStaticBackdrop={true}
                isScrollable={false}
                isCentered={true}
                size='lg' //'sm' // 'lg' // 'xl'
                isAnimation={true}
                onSubmit={formik.handleSubmit}
            >
                <ModalHeader setIsOpen={() => handleOpen()}>
                    <h5 className="fw-bold"> EDITE OS DADOS DO DELIVERY</h5>
                </ModalHeader>
                <>
                    <ModalBody className='p-5'>
                        <form noValidate onSubmit={Formik.handleSubmit}>
                            <div className="row pb-2 d-flex">
                                <FormGroup id="name" label="Nome" className='col-md-0 mb-4'>
                                    <Input
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.name || ''}
                                        isValid={formik.isValid}
                                        isTouched={formik.touched.name}
                                        invalidFeedback={formik.errors.name}
                                        validFeedback='Assim está bom!'
                                        disabled={true}
                                    />
                                </FormGroup>
                                <FormGroup id="id_loja_ifood" label="Id Food" className='col-md-0 mb-4'>
                                    <Input
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.id_loja_ifood || ''}
                                        isValid={formik.isValid}
                                        isTouched={formik.touched.id_loja_ifood}
                                        invalidFeedback={formik.errors.id_loja_ifood}
                                        validFeedback='Assim está bom!'
                                    />
                                </FormGroup>
                                <FormGroup id="id_catalog" label="Id Catálogo Ifood" className='col-md-0 mb-4'>
                                    <Input

                                        value={formik.values.id_catalog || ''}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        isValid={formik.isValid}
                                        isTouched={formik.touched.id_catalog}
                                        invalidFeedback={formik.errors.id_catalog}
                                        validFeedback='Assim está bom!'
                                    />
                                </FormGroup>
                                <FormGroup id="id_store_rappi" label="Id Rappi" className='col-md-0 mb-4'>
                                    <Input
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.id_store_rappi || ''}
                                        isValid={formik.isValid}
                                        isTouched={formik.touched.id_store_rappi}
                                        invalidFeedback={formik.errors.id_store_rappi}
                                        validFeedback='Assim está bom!'
                                    />
                                </FormGroup>
                            </div>
                            {/* Buttons */}
                            <div className="row pt-4">
                                <div className='d-flex justify-content-end gap-3 mt-3'>
                                    <Button
                                        color="danger"
                                        icon="cancel"
                                        rounded={1}
                                        onClick={handleOpen}
                                        shadow="sm"
                                        hoverShadow="sm"
                                        size="sm"
                                    >
                                        Cancelar
                                    </Button>

                                    <Button
                                        isDisable={!formik.isValid && !!formik.submitCount}
                                        type='submit'
                                        color='success'
                                        icon="check"
                                        rounded={1}
                                        hoverShadow="sm"
                                        shadow="sm"
                                        size="sm"
                                    >
                                        Salvar
                                    </Button>

                                </div>
                            </div>
                        </form>
                    </ModalBody>
                </>
            </Modal>
        </>
    )
}

export default ModalEditStore