import { limit, orderBy, QueryConstraint, where } from "firebase/firestore"
import { useEffect, useMemo, useState } from "react"
import DataFilter from "../../interface/resultContext/DataFilter"
import SalesDB from "../../database/wrappers/sales"
import { PAYMENT_TYPE } from "../../types/payment"
import Sale from "../../database/entities/sales/sale.entity"

const useFiscalUnissued = (dataFilter: DataFilter) => {

  const [sales, setSales] = useState<Sale[]>([])
  const [loading, setloading] = useState(false)

  useEffect(() => {
    if (!dataFilter.estab) return;

    const wheres = getWhere();

    setloading(true)

    const onSubscribe = new SalesDB(dataFilter.estab).on((datas) => {
      setSales(datas);
      setloading(false);
    }, ...wheres);

    return () => {
      onSubscribe();
      
    };
  }, [dataFilter])

  function getWhere(): QueryConstraint[] {
    const { store, firstDate, secondDate } = dataFilter;

    const start = new Date(firstDate);
    const end = new Date(secondDate);

    return [
      where('created_at', '>', start),
      where('created_at', '<', end),
      where('reversed', '==', false),
      where('fiscal.issued', '==', false),
      where('storeId', '==', store),
    ].filter(w => !!w) as any[]
  }

  const rows = useMemo(() => [
    ...sales.filter((p) => p.payment_type !== PAYMENT_TYPE.FREE)
  ], [sales]);

  return { rows, loading };
}

export default useFiscalUnissued;