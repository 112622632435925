import Papa from 'papaparse'
import ProductDB from '../../database/wrappers/product';
import { convertTrybuImport } from '../../types/fiscal';

class ImportProductCsv {

  constructor(
    private readonly estabId: string
  ){}

  async import(file: any) {
    const array = await this.startReaderFile(file)
    this.validateData(array)
    return new ProductDB(this.estabId).writeBatch(array)
  }

  private startReaderFile(file: any): Promise<any> {
    return new Promise((res) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        // Use reader.result
        ImportProductCsv.readCSV(reader.result, res)
      }
      reader.readAsText(file);
    })
  }

  private static readCSV(csv: any, fn: any) {
    const results = Papa.parse(csv, { header: true })
    const rows = results.data 
    const datas: any[] = [];
    rows.forEach((row: any) => {
      const formatedRow = this.__convertKeys(row)
      if(!formatedRow.nome) return;
      datas.push({
        name: formatedRow.nome,
        active: true,
        price: Math.round(formatedRow.preco * 100),
        unit: 'UN',
        description: formatedRow.descricao || '',
        type_compound: false,
        category_complements: false,
        highlights: false,
        suggestionId: null,
        supplies: formatedRow.insumo === 's',
        classification: 'VEGE',
        eat_here: false,
        eat_out: false,
  
        products: [],
        complements: [],
  
        fiscal_data: {
          bar_code: formatedRow.codBarra,
          ncm: formatedRow.ncm,
          cst: formatedRow.cst,
          csosn: formatedRow.csosn,
          cest: formatedRow.cest,
          cfop: formatedRow.cfop,
          aliq_icms: formatedRow.icms,
          tributary_situation: convertTrybuImport(formatedRow.situacaoTrib),
        }
      })
    })
    
    fn(datas)
  }

  private validateData(datas: any[]) {
    datas.forEach(item => {
      if(!item.name) throw `${item.name}, Campo nome ausente`
      if(!item.price || isNaN(item.price)) throw `${item.name}, Campo preco inválido`
      if(!item.fiscal_data.bar_code) throw `${item.name}, Campo codBarra ausente`
      if(!item.fiscal_data.ncm) throw `${item.name}, Campo ncm ausente`
      if(!item.fiscal_data.cst) throw `${item.name}, Campo cst ausente`
      if(!item.fiscal_data.csosn) throw `${item.name}, Campo csosn ausente`
      if(!item.fiscal_data.cest) throw `${item.name}, Campo cest ausente`
      if(!item.fiscal_data.cfop) throw `${item.name}, Campo cfop ausente`
      if(!item.fiscal_data.aliq_icms) throw `${item.name}, Campo icms ausente`
      // if(!item.fiscal_data.situacaoTrib) throw `${item.name}, Campo situacaoTrib ausente`
    })
  }

  private static __convertKeys(inputObject: any) {
    if (inputObject.nome) return inputObject

    const keyMapping: Record<string, string> = {
      "Imagem": "nome",
      "Nome": "nome",
      "Preço": "preco",
      "Cod. Interno": "descricao",
      "Sugestão": "insumo",
      "Status": "status",
      "Cod. Barra": "codBarra",
      "NCM": "ncm",
      "CST": "cst",
      "CSOSN": "csosn",
      "CEST": "cest",
      "CFOP": "cfop",
      "ICMS": "icms",
      "SIT. Tributaria": "situacaoTrib"
    };
  
    const outputObject: Record<string, any>  = {}
  
    for (const key in inputObject) {
      if (keyMapping[key]) {
        outputObject[keyMapping[key]] = inputObject[key];
      }
    }
    return outputObject;
  }
}

export default ImportProductCsv;

