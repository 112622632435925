import { QueryConstraint, Unsubscribe } from "firebase/firestore";
import Printer from "../entities/pdv/printer.entity";
import FirestorePipe from "../utils/pipe";
import EstablishmentDB from "./establishment";
import StoreDB from "./store";

class PrinterDB extends FirestorePipe {

  public static readonly colName = 'Printers'

  constructor(idEstab: string, idStore: string) {
    if(!idEstab)
      throw new Error('Informe o id do estabelecimento')
    if(!idStore)
      throw new Error('Informe o id da loja')
    super(`${EstablishmentDB.colName}/${idEstab}/${StoreDB.colName}/${idStore}/${PrinterDB.colName}`);
  }

  public create(data: Printer): Promise<any> {
    return this._create(data);
  }
  public update(id: string, data: Printer): Promise<any> {
    return this._update(id, data);
  }
  public delete(id: string): Promise<any> {
    return this._delete(id);
  }
  public getAll(...params: QueryConstraint[]): Promise<Printer[]> {
    return this._getAll<Printer>(...params);
  }
  public get(id: string): Promise<Printer> {
    return this._get(id);
  }
  public on(listener: (data: Printer[]) => void, ...params: QueryConstraint[]): Unsubscribe {
    return this._on(listener, ...params);
  }

}

export default PrinterDB;