import { orderBy } from "firebase/firestore";
import { useFormik } from "formik";
import React, { memo, useContext, useEffect, useMemo, useState } from "react";
import { DefaultContext } from "../../../contexts/default";
import StoreDB from "../../../database/wrappers/store";
import { ACCESS_LEVEL } from "../../../types/roles";
import dateFormat from "dateformat";
import Dropdown from "../../bootstrap/Dropdown";
import { DropdownToggle, DropdownMenu } from "../../bootstrap/Dropdown";
import FormGroup from "../../bootstrap/forms/FormGroup";
import Label from "../../bootstrap/forms/Label";
import Select from "../../bootstrap/forms/Select";
import Input from "../../bootstrap/forms/Input";
import Button from "../../bootstrap/Button";
import useDarkMode from "../../../hooks/useDarkMode";
import { ResultsContext } from "../../../contexts/results";

const FilterReports = ({ filterMenu, setFilterMenu, setDataFilter }) => {
  const { themeStatus } = useDarkMode()


  const { accessLevel, establishments,estabSelected,setestabSelected } = useContext(DefaultContext);
  const [storesInFilter, setstoresInFilter] = useState([]);


  const optionsEstablishments = useMemo(() =>
    establishments.map(item => ({ value: item.id, text: item.name }))
    , [establishments])

  const optionsStores = useMemo(() => {
    const options = storesInFilter.map(item => ({ value: item.id, text: item.name }))
    return options;
  }, [storesInFilter])


  const formik = useFormik({
    initialValues: {
      estab: '',
      store: '',
      firstDate: dateFormat(new Date(), 'yyyy-mm-dd 00:00:00'),
      secondDate: dateFormat(new Date(), 'yyyy-mm-dd 23:59:59'),
    },
    onSubmit: (values) => {
      setFilterMenu(false);
      const { estab, store, firstDate, secondDate } = values;
      const dataFilter = {
        estab,
        store,
        firstDate,
        secondDate,
      };

      setestabSelected(estab);
      setDataFilter(dataFilter);


    },
  });

  useEffect(() => {
    formik.setFieldValue('estab', estabSelected)
  },[])

  useEffect(() => {
    if (formik.values.estab) {
      const loadStores = () => {
        new StoreDB(formik.values.estab)
          .getAll(orderBy('name', 'asc'))
          .then(datas => {
            setstoresInFilter(datas)
            formik.setFieldValue('#')
          })
          .catch(error => console.error(error))
          .finally(() => {
          });
      }
      loadStores();

    }
  }, [formik.values.estab])

  return (

    <Dropdown isOpen={filterMenu} setIsOpen={setFilterMenu}>
      <DropdownToggle hasIcon={false}>
        <Button
          style={filterMenu ? { color: themeStatus === 'dark' ? 'black' : 'white' } : {}}
          className='text-dark'
          icon='Filter' color='warning' data-tour='filter'>
          Filtros
        </Button>
      </DropdownToggle>

      <DropdownMenu
        isAlignmentEnd
        size='lg'
        isCloseAfterLeave={false}
        data-tour='filter-menu'>
        <div className='container py-2'>
          <form className='row g-3' onSubmit={formik.handleSubmit}>
            {(accessLevel === ACCESS_LEVEL.ADMIN || accessLevel === ACCESS_LEVEL.MANAGER) &&
              <div className='col-12'>
                <FormGroup>
                  <Label htmlFor='unity'>Estabelecimento</Label>
                  <Select
                    id='estab'
                    placeholder='Selecione o estabelecimento'
                    list={optionsEstablishments}
                    onChange={formik.handleChange}
                    value={formik.values.estab}
                  />
                </FormGroup>
              </div>}
            <div className='col-12'>
              <FormGroup>
                <Label htmlFor='store'>Loja</Label>
                <Select
                  id='store'
                  placeholder='Selecione a loja'
                  list={optionsStores}
                  onChange={formik.handleChange}
                  value={formik.values.store}
                />
              </FormGroup>
            </div>

            <div className='col-12'>
              <FormGroup>
                <Label htmlFor='period'>Data de Inicio</Label>
                <Input
                  id='firstDate'
                  type='datetime-local'
                  ariaLabel='First Date'
                  placeholder='01/2021'
                  onChange={formik.handleChange}
                  value={formik.values.firstDate}
                />
              </FormGroup>
            </div>
            <div className='col-12'>
              <FormGroup>
                <Label htmlFor='period'>Data Final</Label>
                <Input
                  id='secondDate'
                  type='datetime-local'
                  ariaLabel='Second Date'
                  placeholder='01/2022'
                  onChange={formik.handleChange}
                  value={formik.values.secondDate}
                />
              </FormGroup>
            </div>

            <div className='col-12'>
              <Button style={{ color: 'black' }} icon="Search" color='warning' className='w-100' type='submit'>
                Buscar Dados
              </Button>
            </div>
          </form>
        </div>
      </DropdownMenu>
    </Dropdown>


  );
}

export default memo(FilterReports)