import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useFormik } from 'formik';
import Button from '../../bootstrap/Button';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Textarea from '../../bootstrap/forms/Textarea';
import InputGroup, { InputGroupText } from '../../bootstrap/forms/InputGroup';
import Label from '../../bootstrap/forms/Label';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import Spinner from '../../bootstrap/Spinner';
import showNotification from '../../extras/showNotification';
import EstablishmentDB from '../../../database/wrappers/establishment';
import { GeoPoint } from 'firebase/firestore';
import masks from '../../../helpers/utils/masks';
import PreAlert from '../../../helpers/utils/preAlert';
import { DefaultContext } from '../../../contexts/default';
import Avatar from '../../MyCustom/Avatar';
import randomString from '../../../helpers/utils/functions/randomString';

const validate = (values) => {
  const errors = {};
  if (!values.cnpj) {
    errors.cnpj = 'Este campo é necessário.';
  } else if (values.cnpj.length < 18) {
    errors.cnpj = 'O CNPJ precisa ter 14 dígitos.';
  }

  if (!values.name) {
    errors.name = 'Este campo é necessário.';
  }

  if (!values.fantasyName) {
    errors.fantasyName = 'Este campo é necessário.';
  } else if (values.fantasyName.length < 2) {
    errors.fantasyName = 'O nome precisa ter 2 caracteres ou mais.';
  }

  if (!values.phone) {
    errors.phone = 'Este campo é necessário.';
  } else if (values.phone.length <= 13) {
    errors.phone = 'O telefone precisa ter 10 dígitos.';

  }

  if (!values.email) {
    errors.email = 'Este campo é necessário.';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Email inválido.';
  }

  if (!values.latitude) {
    errors.latitude = 'Este campo é necessário.';
  }
  else if (Number(values.latitude) < -90 || Number(values.latitude) > 90) {
    errors.latitude = 'Por favor insira uma latitude válida. Deve estar entre -90 e 90.';
  }

  if (!values.longitude) {
    errors.longitude = 'Este campo é necessário.';
  }
  else if (Number(values.longitude) < -180 || Number(values.longitude) > 180) {
    errors.longitude = 'Por favor insira uma longitude válida. Deve estar entre -180 e 180.';
  }

  if (!values.active) {
    errors.active = 'Você precisa escolher o status do estabelecimento.';
  }

  if (!values.reason) {
    errors.reason = 'Este campo é necessário.';
  } else if (values.reason.length < 6) {
    errors.reason = `A razão social precisa ter 6 caracteres ou mais. Você tem ${values.reason.length} caracteres.`;
  }

  return errors;

}

const ModalRegisterEstablishments = ({ open, setIsOpen, setIsClose, establishment }) => {

  const { onShowAlert } = useContext(DefaultContext)

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!open) return formik.resetForm();
    if (establishment) {
      const { id, cnpj, name, fantasy_name, phone, email, reason, active, geopoint, useCommand, image, image_url, } = establishment;

      formik.setValues({
        id: id,
        cnpj: cnpj,
        name: name,
        fantasyName: fantasy_name,
        phone: phone,
        email: email,
        latitude: geopoint ? geopoint._lat : '',
        longitude: geopoint ? geopoint._long : '',
        reason: reason,
        useCommand: useCommand === undefined ? false : useCommand,
        active: active ? 'ativo' : 'inativo',
        image,
        image_url,

      });
    }
  }, [establishment, open])

  const formik = useFormik({
    initialValues: {
      cnpj: '',
      name: '',
      fantasyName: '',
      phone: '',
      email: '',
      latitude: '',
      longitude: '',
      reason: '',
      active: '',
      useCommand: false,
      image: null,
      image_url: null,

    },
    validate,
    onSubmit: values => {
      const { name, fantasyName, phone, email, cnpj, reason, active, latitude, longitude, useCommand, image, } = values;

      const data = {
        name: name,
        fantasy_name: fantasyName,
        phone: phone,
        email: email,
        cnpj: cnpj,
        reason: reason,
        active: active === 'ativo',
        geopoint: new GeoPoint(latitude, longitude),
        useCommand,
        image,
        code_auth: establishment ? establishment.code_auth : randomString(8),

      }

      const onSuccess = () => {
        onShowAlert(PreAlert.success('Registro cadastrado com sucesso'))
        setIsClose();
      }
      const onSuccessUpdate = () => {
        onShowAlert(PreAlert.success('Registro atualizado com sucesso'))
        setIsClose();
      }
      const onError = () => {
        onShowAlert(PreAlert.error('Falhou ao cadastrar o registro'))
      }

      setIsLoading(true);
      if (establishment) {
        new EstablishmentDB()
          .update(establishment.id, data)
          .then(onSuccessUpdate)
          .catch(onError)
          .finally(() => setIsLoading(false))
      } else {
        new EstablishmentDB()
          .create(data)
          .then(onSuccess)
          .catch(onError)
          .finally(() => setIsLoading(false))
      }
    },
  })

  const handleImage = useCallback((e) => {
    const [file] = Array.from(e.target.files)
    formik.setValues({
      ...formik.values,
      image: file,
      image_url: URL.createObjectURL(file)
    })
  }, [formik.values])

  return (
    <Modal
      id={'modal-register-establishments'}
      titleId={'Cadastro e Update de Estabelecimentos'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
      onSubmit={formik.handleSubmit}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="register-establishment">{establishment ? 'Atualização de Estabelecimento' : 'Cadastro de Estabelecimento'}</ModalTitle>
      </ModalHeader>
      <ModalBody className='p-5'>
        <form noValidate onSubmit={formik.handleSubmit}>
          {/* Imagem do Estabelecimento */}
          <div className='row align-items-center justify-content-center'>
            <FormGroup
              id='image_url'
              ariaLabel='Logo'
            >
              <Avatar
                id='image_url'
                value={formik.values.image_url}
                onChange={handleImage} />
            </FormGroup>
          </div>
          {/* Inputs */}
          <div className="row  pb-2 d-flex">
            {/* CNPJ */}
            <FormGroup id='cnpj' label='CNPJ' className='col-md-4 mb-4'>
              <Input
                type='text'
                id='cnpj'
                name='cnpj'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={masks.cnpj(formik.values.cnpj)}
                isValid={formik.isValid}
                isTouched={formik.touched.cnpj}
                invalidFeedback={formik.errors.cnpj}
                validFeedback='Assim está bom!'
                placeholder='34237209000195'
              />
            </FormGroup>
            {/* Nome */}
            <FormGroup id="name" label="Nome" className='col-md-8 mb-4'>
              <Input
                type='text'
                id='name'
                name='name'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                isValid={formik.isValid}
                isTouched={formik.touched.name}
                invalidFeedback={formik.errors.name}
                validFeedback='Assim está bom!'
                placeholder='João da Silva'
              />
            </FormGroup>
            {/* Nome Fantasia */}
            <FormGroup id='fantasyName' label='Nome Fantasia' className='col-md-8 MB-4'>
              <Input
                type='text'
                id='fantasyName'
                name='fantasyName'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.fantasyName}
                isValid={formik.isValid}
                isTouched={formik.touched.fantasyName}
                invalidFeedback={formik.errors.fantasyName}
                validFeedback='Assim está bom!'
                placeholder='Loja do João Ltda'
              />
            </FormGroup>
            {/* Telefone */}
            <FormGroup id='phone' label='Telefone' className='col-md-4 MB-4'>
              <Input
                type='text'
                id='phone'
                name='phone'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={masks.phone(formik.values.phone)}
                isValid={formik.isValid}
                isTouched={formik.touched.phone}
                invalidFeedback={formik.errors.phone}
                validFeedback='Assim está bom!'
                placeholder='33999998888'
              />
            </FormGroup>
           
             {/* Razão Social */}
             <FormGroup id="reason" label="Razão Social" className='col-md-6 mb-4'>
              <Textarea
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.reason}
                isValid={formik.isValid}
                isTouched={formik.touched.reason}
                invalidFeedback={formik.errors.reason}
                validFeedback='Assim está bom!'
                placeholder='
                Digite sua razão social aqui ...'
                rows={2}
              />
            </FormGroup>
             {/* Email */}
             <FormGroup className='col-md-6 mb-4'>
              <Label htmlFor="email">Email</Label>
              <InputGroup>
                <InputGroupText id="inputGroupPrepend">
                  @
                </InputGroupText>
                <Input
                  type='text'
                  id="email"
                  name='email'
                  ariaDescribedby='inputGroupPrepend'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  isValid={formik.isValid}
                  isTouched={formik.touched.email}
                  invalidFeedback={formik.errors.email}
                  validFeedback='Assim está bom!'
                  placeholder="joaodasilva@email.com"
                />
              </InputGroup>
            </FormGroup>
           
            <div className='col-md-6'>
              <div className='row g-4'>
                {/* Latitude */}
                <FormGroup id='latitude' label='Latitude' className='col-md-6 mb-4 '
                >
                  <Input
                    id='latitude'
                    name='latitude'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.latitude}
                    isValid={formik.isValid}
                    isTouched={formik.touched.latitude}
                    invalidFeedback={formik.errors.latitude}
                    validFeedback='Assim está bom!'
                    placeholder='23564654615'
                  />
                </FormGroup>
                {/* Longitude */}
                <FormGroup id='longitude' label='Longitude' className='col-md-6 mb-4'
                >
                  <Input
                    id='longitude'
                    name='longitude'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.longitude}
                    isValid={formik.isValid}
                    isTouched={formik.touched.longitude}
                    invalidFeedback={formik.errors.longitude}
                    validFeedback='Assim está bom!'
                    placeholder='23564654615'
                  />
                </FormGroup>
              </div>
            </div>
            
            {/* Comanda */}
            
            <div className='row mb-4 col-md-6 '>
              
              {/* Status */}
              <FormGroup className='col-md-4 mb-4'>
                <Label>Status</Label>
                <ChecksGroup
                  isValid={formik.isValid}
                  isTouched={formik.touched.active}
                  invalidFeedback={formik.errors.active}
                >
                  <Checks
                    type="radio"
                    id="active"
                    label="Ativo"
                    name="active"
                    value="ativo"
                    onChange={formik.handleChange}
                    checked={formik.values.active}
                    
                  />
                  <Checks
                    type="radio"
                    id="active2"
                    label="Inativo"
                    name="active"
                    value="inativo"
                    onChange={formik.handleChange}
                    checked={formik.values.active}
                    
                  />
                </ChecksGroup>
              </FormGroup>
              <div className='col-md-4 mb-4'>
                <Checks
                  id='useCommand'
                  type="switch"
                  label="Comanda"
                  name="useCommand"
                  onChange={formik.handleChange}
                  checked={formik.values.useCommand}
                />
              </div>
            </div>
          </div>
          {/* Buttons */}
          <div className="row pt-4">
            <div className='d-flex justify-content-end gap-2'>
              <Button
                color="danger"
                icon="cancel"
                rounded={1}
                onClick={setIsClose}
                shadow="sm"
                hoverShadow="sm"
                size="sm"
              >
                Cancelar
              </Button>
              {isLoading ? (
                <Button
                  color="success"
                  rounded={1}
                  hoverShadow="sm"
                  shadow="sm"
                  size="sm"
                >
                  <Spinner
                    color="light"
                    inButton
                    isGrow
                    isSmall
                    size={10}
                    tag="span"
                  >
                    Carregando...
                  </Spinner>
                  Carregando...
                </Button>
              ) : (
                <Button
                  isDisable={!formik.isValid && !!formik.submitCount}
                  type='submit'
                  color='success'
                  icon="check"
                  rounded={1}
                  hoverShadow="sm"
                  shadow="sm"
                  size="sm"
                >
                  Confirmar
                </Button>
              )}
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default ModalRegisterEstablishments;