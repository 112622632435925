import React, { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../../layout/SubHeader/SubHeader';
import Page from '../../../../layout/Page/Page';
import Breadcrumb from '../../../../components/bootstrap/Breadcrumb';
import Button from '../../../../components/bootstrap/Button';
import ModalRegisterStores from '../../../../components/modals/Stores/ModalRegister';
import ModalInvoiceDetails from '../../../../components/modals/Invoice';
import { demoPages } from '../../../../menu';
import Card, { CardBody } from '../../../../components/bootstrap/Card';
import SelectEstablishment from '../../../../components/MyCustom/SelectEstablishment';
import { DefaultContext } from '../../../../contexts/default';
import StoreDB from '../../../../database/wrappers/store';
import TableCustom from '../../../../components/MyCustom/TableCustom';
import showNotification from '../../../../components/extras/showNotification';
import notificationBody from '../../../../helpers/utils/functions/notificationBody';
import { orderBy } from 'firebase/firestore';
import logo from '../../../../assets/img/logo_uget_black.png'


const Stores = () => {
  const { establishment, onShowQuestion } = useContext(DefaultContext)

  const [openModalRegister, setOpenModalRegister] = useState(false);
  const [openModalInvoiceDetails, setopenModalInvoiceDetails] = useState(false);
  

  const [stores, setStores] = useState([]);
  const [storeSelected, setStoreSelected] = useState(null);

  useEffect(() => {
    if (!establishment) return;
    const onSubscribe = new StoreDB(establishment.id).on(setStores, orderBy('name', 'asc'))
    return onSubscribe;
  }, [establishment]);

  const handleOpenModalRegister = useCallback(() => {
    setStoreSelected(null);
    setOpenModalRegister(true);
  }, [])

  const handleCloseModalRegister = useCallback(() => {
    setOpenModalRegister(false);
  }, [])

  const handleOpenModalEdit = useCallback((item) => {
    setStoreSelected(item);
    setOpenModalRegister(true)
  }, [])

  const handleOpenModalInvoiceDetails = useCallback((row) => {
    setStoreSelected(row)
    setopenModalInvoiceDetails(true);
  }, [])

  const handleCloseModalInvoiceDetails = useCallback(() => {
    setopenModalInvoiceDetails(false);
  }, [])

  const handleOpenModalDelete = useCallback((id) => {
    onShowQuestion({
      title: 'Exclusão de Loja',
      message: 'Você está excluindo uma loja. Está ação é irreversível. Deseja continuar?',
      onConfirm: () => {
        new StoreDB(establishment.id)
        .delete(id)
        .then(res => showNotification('', notificationBody('Registro deletado com sucesso'), 'success'))
        .catch(err => showNotification('', notificationBody('Falhou ao deletar o registro'), 'danger'))
      }
    })
  }, [establishment])

  const columns = useMemo(() => ([
    {
      label: 'Imagem',
      field: 'image_url',
      format: row => (
        <>
          {row.image_url ? (
            <img src={row.image_url} alt="Imagem" width={54} height={54} />
          ) : (
            <img src={logo} alt="Imagem" width={60} height='auto' />
          )}
        </>
      ),
      noExport:true
    },
    {
      label: 'Nome',
      field: 'name',
    },
    {
      label: 'CNPJ',
      field: 'cnpj',
    },
    {
      label: 'E-mail',
      field: 'email',
    },
    {
      label: 'ID LOJA',
      field: 'store_id',
    },
    {
      label: 'Stone Code',
      field: 'stone_code',
    },
    {
      label: 'Pagseguro Code',
      field: 'pagseguro_code',
    },
    {
      label: 'Auth Code',
      field: 'code_auth',
    },
    {
      label: 'Status',
      field: 'active',
      format: row => (
        <Button
          isLink
          color={row.active ? 'success' : 'danger'}
          icon='Circle'
          className='text-nowrap'>
          {row.active ? 'Ativo' : 'Inativo'}
        </Button>
      )
    },
    {
      label: 'Fiscal',
      field: 'status',
      
      format: row => (
        <Button
          isLink
          color={row?.fiscal_data?.status ==='success' ? 'success' : row?.fiscal_data?.status ==='error' ? 'danger': 'info'}
          icon="Settings"
          className='text-nowrap'
          onClick={() => handleOpenModalInvoiceDetails(row)}
          >
          {row?.fiscal_data?.status ==='success' ? 'Habilitado' : row?.fiscal_data?.status === 'error' ?  'Error' : 'Desabilitado'}
          
        </Button>
      ),
      noExport: true
      
    },
    {
      label: 'Ações',
      field: 'actions',
      format: row => (
        <div className='w-50 d-flex justify-content-between gap-2'>
          <Button
            color="light"
            icon="edit"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleOpenModalEdit(row)}
          />
          <Button
            color="danger"
            icon="Trash"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleOpenModalDelete(row.id)}
          />
        </div>
      ),
      noExport: true
    },
  ]), [handleOpenModalEdit, handleOpenModalDelete])

  return (
    <>
      <PageWrapper title={demoPages.pageLayout.subMenu.stores.text}>
        <SubHeader>
          <SubHeaderLeft>
            <Breadcrumb
              list={[
                { title: 'Cadastros', to: '/registers/stores' },
                {
                  title: 'Lojas',
                  to: '/registers/stores',
                },
              ]}
            />
          </SubHeaderLeft>
          <SubHeaderRight>
            <Button
              color='primary'
              icon='plus'
              shadow="sm"
              hoverShadow="sm"
              onClick={handleOpenModalRegister}>
              Cadastrar
            </Button>
          </SubHeaderRight>
        </SubHeader>
        <Page container='fluid'>
          <Card>
            <CardBody className='table-responsive p-5'>
              <SelectEstablishment />

              <TableCustom
                columns={columns}
                rows={stores}
                fileName={'Lojas'}
              />
            </CardBody>
          </Card>
        </Page>
      </PageWrapper>

      <ModalRegisterStores
        open={openModalRegister}
        setIsOpen={setOpenModalRegister}
        setIsClose={handleCloseModalRegister}
        storeSelected={storeSelected}
      />

      {openModalInvoiceDetails && 
        <ModalInvoiceDetails 
          open={openModalInvoiceDetails}
          setIsOpen={setopenModalInvoiceDetails}
          setIsClose={handleCloseModalInvoiceDetails}
          storeSelected={storeSelected}
        />
      }

    </>
  );
};

export default Stores;
