import { QueryConstraint, Unsubscribe } from "firebase/firestore";
import Goal from "../entities/goal.entity";
import FirestorePipe from "../utils/pipe";
import EstablishmentDB from "./establishment";

class GoalDB extends FirestorePipe {

  public static readonly colName = 'Goals'

  constructor(idEstab: string) {
    if(!idEstab)
      throw new Error('Informe o id do estabelecimento')
    super(`${EstablishmentDB.colName}/${idEstab}/${GoalDB.colName}/`);
  }

  public define(id: string, data: Goal): Promise<any> {
    return this._define(id, data);
  }
  public create(data: Goal): Promise<any> {
    throw 'Não cadastra goal em create';
  }
  public update(id: string, data: Goal): Promise<any> {
    throw 'Não atualiza goal em update';
  }
  public delete(id: string): Promise<any> {
    throw 'Não é possivel excluir';
  }
  public getAll(...params: QueryConstraint[]): Promise<Goal[]> {
    throw 'Não se aplica';
  }
  public get(id: string): Promise<Goal> {
    return this._get(id);
  }
  public on(listener: (data: Goal[]) => void, ...params: QueryConstraint[]): Unsubscribe {
    throw 'Não se aplica';
  }
}

export default GoalDB;