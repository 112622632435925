export const COLORS_LIGHT = {
  DARK: '#FAD02A',
  PRIMARY: '#46BCAA',
  SECONDARY: '#FDA1BE',
  SUCCESS: '#E0ACD5',
  INFO: '#B66FFF',
  WARNING: '#DE6B48',
  DANGER: '#DE4315',
  GREEN: '#90EE90',
} as const

export const COLORS_DARK = {
  DARK: '#D1AE22',
  PRIMARY: '#46BCAA',
  SECONDARY: '#FDA1BE',
  SUCCESS: '#BD90B4',
  INFO: '#9057CB',
  WARNING: '#C65F3F',
  DANGER: '#C03911',
  GREEN: '#8AE38A',
} as const