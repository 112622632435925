export const ROLE = {
  ADMIN: 'admin',
  MANAGER: 'manager',
  COORDINATOR: 'coordinator',
  CASHIER: 'cashier',
}

export const TYPE_CASHIER = {
  CASHIER: 'cashier',
  HOSTESS: 'hostess',
  WAITER: 'waiter',
}

export const ACCESS_LEVEL = {
  ADMIN: 3,
  MANAGER: 2,
  COORDINATOR: 1,
  CASHIER: 0,
}

export function convertRolesPTBR(role: string) {
  switch (role) {
    case ROLE.ADMIN: return 'Administrador uGet';
    case ROLE.MANAGER: return 'Gerente';
    case ROLE.COORDINATOR: return 'Coordenador';
    case ROLE.CASHIER: return 'Caixa';
    default: return 'Indefinido'
  }
}

export function roleLevel(role: string) {
  switch (role) {
    case ROLE.ADMIN: return ACCESS_LEVEL.ADMIN;
    case ROLE.MANAGER: return ACCESS_LEVEL.MANAGER;
    case ROLE.COORDINATOR: return ACCESS_LEVEL.COORDINATOR;
    case ROLE.CASHIER: return ACCESS_LEVEL.CASHIER;
    default: return -1;
  }
}

export function convertTypesCashierPTBR(type: string) {
  switch (type) {
    case TYPE_CASHIER.CASHIER: return 'Caixa';
    case TYPE_CASHIER.HOSTESS: return 'Hostess';
    case TYPE_CASHIER.WAITER: return 'Garçom';
    default: return 'Indefinido'
  }
}