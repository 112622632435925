import React, { useContext, useEffect, useState, useCallback, useMemo, useRef } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../../layout/SubHeader/SubHeader';
import Page from '../../../../layout/Page/Page';
import Breadcrumb from '../../../../components/bootstrap/Breadcrumb';
import Button from '../../../../components/bootstrap/Button';
import { demoPages } from '../../../../menu';
import ModalRegisterComplement from '../../../../components/modals/Complement/ModalRegister';
import { DefaultContext } from '../../../../contexts/default';
import ComplementDB from '../../../../database/wrappers/complement';
import SelectEstablishment from '../../../../components/MyCustom/SelectEstablishment';
import Card, { CardBody } from '../../../../components/bootstrap/Card';
import TableCustom from '../../../../components/MyCustom/TableCustom';
import Money from '../../../../helpers/utils/money/money';
import showNotification from '../../../../components/extras/showNotification';
import notificationBody from '../../../../helpers/utils/functions/notificationBody';
import { orderBy } from 'firebase/firestore';
import { ACCESS_LEVEL } from '../../../../types/roles';
import logo from '../../../../assets/img/logo_uget_black.png'
//csv
import BtnLoad from '../../../../components/MyCustom/BtnLoad';
import PreAlert from '../../../../helpers/utils/preAlert';
import Input from '../../../../components/bootstrap/forms/Input';
import ImportComplementCsv from '../../../../utils/csv/importComplementCsv';
import StoreSettingDB from '../../../../database/wrappers/storeSettings';
import UserHistory from '../../../../database/wrappers/userHistory';


const Complement = () => {
  const { accessLevel, estabSelected, establishment, onShowAlert, onShowQuestion ,settings, user} = useContext(DefaultContext);

  const [openModalRegister, setOpenModalRegister] = useState(false);
  const [complements, setComplements] = useState([]);
  const [complementSelected, setComplementSelected] = useState({});
  const [loadImport, setloadImport] = useState(false);
  const inputFile = useRef(null)
  const refLinkComplement = useRef(null)




  useEffect(() => {
    if (!establishment) return;
    const onSubscribe = new ComplementDB(establishment.id).on(setComplements, orderBy('name', 'asc'));
    return onSubscribe;
  }, [establishment])

  const handleOpenModalRegister = useCallback(() => {
    setComplementSelected(null)
    setOpenModalRegister(true);
  }, [])

  const handleCloseModalRegister = useCallback(() => {
    setOpenModalRegister(false);
  }, [])

  const handleOpenModalEdit = useCallback((item) => {
    setComplementSelected(item);
    setOpenModalRegister(true)
  }, [])

  const handleOpenModalDelete = useCallback((row) => {
    onShowQuestion({
      title: 'Exclusão de Complemento',
      message: 'Você está excluindo um complemento. Está ação é irreversível. Deseja continuar?',
      onConfirm: () => {
        new ComplementDB(establishment.id)
          .delete(row.id)
          .then(res => {
            showNotification('', notificationBody('Registro deletado com sucesso'), 'success');
            new UserHistory(establishment.id, user, "o Complemento")
              .deleteWithData(row.id, row)
              .finally(() => { })
          })
          .catch(err => showNotification('', notificationBody('Falhou ao deletar o registro'), 'danger'))
      }
    })
  }, [establishment])

  const handleChangeStatus = useCallback((id, active) => {
    new ComplementDB(establishment.id)
      .update(id, {
        active: !active
      })
      .catch(error => onShowAlert({
        title: 'ERRO!',
        msm: 'Não foi possível atualizar o status do complemento',
        icon: 'Error',
        color: 'danger'
      }))
  }, [establishment])

  // import csv
  const onClickImport = useCallback(() => {
    inputFile.current.click();
  }, [inputFile])

  const handleFiles = useCallback(e => {
    if (!estabSelected) return;
    setloadImport(true)
    const [file] = Array.from(e.target.files)
    new ImportComplementCsv(estabSelected)
      .import(file)
      .then(() => onShowAlert(PreAlert.success('Registro(s) cadastrado(s) com sucesso')))
      .catch((error) => onShowAlert(PreAlert.error(error || 'Falhou ao cadastrar o(s) registro(s)')))
      .finally(() => setloadImport(false))
  }, [estabSelected])

  // export csv
  const onDownloadComplementCsv = useCallback(() => {
    refLinkComplement.current.click();
  }, [refLinkComplement])


  const rowsRender = useMemo(() => complements.map(item => ({
    ...item,
    fiscal_bar_code: item.fiscal_data?.bar_code,
    fiscal_ncm: item.fiscal_data?.ncm,
    fiscal_cst: item.fiscal_data?.cst,
    fiscal_csosn: item.fiscal_data?.csosn,
    fiscal_cest: item.fiscal_data?.cest,
    fiscal_cfop: item.fiscal_data?.cfop,
    fiscal_aliq_icms: item.fiscal_data?.aliq_icms,
    fiscal_tributary_situation: item.fiscal_data?.tributary_situation,
  })), [complements])

  const columns = useMemo(() => ([
    {
      label: 'Imagem',
      field: 'image_url',
      format: row => (
        <>
          {row.image_url ? (
            <img src={row.image_url} alt="Imagem" width={54} height={54} />
          ) : (
            <img src={logo} alt="Imagem" width={60} height='auto' />
          )}
        </>
      ),
      noExport: true
    },
    {
      label: 'Imagem Secun.',
      field: 'second_image_url',
      format: row => (
        <>
          {row.second_image_url ? (
            <img src={row.second_image_url} alt="Imagem" width={54} height={54} />
          ) : (
            <img src={logo} alt="Imagem" width={60} height='auto' />
          )}
        </>
      ),
      noExport: true,
      hidden: !settings?.ecommerce
    },
    {
      label: 'Nome',
      field: 'name',
    },
    {
      label: 'Nome Sec.',
      field: 'secondName',
      hidden: !settings?.ecommerce

    },
    {
      label: 'Sku',
      field: 'sku',
      hidden: !settings?.ecommerce

    },
    {
      label: 'Descrição',
      field: 'description',
    },
    {

      label: 'Ordem',
      field: 'order',
      format: row => row.order ?? null,
    },
    {
      label: 'Preço',
      field: 'price',
      format: row => (
        <span>{Money.centsToCoinCsv(row.price)}</span>
      ),
      formatExport: value => Money.centsToCoinCsv(value)
    },
    {
      label: 'Status',
      field: 'active',
      format: row => (
        <>
          <Button
            isLink
            color={row.active ? 'success' : 'danger'}
            icon='Circle'
            onClick={() => handleChangeStatus(row.id, row.active)}
            className='text-nowrap'>
            {row.active ? 'Ativo' : 'Inativo'}
          </Button>
        </>
      )
    },
    {
      label: 'Cod. Barra',
      field: 'fiscal_bar_code',
      hidden: true,
     
    },

    {
      label: 'NCM',
      field: 'fiscal_ncm',
      hidden: true,
     
    },
    {
      label: 'CST',
      field: 'fiscal_cst',
      hidden: true,
     
    },
    {
      label: 'CSOSN',
      field: 'fiscal_csosn',
      hidden: true,
     
    },
    {
      label: 'CEST',
      field: 'fiscal_cest',
      hidden: true,
     
    },
    {
      label: 'CFOP',
      field: 'fiscal_cfop',
      hidden: true,
     
    },
    {
      label: 'ICMS',
      field: 'fiscal_aliq_icms',
      hidden: true,
     
    },
    {
      label: 'SIT. Tributaria',
      field: 'fiscal_tributary_situation',
      hidden: true,
     
    },
    {
      label: 'Ações',
      field: 'actions',
      format: row => (
        <div className='w-50 d-flex justify-content-between gap-2'>
          <Button
            color="light"
            icon="edit"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleOpenModalEdit(row)}
          />

          <Button
            color="danger"
            icon="Trash"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleOpenModalDelete(row)}
          />
        </div>
      ),
      noExport: true
    },
  ]), [handleChangeStatus, handleOpenModalEdit, handleOpenModalDelete, settings])


  return (
    <>
      <PageWrapper title={demoPages.pdvMenu.subMenu.complement.text}>
        <SubHeader>
          <SubHeaderLeft>
            <Breadcrumb
              list={[
                { title: 'Cadastros', to: '/pdv/complement' },
                {
                  title: 'Complemento',
                  to: '/pdv/complement',
                },
              ]}
            />
          </SubHeaderLeft>
          <SubHeaderRight>
            <a hidden ref={refLinkComplement} href='https://firebasestorage.googleapis.com/v0/b/e-pdx-b9d3b.appspot.com/o/ExtraFiles%2Fcomplementos.csv?alt=media&token=099540f7-982c-4391-9f8c-d1943e3935de' />
            <Button
              type='file'
              className='text-dark'
              color='success'
              icon='download'
              shadow="sm"
              hoverShadow="sm"
              onClick={onDownloadComplementCsv}
            >
              Download CSV
            </Button>
            <Input
              hidden
              id='input-file-import'
              type='file'
              accept={'.csv'}
              ref={inputFile}
              onChange={handleFiles}
            />
            {loadImport ?
              <BtnLoad />
              :
              <Button
                type='file'
                className='text-dark'
                color='warning'
                icon='upload'
                shadow="sm"
                hoverShadow="sm"
                onClick={onClickImport}
              >
                Importar CSV
              </Button>
            }
            {accessLevel >= ACCESS_LEVEL.MANAGER &&
              <Button
                color='primary'
                icon='plus'
                shadow="sm"
                hoverShadow="sm"
                onClick={handleOpenModalRegister}
              >
                Cadastrar
              </Button>}
          </SubHeaderRight>
        </SubHeader>
        <Page container="fluid">
          <Card>
            <CardBody className='table-responsive p-5'>
              <SelectEstablishment />
              <TableCustom
                columns={columns}
                rows={rowsRender}
                fileName={'Complemento'}
              />
            </CardBody>
          </Card>
        </Page>
      </PageWrapper>

      <ModalRegisterComplement
        open={openModalRegister}
        setIsOpen={setOpenModalRegister}
        setIsClose={handleCloseModalRegister}
        complementSelected={complementSelected}
      />
    </>
  );
};

export default Complement;
