import React from "react";
import HeaderSector from "./header";
import ContentPdf from "./content";
import FooterPdf from "./footer";

const SectorPdf = ({ data, graphics, ranking,salesPerHour, ticketMedio, salesQuantity , graficoFaturamentoMedio}) => {

    return (
        <>
            <HeaderSector />
            <ContentPdf data={data} graphics={graphics} ranking={ranking} salesPerHour={salesPerHour} ticketMedio={ticketMedio} salesQuantity={salesQuantity} graficoFaturamentoMedio={graficoFaturamentoMedio} />
            <FooterPdf />


        </>
    );
}

export default SectorPdf;
