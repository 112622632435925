import React, { memo } from 'react'
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../../components/bootstrap/Card';
import useDarkMode from '../../../hooks/useDarkMode';

import classNames from 'classnames';

const QuantidadeVenda = ({ count }) => {
  const { darkModeStatus } = useDarkMode();

  return (
    <div className="row g-4">
      <div className="col-md-12">
        <Card
          className={classNames(
            'transition-base rounded-2 mb-4  text-dark',
            {
              'bg-l25-secondary bg-l10-secondary-hover':
                !darkModeStatus,
              'bg-lo50-secondary bg-lo25-secondary-hover':
                darkModeStatus,
            },
          )}
          shadow='sm'>
          <CardHeader size="sm" className='bg-transparent'>
            <CardLabel>
              <CardTitle tag='h4' className='h5'>
                Quantidade de Venda
              </CardTitle>
            </CardLabel>
          </CardHeader>
          <CardBody>
            <div className='d-flex align-items-center pb-3'>
              <div className='flex-grow-1 ms-3'>
                <div className='fw-bold fs-3 mb-0'>
                  {count}
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

export default memo(QuantidadeVenda)
