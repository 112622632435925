import React, { useState, useContext, memo, useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import PreAlert from '../../../helpers/utils/preAlert';
import { DefaultContext } from '../../../contexts/default';
import NfcsAccessDB from '../../../database/wrappers/nfcsAccess';
import Button from '../../bootstrap/Button';
import Spinner from '../../bootstrap/Spinner';
import api from '../../../services/api';
import Select from '../../bootstrap/forms/Select';
import { Options } from '../../bootstrap/Option';


const ModalRegisterNFC = ({ open, setIsOpen, setIsClose, passwordSelected }) => {
  const { establishment, store, onShowAlert, storesDicionary } = useContext(DefaultContext)
  const [loadingNfc, setloadingNfc] = useState(false)
  const [loading, setloading] = useState(false)
  const [userInEstab, setUsersInEstab] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    getUsers();
  }, [])
  const getUsers = useCallback(() => {
    api.get('routes/users')
      .then(res => {
        const users = res.data.filter(user => user.customClaims?.role === 'coordinator' || user.customClaims?.role === 'manager')
        setUsers(users)
      })

      .catch(error => onShowAlert({
        title: 'ERRO!',
        msm: 'Não foi possível obter os dados dos usuários',
        icon: 'Error',
        color: 'danger'
      }))
      .finally(() => setloading(false))
  }, [])

  useEffect(() => {
    if (establishment && storesDicionary) {
      const usersFilter = users.filter(user => user.customClaims?.estabId === establishment.id);
      usersFilter.forEach(user => {
        let name = '';
        if (user.customClaims?.stores) {
          user.customClaims?.stores.forEach((storeId, index) => {
            if (index > 0) name += '; ';
            if (storesDicionary[storeId])
              name += storesDicionary[storeId]?.name
          })
        }
        if (user.customClaims?.storeId) {
          name = storesDicionary[user.customClaims?.storeId]?.name
        }
        user.store = name;
      })
      const userToUserEstab = (user) => {
        let roleText = user.customClaims?.role;
        if (roleText === 'manager') {
          roleText = 'Gerente';
        } else if (roleText === 'coordinator') {
          roleText = 'Coordenador';
        }
        const text = roleText + ' - ' + user.displayName + ' - ' + user.email;
        return {
          text,
          value: user.uid,
        };
      };
      const userEstabData = usersFilter.map(userToUserEstab);
      setUsersInEstab(userEstabData);
    }
  }, [establishment, storesDicionary, users])

  useEffect(() => {
    if (!open) return formikNfcRegister.resetForm();
    if (passwordSelected) {
      const { nfc, userId } = passwordSelected;

      formikNfcRegister.setValues({
        nfc,
        userId,
      });
    }
  }, [passwordSelected, open])


  const validateNfcRegister = useCallback((values) => {
    const errors = {};
    if (!values.nfc) {
      errors.nfc = 'Este campo é necessário';
    } else if (values.nfc.length < 8) {
      errors.nfc = 'Deve ter 8 caracteres';
    }
    return errors
  }, [])

  const formikNfcRegister = useFormik({
    initialValues: {
      nfc: '',
      userId: '',
    },
    validate: validateNfcRegister,
    onSubmit: values => {
      const { nfc, userId } = values;
      const data = {
        nfc,
        userId
      }
      const onSuccess = () => {
        onShowAlert(PreAlert.success('NFC cadastrada com sucesso'))
        setIsClose();
      }
      const onError = (error) => {
        console.error(error);
        onShowAlert(PreAlert.error('Falhou ao cadastrar o nfc'))
      }
      setloadingNfc(true)
      if (passwordSelected) {
        new NfcsAccessDB(establishment.id, store.id)
          .update(passwordSelected.id, data)
          .then(onSuccess)
          .catch(onError)
          .finally(() => setloadingNfc(false))

      } else
        new NfcsAccessDB(establishment.id, store.id)
          .create(data)
          .then(onSuccess)
          .catch(onError)
          .finally(() => setloadingNfc(false))
    }
  })
    

return (
  <Modal
    id={'modal-register-ticketBoxEntrance'}
    titleId={'Cadastro de NFC'}
    isOpen={open}
    setIsOpen={setIsOpen}
    isStaticBackdrop={true}
    isScrollable={false}
    isCentered={true}
    size="sm" // 'sm' || 'lg' || 'xl' 
    isAnimation={true}
  >
    <ModalHeader setIsOpen={setIsOpen}>
      <ModalTitle id="register-nfc">Cadastro de NFC</ModalTitle>
    </ModalHeader>
    <ModalBody className='p-5'>
      <form id="reg-nfc-access" noValidate onSubmit={formikNfcRegister.handleSubmit}>
        <div className='row d-flex g-4' style={{ marginBottom: 20 }}>
          <FormGroup id='nfc' label='NFC' >
            <Input
              onChange={formikNfcRegister.handleChange}
              onBlur={formikNfcRegister.handleBlur}
              value={formikNfcRegister.values.nfc}
              isValid={formikNfcRegister.isValid}
              isTouched={formikNfcRegister.touched.nfc}
              invalidFeedback={formikNfcRegister.errors.nfc}
              validFeedback='Assim está bom!'
              placeholder='Informe uma senha'
            />
          </FormGroup>
          <FormGroup id='userId' label='Vincular NFC a usuário (Opcional)'>
            <Select
              ariaLabel='userId'
              placeholder='Selecione o usuário...'
              id='userId'
              name='userId'
              onChange={formikNfcRegister.handleChange}
              value={formikNfcRegister.values.userId}
            >
              <Options list={userInEstab} />
            </Select>
          </FormGroup>

        </div>

        <div className="row pt-4">
          <div className='d-flex justify-content-evenly'>
            <Button
              type='submit'
              color={loadingNfc ? 'success' : 'primary'}
              icon={loadingNfc ? '' : 'Add'}
              shadow="sm"
              hoverShadow="sm"
              isDisable={loadingNfc}
            >
              {loadingNfc ? (
                <>
                  <Spinner
                    color="light"
                    inButton
                    isGrow
                    isSmall
                    size={10}
                    tag="span"
                  />
                  Carregando...
                </>
              ) : (
                'Adicionar'
              )}
            </Button>
            <Button
              color="danger"
              icon="cancel"
              rounded={1}
              onClick={setIsClose}
              shadow="sm"
              hoverShadow="sm"
              size="sm"
            >
              Cancelar
            </Button>

          </div>
        </div>
      </form>
    </ModalBody>


  </Modal >
)
}

export default memo(ModalRegisterNFC);