import React, { useState, useEffect, useContext, memo } from 'react';
import { useFormik } from 'formik';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Checks from '../../bootstrap/forms/Checks';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import PreAlert from '../../../helpers/utils/preAlert';
import { DefaultContext } from '../../../contexts/default';
import BtnLoad from '../../MyCustom/BtnLoad';
import BtnCancel from '../../MyCustom/BtnCancel';
import BtnConfirm from '../../MyCustom/BtnConfirm';
import ReasonDB from '../../../database/wrappers/reason';

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Este campo é necessário.';
  }

  return errors;
}

const ModalRegisterReason = ({ open, setIsOpen, setIsClose, reasonSelected }) => {

  const { onShowAlert, establishment, store } = useContext(DefaultContext)

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!open) return formik.resetForm();
    if (reasonSelected) {
      const { name, active } = reasonSelected;

      formik.setValues({
        name,
        active: active,
      });
    }
  }, [reasonSelected, open])

  const formik = useFormik({
    initialValues: {
      name: '',
      active: true,
    },
    validate,
    onSubmit: values => {
      const { name, active } = values;
      const data = {
        name,
        active,
      }

      const onSuccess = () => {
        onShowAlert(PreAlert.success('Registro cadastrado com sucesso'))
        setIsLoading(false);
        setIsClose();
      }
      const onSuccessUpdate = () => {
        onShowAlert(PreAlert.success('Registro atualizado com sucesso'))
        setIsLoading(false);
        setIsClose();
      }
      const onError = () => {
        onShowAlert(PreAlert.error('Falhou ao cadastrar o registro'))
        setIsLoading(false);
      }

      setIsLoading(true);
      if (reasonSelected) {
        new ReasonDB(establishment.id, store.id)
          .update(reasonSelected.id, data)
          .then(onSuccessUpdate)
          .catch(onError)
          .finally(() => setIsLoading(false))
      } else {
        new ReasonDB(establishment.id, store.id)
          .create(data)
          .then(onSuccess)
          .catch(onError)
          .finally(() => setIsLoading(false))
      }
    },
  })

  return (
    <Modal
      id={'modal-register-ticketBoxEntrance'}
      titleId={'Cadastro e Atualização de Entradas'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
      onSubmit={formik.handleSubmit}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="register-reasonSelected">{reasonSelected ? 'Atualização de Justificativa' : 'Cadastro de Justificativa'}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <form noValidate onSubmit={formik.handleSubmit}>

          {/* Inputs */}
          <div className="row g-4">

            {/* Nome */}
            <FormGroup id="name" label="Descrição" className='col-md-7'>
              <Input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                isValid={formik.isValid}
                isTouched={formik.touched.name}
                invalidFeedback={formik.errors.name}
                validFeedback='Assim está bom!'
                placeholder='Justificativa para cortesia e/ou desconto'
              />
            </FormGroup>

            {/* Status */}
            <FormGroup
              id='active'
              label='Status'
              className='col-md-3'
            >
              <Checks
                type="switch"
                id="active"
                label={formik.values.active ? 'Ativo' : 'Inativo'}
                name="active"
                onChange={formik.handleChange}
                checked={formik.values.active}
              />
            </FormGroup>

          </div>
          {/* Buttons */}
          <div className="row pt-4">
            <div className="col-md-4 offset-md-8">
              <div className='d-flex justify-content-evenly'>
                <BtnCancel setIsClose={setIsClose} />
                {isLoading ? (
                  <BtnLoad />
                ) : (
                  <BtnConfirm isDisable={!formik.isValid} />
                )}
              </div>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default memo(ModalRegisterReason);