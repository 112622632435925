import React, { useState, useContext, useEffect } from 'react';
import { useFormik } from 'formik';
import Button from '../../bootstrap/Button';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import Label from '../../bootstrap/forms/Label';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
// Database
import { DefaultContext } from '../../../contexts/default';
import PreAlert from '../../../helpers/utils/preAlert';
import BoxOfficeDB from '../../../database/wrappers/boxOffice';
import BtnLoad from '../../MyCustom/BtnLoad';
import masks, { unmask } from '../../../helpers/utils/masks';


const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Este campo é necessário';
  }

  const consummation = Number(unmask(values.consummation))
  if (!values.consummation || isNaN(consummation)) {
    errors.consummation = 'Informe um número válido';
  }

  const value = Number(unmask(values.value))
  if (!values.value || isNaN(value)) {
    errors.value = 'Informe um número válido';
  }

  if (!values.active) {
    errors.active = 'Você precisa escolher o status da Impressora';
  }

  return errors;
}

const ModalRegisterboxOffice = ({ open, setIsOpen, setIsClose, boxOfficeSelected }) => {

  const { establishment, store, onShowAlert } = useContext(DefaultContext)

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!open) return formik.resetForm();
    if (boxOfficeSelected) {
      const { name, active, value, consummation, color } = boxOfficeSelected;

      formik.setValues({
        active: active ? 'ativo' : 'inativo',
        name,
        color,
        value: unmask(String(value)),
        consummation: unmask(String(consummation)),
      });
    }
  }, [boxOfficeSelected, open])

  const formik = useFormik({
    initialValues: {
      name: '',
      active: '',
      value: '',
      consummation: '',
      color: '#FFFFFF'
    },
    validate,
    onSubmit: values => {
      const { name, active, value, consummation, color } = values;

      const data = {
        name,
        active: active === 'ativo',
        value: Number(unmask(value)),
        consummation: Number(unmask(consummation)),
        color
      }

      const onSuccess = () => {
        onShowAlert(PreAlert.success('Registro cadastrado com sucesso'))
        setIsClose();
      }
      const onSuccessUpdate = () => {
        onShowAlert(PreAlert.success('Registro atualizado com sucesso'))
        setIsClose();
      }
      const onError = () => {
        onShowAlert(PreAlert.error('Falhou ao cadastrar o registro'))
      }

      setIsLoading(true);
      if (boxOfficeSelected) {
        new BoxOfficeDB(establishment.id, store.id)
          .update(boxOfficeSelected.id, data)
          .then(onSuccessUpdate)
          .catch(onError)
          .finally(() => setIsLoading(false))
      } else {
        new BoxOfficeDB(establishment.id, store.id)
          .create(data)
          .then(onSuccess)
          .catch(onError)
          .finally(() => setIsLoading(false))
      }
    },
  })

  return (
    <Modal
      id={'modal-register-boxoffice'}
      titleId={'Cadastro de Entrada'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
      onSubmit={formik.handleSubmit}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="register-printer">{boxOfficeSelected ? 'Atualização de Entrada' : 'Cadastro de Entrada'}</ModalTitle>
      </ModalHeader>
      <ModalBody className='p-5'>
        <form noValidate onSubmit={formik.handleSubmit}>

          {/* Inputs */}
          <div className="row pb-2 d-flex">
            {/* Nome */}
            <FormGroup id="name" label="Nome" className='col-md-12 mb-4'>
              <Input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                isValid={formik.isValid}
                isTouched={formik.touched.name}
                invalidFeedback={formik.errors.name}
                validFeedback='Assim está bom!'
                placeholder='Eletrodomésticos, lâmpadas, etc...'
              />
            </FormGroup>

            <FormGroup id='value' label='Valor (R$)' className='col-md-6 mb-4'>
              <Input
                // type="number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={masks.money(formik.values.value)}
                isValid={formik.isValid}
                isTouched={formik.touched.value}
                invalidFeedback={formik.errors.value}
                validFeedback='Assim está bom!'
                placeholder='R$ 0,00'
              />
            </FormGroup>

            <FormGroup id='consummation' label='Consumação (R$)' className='col-md-6 mb-4'>
              <Input
                // type="number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={masks.money(formik.values.consummation)}
                isValid={formik.isValid}
                isTouched={formik.touched.consummation}
                invalidFeedback={formik.errors.consummation}
                validFeedback='Assim está bom!'
                placeholder='R$ 0,00'
              />
            </FormGroup>

            <FormGroup label='Cor' className='col-md-6 mb-4'>
              <Input
                type="color"
                id='color'
                onChange={formik.handleChange}
                value={formik.values.color}
                validFeedback='Assim está bom!'
                placeholder='R$ 0,00'
              />
            </FormGroup>
            {/* Status */}
            <FormGroup className='col-md-6 mb-4'>
              <Label>Status</Label>
              <ChecksGroup
                isValid={formik.isValid}
                isTouched={formik.touched.active}
                invalidFeedback={formik.errors.active}
              >
                <Checks
                  type="radio"
                  id="active"
                  label="Ativo"
                  name="active"
                  value="ativo"
                  onChange={formik.handleChange}
                  checked={formik.values.active}
                  isInline
                />
                <Checks
                  type="radio"
                  id="active2"
                  label="Inativo"
                  name="active"
                  value="inativo"
                  onChange={formik.handleChange}
                  checked={formik.values.active}
                  isInline
                />
              </ChecksGroup>
            </FormGroup>
          </div>
          {/* Buttons */}
          <div className="row pt-4">
            <div className='d-flex justify-content-end gap-2'>
              <Button
                color="danger"
                icon="cancel"
                rounded={1}
                onClick={setIsClose}
                shadow="sm"
                hoverShadow="sm"
                size="sm"
              >
                Cancelar
              </Button>
              {isLoading ? (
                <BtnLoad />
              ) : (
                <Button
                  isDisable={!formik.isValid && !!formik.submitCount}
                  type='submit'
                  color='primary'
                  icon="check"
                  rounded={1}
                  hoverShadow="sm"
                  shadow="sm"
                  size="sm"
                >
                  Confirmar
                </Button>
              )}
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default ModalRegisterboxOffice;