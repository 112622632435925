import Store from "../../database/entities/store.entity";
import apiFiscal from "../../services/apiFiscal";
import Money from "../../helpers/utils/money/money";
import { PAYMENT_TYPE } from "../../types/payment";
import CommandProduct from "../../database/entities/sales/commandProduct";
class Fiscal {
  private sentData: any;
  constructor(
    private productsDicionary: any,
    private complementsDicionary: any,
  ) { }

  generateFiscal(hash: string, products: CommandProduct[], store: Store, payment_type: number, discount: number, cpf?: string) {
    const productsFiscal = this.mountProductsFiscal(products);
    if (discount > 0 || payment_type === PAYMENT_TYPE.COURTESY)
      this.distributeDiscount(productsFiscal, discount, payment_type);
    return this.postFiscal(hash, productsFiscal, store, payment_type, cpf)
  }

  private postFiscal(hash: string, products: any[], store: Store, payment_type: number, cpf?: string,) {

    const data = {
      cnpj: store.cnpj.replace(/\D/g, ""),
      payment_type: this.convertType(payment_type),
      total_price: parseFloat(products.reduce((prev, curr) => prev + curr.price * curr.amount, 0).toFixed(2)),
      cpf: cpf ? cpf.replace(/\D/g, "") : null,
      hash: hash ? hash : null,
      discount_total: products.reduce((prev, curr) => prev + (curr.discount || 0), 0),
      products,
    }
    this.sentData = data;
    return apiFiscal.getIssuedFiscal(data)
  }
  private mountProductsFiscal(products: CommandProduct[]) {
    const productsFiscal = products.map(product => {
      const dictionary = (product as any).type === 0 ? this.productsDicionary : this.complementsDicionary;
      return {
        id: product.id,
        name: dictionary[product.id]?.name || 'Indefinido',
        price: Money.centsToCoin(Math.round(product.totalFiscal / product.qtdFiscal)),
        amount: product.qtdFiscal,
        // Dados fiscais
        ncm: dictionary[product.id]?.fiscal_data?.ncm,
        cfop: dictionary[product.id]?.fiscal_data?.cfop,
        cest: dictionary[product.id]?.fiscal_data?.cest,
        csosn: dictionary[product.id]?.fiscal_data?.csosn,
        cst: dictionary[product.id]?.fiscal_data?.cst,
        icms: dictionary[product.id]?.fiscal_data?.aliq_icms,
      }
    })
    return productsFiscal;
  }

  private distributeDiscount(products: any[], discount: number, payment_type: number) {
    let remainingDiscount = discount / 100;
    products.forEach(p => {
      const totalPrice = p.price * p.amount;
      const value = totalPrice > remainingDiscount ? remainingDiscount : totalPrice;
      p.discount = payment_type === PAYMENT_TYPE.COURTESY ? totalPrice : value;
      remainingDiscount -= value;
    })
  }

  private convertType(type: number) {
    if (type === PAYMENT_TYPE.CASH) return '01';
    if (type === PAYMENT_TYPE.CARD_CREDIT) return '03';
    if (type === PAYMENT_TYPE.CARD_DEBIT) return '04';
    if (type === PAYMENT_TYPE.PIX) return '20';
    return '01';
  }

  public getSentData() {
    return this.sentData;
  }

}

export default Fiscal;