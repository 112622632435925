import Sale from "../../../database/entities/sales/sale.entity";
import { OrderIfood } from "../../../types/ordersIfood";
import Money from "../money/money";

function getStartEndWeek() {
  const curr = new Date; // get current date
  curr.setDate(curr.getDate())
  const first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
  const last = first + 7; // last day is the first day + 6

  const firstdayWeek = new Date(curr.setDate(first)).getDate();
  const lastdayWeek = new Date(curr.setDate(last - 1)).getDate();

  return { firstdayWeek, lastdayWeek };
}

function differenceTwoDates(start: any, end: any) {
  const diffTime = Math.abs(end - start);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
}

export function computeSalesToHours(sales: Sale[]) {
  const hours = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  sales.forEach(sale => {
    if(sale.reversed) return;
    const saleDate = sale.created_at.toDate();
    
    hours[saleDate.getHours()] += sale.value;
  })

  return {
    hours: hours.map(item => Money.centsToCoin(item)),
  }
}

export function computeIfoodToHours(orders: OrderIfood[]) {
  const hours = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  orders.forEach(order => {
    if(order.status === `CAN`) return;
    const orderDate = order.created_at.toDate()

    hours[orderDate.getHours()] += order.subTotal
  })

  return {
    hours: hours.map(item => Money.centsToCoin(item)),
  }
}