import { QueryConstraint, Unsubscribe } from "firebase/firestore";
import PaymentCommand from "../entities/sales/paymentCommand";
import FirestorePipe from "../utils/pipe";
import EstablishmentDB from "./establishment";

class PaymentCommandDB extends FirestorePipe {

  public static readonly colName = 'PaymentCommand'

  constructor(idEstab: string) {
    if(!idEstab)
      throw new Error('Informe o id da loja e do estabelecimento')
    super(`${EstablishmentDB.colName}/${idEstab}/${PaymentCommandDB.colName}`);
  }

  public create(data: PaymentCommand): Promise<any> {
    throw new Error('Method not exists')
  }
  public update(id: string, data: PaymentCommand): Promise<any> {
    throw new Error('Method not exists')
  }
  public delete(id: string): Promise<any> {
    throw new Error('Method not exists')
  }
  public getAll(...params: QueryConstraint[]): Promise<PaymentCommand[]> {
    return this._getAll<PaymentCommand>(...params);
  }
  public get(id: string): Promise<PaymentCommand> {
    return this._get(id);
  }
  public on(listener: (data: PaymentCommand[]) => void, ...params: QueryConstraint[]): Unsubscribe {
    return this._on(listener, ...params);
  }

}

export default PaymentCommandDB;