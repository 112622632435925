import { Firestore, QueryConstraint, Unsubscribe, collection, getDocs, getFirestore, query, where } from "firebase/firestore";
import Client from "../entities/client.entity";
import FirestorePipe from "../utils/pipe";

class ClientDB extends FirestorePipe {

  public static readonly colName = 'Clients'

  constructor() {
    super(ClientDB.colName);
  }

  public create(data: Client): Promise<any> {
    return this._create(data);
  }
  public update(id: string, data: Client): Promise<any> {
    return this._update(id, data);
  }
  public delete(id: string): Promise<any> {
    return this._delete(id);
  }
  public getAll(...params: QueryConstraint[]): Promise<Client[]> {
    return this._getAll<Client>(...params);
  }
  public get(id: string): Promise<Client> {
    return this._get(id);
  }
  public on(listener: (data: Client[]) => void, ...params: QueryConstraint[]): Unsubscribe {
    return this._on(listener, ...params);
  }

  async getByCPF(cpf: string): Promise<Client | null> {
    try {
      const firestoreInstance: Firestore = getFirestore();
      const clientsCollection = collection(firestoreInstance, "Clients");
      const q = query(clientsCollection, where("cpf", "==", cpf));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const document = querySnapshot.docs[0];
        return {
          id: document.id,
          ...document.data(),
        } as Client;
      }
      return null;
    } catch (e) {
      console.error('Ocorreu um erro ao buscar o cliente:', e);
      return null;
    }
  }

}

export default ClientDB;