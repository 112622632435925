import { APP_TYPE } from "./types/apps";
import { ACCESS_LEVEL } from "./types/roles";

export const homeMenu = {
  intro: { id: 'intro', text: 'Intro', path: '#intro', icon: 'Vrpano', subMenu: null },
  bootstrap: {
    id: 'bootstrap',
    text: 'Bootstrap Components',
    path: '#bootstrap',
    icon: 'BootstrapFill',
    subMenu: null,
  },
  storybook: {
    id: 'storybook',
    text: 'Storybook',
    path: '#storybook',
    icon: 'CustomStorybook',
    subMenu: null,
  },
  formik: {
    id: 'formik',
    text: 'Formik',
    path: '#formik',
    icon: 'CheckBox',
    subMenu: null,
  },
  apex: {
    id: 'apex',
    text: 'Apex Charts',
    path: '#apex',
    icon: 'AreaChart',
    subMenu: null,
  },
};

export const authPages = {
  signUp: {
    id: 'signUp',
    text: 'Sign Up',
    path: '/sign-up',
    icon: 'PersonAdd',
  },
  login: {
    id: 'login',
    text: 'Login',
    path: '/',
    icon: 'Login',
  },
  logout: {
    id: 'logout',
    text: 'Sair',
    path: '/logout',
    icon: 'Logout',
  },
  page404: {
    id: 'Page404',
    text: '404 Page',
    path: '/404',
    icon: 'ReportGmailerrorred',
  },
  profile: {
    id: 'profile',
    text: 'Perfil Temp',
    path: '/profile',
    icon: 'Person',
  },
}

export const othersMenu = {
  termsOfUse: {
    id: 'termsOfUse',
    text: 'Cadastro',
    path: '/privacidade',
    icon: 'PersonAdd',
  }
};
export const dashboardMenu = {
  dashboard: {
    id: 'dashboard',
    text: 'Dashboard',
    path: '/dashboard',
    icon: 'Dashboard',
    subMenu: null,
  },
};

export const demoPages = {
  pageLayout: {
    id: 'registers',
    text: 'Cadastros',
    path: 'registers',
    icon: 'AddBox',
    min_level: ACCESS_LEVEL.COORDINATOR,
    subMenu: {
      establishment: {
        id: 'establishment',
        text: 'Estabelecimento',
        path: 'registers/establishment',
        icon: 'BusinessCenter',
        min_level: ACCESS_LEVEL.ADMIN,
      },
      stores: {
        id: 'stores',
        text: 'Lojas',
        path: 'registers/stores',
        icon: 'LocalMall',
        min_level: ACCESS_LEVEL.MANAGER,
      },
      clients: {
        id: 'clients',
        text: 'Clientes',
        path: 'register/clients',
        icon: 'People',
      },
      users: {
        id: 'users-register',
        text: 'Usuários',
        path: 'registers/users',
        icon: 'Person',
        min_level: ACCESS_LEVEL.COORDINATOR,
      },
      totens: {
        id: 'totens',
        text: 'Totem',
        path: 'registers/totens',
        icon: 'Monitor',
        min_level: ACCESS_LEVEL.COORDINATOR,
      },
      goals: {
        id: 'goals',
        text: 'Metas',
        path: 'registers/goals',
        icon: 'ShowChart',
        min_level: ACCESS_LEVEL.MANAGER,
      },
    },
  },
  pdvMenu: {
    id: 'pdvMenu',
    text: 'PDV',
    path: '/pdv',
    icon: 'ImportantDevices',
    min_level: ACCESS_LEVEL.COORDINATOR,
    subMenu: {
      complement: {
        id: 'complement',
        text: 'Complemento',
        path: 'pdv/complement',
        icon: 'Bookmark',
      },
      categoryComplement: {
        id: 'categoryComplement',
        text: 'Categoria do Complemento',
        path: 'pdv/categoryComplement',
        icon: 'Bookmarks',
      },
      product: {
        id: 'product',
        text: 'Produtos',
        path: 'pdv/products',
        icon: 'TagsFill',
      },
      subCategories: {
        id: 'subCategories',
        text: 'Sub-Categorias',
        path: 'pdv/subCategories',
        icon: 'Subtract',
      },
      categories: {
        id: 'categories',
        text: 'Categorias',
        path: 'pdv/categories',
        icon: 'Category',
      },
      suggestion: {
        id: 'suggestion',
        text: 'Sugestão',
        path: 'pdv/suggestion',
        icon: 'HistoryEdu',
      },
      menus: {
        id: 'menus',
        text: 'Cardápios',
        path: 'pdv/menus',
        icon: 'MenuBook',
      },
    },
  },
  store: {
    id: 'store',
    text: 'Loja',
    path: '/store',
    icon: 'Store',
    min_level: ACCESS_LEVEL.CASHIER,
    subMenu: {
      reasons: {
        id: 'reasons',
        text: 'Justificativas',
        path: 'store/reasons',
        icon: 'Comment',
      }, 
      cupons: {
        id: 'cupons',
        text: 'Cupons',
        path: 'store/cupons',
        icon: 'confirmationNumber',
      },
      complement: {
        id: 'complement',
        text: 'Complementos',
        path: 'store/complement',
        icon: 'Bookmark',
      },
      product: {
        id: 'product',
        text: 'Produtos',
        path: 'store/products',
        icon: 'TagsFill',
      },
      stockProducts: {
        id: 'stock-products',
        text: 'Estoque Produtos',
        path: 'store/stockProducts',
        icon: 'AllInbox',
      },
      stockComponents: {
        id: 'stock-components',
        text: 'Estoque Componente',
        path: 'store/stockComponents',
        icon: 'Inbox',
      },
      defect: {
        id: 'defect',
        text: 'Avariados',
        path: 'store/defect',
        icon: 'ReportProblem',
      },
      cashier: {
        id: 'cashier',
        text: 'Caixas',
        path: 'store/cashier',
        icon: 'AttachMoney',
        min_level: ACCESS_LEVEL.COORDINATOR,
      },
      accessPassword: {
        id: 'access-password',
        text: 'Senha de Acesso',
        path: 'store/accessPasswrod',
        icon: 'Security',
      },
      printers: {
        id: 'printers',
        text: 'Impressoras',
        path: 'store/printers',
        icon: 'Printer',
      },
      request: {
        id: 'request',
        text: 'Pedidos',
        path: 'store/request',
        icon: 'MenuBook',
      },
      settings: {
        id: 'storeSettings',
        text: 'Configurações',
        path: 'store/settings',
        icon: 'Settings',
      },
    },
  },
  delivery: {
    id: 'delivery',
    text: 'Delivery',
    path: 'delivery',
    icon: 'DeliveryDining',
    subMenu: {
      lojas: {
        id: 'lojas',
        text: 'Lojas',
        path: 'delivery/lojas',
        icon: 'Store'
      },
      gerenciamento: {
        id: 'gerenciamento',
        text: 'Gerenciamento Delivery',
        path: 'delivery/gerenciamento',
        icon: 'Home'
      },
    },
  },
  fiscal: {
    id: 'fiscal',
    text: 'Fiscal',
    path: '/fiscal',
    icon: 'RequestPage',
    subMenu: {
      unissuedNotes: {
        id: 'unissuedNotes',
        text: 'Notas não emitidas',
        path: 'fiscal/unissuedNotes',
        icon: 'InsertDriveFile',
      },
    },
  },
  command: {
    id: 'command',
    text: 'Comanda',
    path: '/command',
    icon: 'CardMembership',
    onlyApps: [APP_TYPE.COMMAND],
    min_level: ACCESS_LEVEL.COORDINATOR,
    subMenu: {
      boxOffice: {
        id: 'boxOffice',
        text: 'Bilheteria',
        path: 'command/boxoffice',
        icon: 'ExitToApp',
      },
    },
  },
  // notifications: {
  //   id: 'notifications',
  //   text: 'Notificações',
  //   path: 'notifications',
  //   icon: 'Notifications',
  // },
  // contactUs: {
  //   id: 'contactUs',
  //   text: 'Suporte',
  //   path: 'contactUs',
  //   icon: 'ContactSupport',
  // },
  // documentations: {
  //   id: 'documentations',
  //   text: 'Documentação',
  //   path: 'documentations',
  //   icon: 'TextSnippet',
  // },
  reports: {
    id: 'reports',
    text: 'Relatórios',
    path: 'reports',
    icon: 'QueryStats',
    subMenu: {
      faturamentoPorDia: {
        id: 'faturamentoPorDia',
        text: 'Faturamento',
        path: 'reports/faturamentoPorDia',
        icon: 'BarChart'
      },
      userHistory: {
        id: 'userHistory',
        text: 'Histórico de Usuário',
        path: 'reports/userHistory',
        icon: 'BugReport'
      },
      feedbacks: {
        id: 'feedbacks',
        text: 'Feedbacks',
        path: 'reports/feedbacks',
        icon: 'ThumbUpAlt'
      },
    },
  },

  settings: {
    id: 'settings',
    text: 'Configurações',
    path: 'settings',
    icon: 'Settings',
  },

  //profile: {
  //   id: 'profile',
  //   text: 'Perfil Temp',
  //   path: 'profile',
  //   icon: 'Person',
  //},
};

// export const layoutMenu = {
//   // layoutTypes: {
//   //   id: 'layoutTypes',
//   //   text: 'Page Layout Types',
//   // },
//   // blank: {
//   //   id: 'blank',
//   //   text: 'Blank',
//   //   path: 'page-layouts/blank',
//   //   icon: 'check_box_outline_blank ',
//   // },
//   // pageLayout: {
//   //   id: 'pageLayout',
//   //   text: 'Page Layout',
//   //   path: 'page-layouts',
//   //   icon: 'BackupTable',
//   //   subMenu: {
//   //     headerAndSubheader: {
//   //       id: 'headerAndSubheader',
//   //       text: 'Header & Subheader',
//   //       path: 'page-layouts/header-and-subheader',
//   //       icon: 'ViewAgenda',
//   //     },
//   //     onlyHeader: {
//   //       id: 'onlyHeader',
//   //       text: 'Only Header',
//   //       path: 'page-layouts/only-header',
//   //       icon: 'ViewStream',
//   //     },
//   //     onlySubheader: {
//   //       id: 'onlySubheader',
//   //       text: 'Only Subheader',
//   //       path: 'page-layouts/only-subheader',
//   //       icon: 'ViewStream',
//   //     },
//   //     establishment: {
//   //       id: 'establishment',
//   //       text: 'Estabelecimento',
//   //       path: 'registers/establishment',
//   //       icon: 'BusinessCenter',
//   //     },
//   //     categories: {
//   //       id: 'categories',
//   //       text: 'Categorias',
//   //       path: 'registers/categories',
//   //       icon: 'Category',
//   //     },
//   //     totens: {
//   //       id: 'totens',
//   //       text: 'Totem',
//   //       path: 'registers/totens',
//   //       icon: 'Monitor',
//   //     },
//   //     stores: {
//   //       id: 'stores',
//   //       text: 'Lojas',
//   //       path: 'registers/stores',
//   //       icon: 'LocalMall',
//   //     },
//   //     products: {
//   //       id: 'products',
//   //       text: 'Produtos',
//   //       path: 'registers/products',
//   //       icon: 'TagsFill',
//   //     },
//   //     subCategories: {
//   //       id: 'subCategories',
//   //       text: 'Sub-Categorias',
//   //       path: 'registers/subCategories',
//   //       icon: 'Subtract',
//   //     },
//   //     complement: {
//   //       id: 'complement',
//   //       text: 'Complemento',
//   //       path: 'registers/complement',
//   //       icon: 'PlusCircleDotted',
//   //     },
//   //     users: {
//   //       id: 'users-register',
//   //       text: 'Usuários',
//   //       path: 'registers/users',
//   //       icon: 'PlusCircleDotted',
//   //     },
//   //     categoryComplement: {
//   //       id: 'categoryComplement',
//   //       text: 'Categoria do Complemento',
//   //       path: 'registers/categoryComplement',
//   //       icon: 'Bookmarks',
//   //     },
//   //     menus: {
//   //       id: 'menus',
//   //       text: 'Menu',
//   //       path: 'pdv/menus',
//   //       icon: 'MenuBook',
//   //     },
//   //     stocks: {
//   //       id: 'stocks',
//   //       text: 'Estoque',
//   //       path: 'pdv/stocks',
//   //       icon: 'AllInbox',
//   //     },
//   //     onlyContent: {
//   //       id: 'onlyContent',
//   //       text: 'Only Content',
//   //       path: 'page-layouts/only-content',
//   //       icon: 'WebAsset',
//   //     },
//   //   },
//   // },
//   // asideTypes: {
//   //   id: 'asideTypes',
//   //   text: 'Aside Types',
//   //   path: 'aside-types',
//   //   icon: 'Vertical Split',
//   //   subMenu: {
//   //     defaultAside: {
//   //       id: 'defaultAside',
//   //       text: 'Default Aside',
//   //       path: 'aside-types/default-aside',
//   //       icon: 'ViewQuilt',
//   //     },
//   //     minimizeAside: {
//   //       id: 'minimizeAside',
//   //       text: 'Minimize Aside',
//   //       path: 'aside-types/minimize-aside',
//   //       icon: 'View Compact',
//   //     },
//   //   },
//   // },
// };

export const productsMenu = {
  companyA: { id: 'companyA', text: 'Company A', path: 'grid-pages/products', subMenu: null },
  companyB: { id: 'companyB', text: 'Company B', path: '/', subMenu: null },
  companyC: { id: 'companyC', text: 'Company C', path: '/', subMenu: null },
  companyD: { id: 'companyD', text: 'Company D', path: '/', subMenu: null },
};
