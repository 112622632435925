import React, { useContext, useEffect, useState, useCallback, useMemo } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../../layout/SubHeader/SubHeader';
import Page from '../../../../layout/Page/Page';
import Breadcrumb from '../../../../components/bootstrap/Breadcrumb';
import Button from '../../../../components/bootstrap/Button';
import { demoPages } from '../../../../menu';
import { DefaultContext } from '../../../../contexts/default';
import SuggestionDB from '../../../../database/wrappers/suggestion';
import SelectEstablishment from '../../../../components/MyCustom/SelectEstablishment';
import Card, { CardBody } from '../../../../components/bootstrap/Card';
import TableCustom from '../../../../components/MyCustom/TableCustom';
import showNotification from '../../../../components/extras/showNotification';
import notificationBody from '../../../../helpers/utils/functions/notificationBody';
import { orderBy } from 'firebase/firestore';
import { ACCESS_LEVEL } from '../../../../types/roles';
import ModalRegisterSuggestion from '../../../../components/modals/Suggestion/ModalRegister';
import UserHistory from '../../../../database/wrappers/userHistory';

const Suggestions = () => {
  const { accessLevel, establishment, onShowAlert, onShowQuestion, user } = useContext(DefaultContext);

  const [openModalRegister, setOpenModalRegister] = useState(false);
  const [suggestions, setsuggestions] = useState([]);
  const [suggestionSelected, setsuggestionSelected] = useState({});

  useEffect(() => {
    if (!establishment) return;
    const onSubscribe = new SuggestionDB(establishment.id).on(setsuggestions, orderBy('name', 'asc'));
    return onSubscribe;
  }, [establishment])

  const handleOpenModalRegister = useCallback(() => {
    setsuggestionSelected(null)
    setOpenModalRegister(true);
  }, [])

  const handleCloseModalRegister = useCallback(() => {
    setOpenModalRegister(false);
  }, [])

  const handleOpenModalEdit = useCallback((item) => {
    setsuggestionSelected(item);
    setOpenModalRegister(true)
  }, [])

  const handleOpenModalDelete = useCallback((row) => {
    onShowQuestion({
      title: 'Exclusão de Sugestão',
      message: 'Você está excluindo uma sugestão. Está ação é irreversível. Deseja continuar?',
      onConfirm: () => {
        new SuggestionDB(establishment.id)
        .delete(row.id)
        .then(res => {
          showNotification('', notificationBody('Registro deletado com sucesso'), 'success');
          new UserHistory(establishment.id, user, "a Sugestão")
              .deleteWithData(row.id, row)
              .finally(() => { })
        })
        .catch(err => showNotification('', notificationBody('Falhou ao deletar o registro'), 'danger'))
      }
    })
  }, [establishment])

  const handleChangeStatus = useCallback((id, active) => {
    new SuggestionDB(establishment.id)
    .update(id, {
      active: !active
    })
    .catch(error => onShowAlert({
      title: 'ERRO!',
      msm: 'Não foi possível atualizar o status da sugestão',
      icon: 'Error',
      color: 'danger'
    }))
  }, [establishment])

  const columns = useMemo(() => ([
    {
      label: 'Nome',
      field: 'name',
    },
    {
      label: 'Descrição',
      field: 'description',
    },
    {
      label: 'Status',
      field: 'active',
      format: row => (
        <>
          <Button
            isLink
            color={row.active ? 'success' : 'danger'}
            icon='Circle'
            onClick={() => handleChangeStatus(row.id, row.active)}
            className='text-nowrap'>
            {row.active ? 'Ativo' : 'Inativo'}
          </Button>
        </>
      ),
    },
    {
      label: 'Ações',
      field: 'actions',
      format: row => (
        <div className='w-50 d-flex justify-content-between'>
          <Button
            color="light"
            icon="edit"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleOpenModalEdit(row)}
          />

          <Button
            color="danger"
            icon="Trash"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleOpenModalDelete(row)}
          />
        </div>
      ),
      noExport: true
    },
  ]), [handleChangeStatus, handleOpenModalEdit, handleOpenModalDelete])

  return (
    <>
      <PageWrapper title={demoPages.pdvMenu.subMenu.suggestion.text}>
        <SubHeader>
          <SubHeaderLeft>
            <Breadcrumb
              list={[
                { title: 'Pdv', to: '/pdv/' },
                {
                  title: 'Sugestão',
                  to: '/pdv/suggestion',
                },
              ]}
            />
          </SubHeaderLeft>
          <SubHeaderRight>
            {accessLevel >= ACCESS_LEVEL.MANAGER &&
            <Button
              color='primary'
              icon='plus'
              shadow="sm"
              hoverShadow="sm"
              onClick={handleOpenModalRegister}
            >
              Cadastrar
            </Button>}
          </SubHeaderRight>
        </SubHeader>
        <Page container="fluid">
          <Card>
            <CardBody className='table-responsive p-5'>
              <SelectEstablishment />
              <TableCustom columns={columns} rows={suggestions} fileName={'Sugestão'}/>
            </CardBody>
          </Card>
        </Page>
      </PageWrapper>

      <ModalRegisterSuggestion
        open={openModalRegister}
        setIsOpen={setOpenModalRegister}
        setIsClose={handleCloseModalRegister}
        suggestionSelected={suggestionSelected}
      />
    </>
  );
};

export default Suggestions;
