import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../../layout/SubHeader/SubHeader';
import Page from '../../../../layout/Page/Page';
import Button from '../../../../components/bootstrap/Button';
import { demoPages } from '../../../../menu';
import ModalRegisterReason from '../../../../components/modals/Reasons/ModalRegister';
import { DefaultContext } from '../../../../contexts/default';
import { orderBy } from 'firebase/firestore';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../../../components/bootstrap/Card';
import TableCustom from '../../../../components/MyCustom/TableCustom';
import showNotification from '../../../../components/extras/showNotification';
import notificationBody from '../../../../helpers/utils/functions/notificationBody';
import Breadcrumb from '../../../../components/bootstrap/Breadcrumb';
import useDarkMode from '../../../../hooks/useDarkMode';
import SelectEstablishment from '../../../../components/MyCustom/SelectEstablishment';
import SelectStore from '../../../../components/MyCustom/SelectStore';
import ReasonDB from '../../../../database/wrappers/reason';

const Reasons = () => {
  const { darkModeStatus } = useDarkMode();
  const { estabSelected, storeSelected, onShowQuestion } = useContext(DefaultContext)
  const [openModalRegister, setOpenModalRegister] = useState(false);
  const [reasons, setReasons] = useState([])
  const [reasonSelected, setReasonSelected] = useState(null)

  useEffect(() => {
    if (!estabSelected || !storeSelected) return console.error('estabSelected or storeSelected not found');
    const onSubscriber = new ReasonDB(estabSelected, storeSelected).on(setReasons, orderBy('name', 'asc'))
    return onSubscriber;
  }, [estabSelected, storeSelected])

  const handleCloseModal = useCallback(() => {
    setOpenModalRegister(false)
  }, [])

  const handleRegister = useCallback(() => {
    setReasonSelected(null);
    setOpenModalRegister(true)
  }, [])

  const handleEdit = useCallback((item) => {
    setReasonSelected(item);
    setOpenModalRegister(true)
  }, [])

  const handleChangeItem = useCallback((id, key, value) => {
    new ReasonDB(estabSelected, storeSelected)
      .update(id, {
        [key]: value
      })
      .then(() => showNotification('', notificationBody('Registro alterado com sucesso'), 'success'))
      .catch(() => showNotification('', notificationBody('Falha ao atualizar o registro'), 'danger'))
  }, [estabSelected, storeSelected])

  const handleOpenModalDelete = useCallback((id) => {
    onShowQuestion({
      title: 'Exclusão de Justificativa',
      message: 'Você está excluindo uma justificativa. Está ação é irreversível. Deseja continuar?',
      onConfirm: () => {
        new ReasonDB(estabSelected, storeSelected)
          .delete(id)
          .then(res => showNotification('', notificationBody('Registro excluído com sucesso'), 'success'))
          .catch(err => showNotification('', notificationBody('Falha excluir o registro'), 'danger'))
      }
    })
  }, [estabSelected, storeSelected, onShowQuestion])

  const rowsRender = useMemo(() =>
    reasons.map(row => ({
      ...row,
      name: row.name,
      active: row.active,
    }))
    , [reasons])

  const columns = useMemo(() => ([
    {
      label: 'Descrição',
      field: 'name',
    },
    {
      label: 'Status',
      field: 'active',
      format: row => (
        <Button
          isLink
          color={row.active ? 'success' : 'danger'}
          icon='Circle'
          className='d-flex justify-content-center align-items-center text-nowrap'
          onClick={() => handleChangeItem(row.id, 'active', !row.active)}
        >
          {row.active ? 'Ativo' : 'Inativo'}
        </Button>
      )
    },
    {
      label: 'Ações',
      field: 'actions',
      format: row => (
        <div className='w-75 d-flex justify-content-between'>
          <Button
            color={darkModeStatus ? 'light' : 'dark'}
            icon="edit"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleEdit(row)}
          />
          <Button
            color="danger"
            icon="Trash"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleOpenModalDelete(row.id)}
          />
        </div>
      ),
      noExport: true
    },
  ]), [darkModeStatus, handleOpenModalDelete, handleChangeItem, handleEdit])

  return (
    <>
      <PageWrapper title={demoPages.store.subMenu.reasons.text}>
        <SubHeader>
          <SubHeaderLeft>
            <Breadcrumb
              list={[
                { title: 'Evento', to: '/event/bilheteria' },
                { title: 'Justificativas', to: '/event/bilheteria' },
              ]}
            />
          </SubHeaderLeft>
          <SubHeaderRight>
            <Button
              color='primary'
              icon='plus'
              shadow="sm"
              hoverShadow="sm"
              onClick={handleRegister}
            >
              Cadastrar
            </Button>
          </SubHeaderRight>
        </SubHeader>

        <Page container="fluid">
          <Card>
            <CardHeader>
              <CardLabel icon='Comment' iconColor={darkModeStatus ? 'light' : 'dark'}>
                <CardTitle>Categorização de Cortesias e Descontos</CardTitle>
              </CardLabel>
            </CardHeader>
            <CardBody className='table-responsive'>
              <div className='row'>
                <SelectEstablishment />
                <SelectStore />
              </div>

              <TableCustom
                rows={rowsRender}
                columns={columns}
                keyExtractor={row => row.id}
                fileName={'Categorizacao-de-cortesia-e-descontos'}
              />
            </CardBody>
          </Card>
        </Page>

        <ModalRegisterReason
          open={openModalRegister}
          setIsOpen={setOpenModalRegister}
          setIsClose={handleCloseModal}
          reasonSelected={reasonSelected}
        />
      </PageWrapper>
    </>
  );
};

export default Reasons;
