import { doc, getFirestore, onSnapshot, setDoc } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import Button from "../../../../components/bootstrap/Button";
import Card, { CardBody, CardFooter, CardHeader, CardLabel, CardTitle } from "../../../../components/bootstrap/Card";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../components/bootstrap/forms/Input";
import SelectEstablishment from "../../../../components/MyCustom/SelectEstablishment";
import SelectStore from "../../../../components/MyCustom/SelectStore";
import { DefaultContext } from "../../../../contexts/default";
import app from "../../../../database/config";
import EstablishmentDB from "../../../../database/wrappers/establishment";
import PreAlert from "../../../../helpers/utils/preAlert";
import useDarkMode from "../../../../hooks/useDarkMode";
import Page from "../../../../layout/Page/Page";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import { demoPages } from "../../../../menu";

function Request() {
  const { darkModeStatus } = useDarkMode()

  const { estabSelected, storeSelected, onShowAlert } = useContext(DefaultContext)

  const [requestNumber, setrequestNumber] = useState('')
  const [errorMsm, seterrorMsm] = useState('')

  useEffect(() => {
    const docRef = doc(getFirestore(app), `${EstablishmentDB.colName}/${estabSelected}/RequestNumber/${storeSelected}`)
    return onSnapshot(docRef, snapshot => setrequestNumber(snapshot.data()?.number.toString() || ''))
  },[estabSelected, storeSelected])

  const onChangeValue = (e) => {
    const txtOnlyNumbers = e.target.value.replace(/\D/g, "");
    setrequestNumber(txtOnlyNumbers)
  }

  function sendUpdate(number) {
    const docRef = doc(getFirestore(app), `${EstablishmentDB.colName}/${estabSelected}/RequestNumber/${storeSelected}`)
    setDoc(docRef, {
      number,
    })
    .then(() => onShowAlert(PreAlert.success('Registro salvo com sucesso')))
    .catch(() => onShowAlert(PreAlert.error('Não foi possível alterar o registro')))
  }

  const handleSubmit = (e) => {
    e?.preventDefault();
    const number = Number(requestNumber)
    if(isNaN(number)) {
      return seterrorMsm('Número inválido')
    }
    sendUpdate(number);
  }

  return (
    <PageWrapper title={demoPages.store.subMenu.request.text}>
      <Page className=''>
        <div className="column">
          <SelectEstablishment />
          <SelectStore />
        </div>
        <form onSubmit={handleSubmit} className='row pt-5 justify-content-start'>
          <Card className='col-md-4'>
            <CardHeader>
              <CardLabel icon='MenuBook' iconColor={darkModeStatus ? 'light' : 'dark'}>
                <CardTitle>Número do pedido</CardTitle>
              </CardLabel>
            </CardHeader>
            <CardBody className='p-5'>
              <FormGroup id="requestNumber" label="Número do pedido" className='col-md-12'>
                <Input
                  onChange={onChangeValue}
                  value={requestNumber}
                  isValid={!!errorMsm}
                  // isTouched={formik.touched.price}
                  invalidFeedback={errorMsm}
                />
              </FormGroup>
            </CardBody>
            <CardFooter className='d-flex flex-row-reverse'>
              <div className="row col-md-6">
                <Button
                  className='col-md mx-2'
                  type='button'
                  color='warning'
                  icon="Refresh"
                  rounded={1}
                  size="sm"
                  onClick={() => sendUpdate(0)}
                >
                  Reset
                </Button>
                <Button
                  className='col-md mx-2'
                  type='submit'
                  color='primary'
                  icon="Save"
                  rounded={1}
                  size="sm"
                >
                  Salvar
                </Button>
              </div>
            </CardFooter>
          </Card>
        </form>
      </Page>
    </PageWrapper >
  );
}

export default Request;