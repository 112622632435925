import React, { useContext, useEffect, useState } from 'react';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../../../bootstrap/Modal';
import TableCustom from '../../../../MyCustom/TableCustom';
import Logo from '../../../../Logo';
import Money from '../../../../../helpers/utils/money/money';
import Button from '../../../../bootstrap/Button';
import Spinner from '../../../../bootstrap/Spinner';
import { DefaultContext } from '../../../../../contexts/default';
import ComplementDB from '../../../../../database/wrappers/complement';
import StoreComplementDB from '../../../../../database/wrappers/storeComplement';
import api from '../../../../../services/api';
import CategoryComplementDB from '../../../../../database/wrappers/categoryComplement';
import { orderBy } from 'firebase/firestore';
import FormGroup from '../../../../bootstrap/forms/FormGroup';
import Input from '../../../../bootstrap/forms/Input';
import Popovers from '../../../../bootstrap/Popovers';
import PreAlert from '../../../../../helpers/utils/preAlert';
import { ResultsContext } from '../../../../../contexts/results';

export const EditModalComplement = ({ open, handleOpen, productSelected }) => {
    const { store, establishment, onShowAlert } = useContext(DefaultContext);
    const {storeInfo} = useContext(ResultsContext)

    const [complements, setComplements] = useState([])
    const [storeComplementsDicionary, setStoreComplementsDicionary] = useState([])
    const [complementsRender, setComplementsRender] = useState([])
    const [loading, setloading] = useState(false);
    const [complementsIds, setComplementsIds] = useState([])

    useEffect(() => {
        if (establishment && productSelected) {
            async function fetchComplements() {
                await new CategoryComplementDB(establishment.id)
                    .getAll(orderBy('name', 'asc'))
                    .then((data) => {
                        const catCate = data?.filter((category) => productSelected?.complements?.includes(category.id))

                        const complements = new Set([])
                        catCate?.forEach((cat) => {
                            cat?.complements?.forEach((catComp) => {
                                complements.add(catComp);
                            })

                        })
                        setComplementsIds(complements)
                    })
            }
            fetchComplements()
        }
    }, [establishment, productSelected])

    useEffect(() => {
        async function fetchComplementsInfo() {
            if (establishment)
                await new ComplementDB(establishment.id)
                    .getAll(orderBy('name', 'asc'))
                    .then((data) => {
                        const complements = [];
                        complementsIds?.forEach((co) => {
                            const comp = data?.find((c) => c.id === co)
                            if (comp)
                                complements.push(comp)
                        })
                        setComplements(complements)
                    })
        }
        fetchComplementsInfo()
    }, [establishment, complementsIds])

    useEffect(() => {
        if (!establishment || !store) return;
        const onSubscribe = new StoreComplementDB(establishment.id, store.id).on(datas => {
            const dicionary = {};
            datas.forEach(data => dicionary[data.id] = data)
            setStoreComplementsDicionary(dicionary)
        });
        return onSubscribe;
    }, [establishment, store])

    useEffect(() => {
        if (complements !== null && storeComplementsDicionary !== null) {
            const complementsRender = complements?.map(complement => {
                const storeComplement = storeComplementsDicionary[complement.id];
                return {
                    id: complement.id,
                    name: complement.name,
                    delivery_code: complement.delivery_code,
                    ifoodId: storeComplement?.ifoodId,
                    toRappi: storeComplement?.toRappi,
                    image_url: complement?.image_url,
                    storeId: storeComplement?.id,

                    ifoodPrice: storeComplement?.ifoodPrice || storeComplement?.price || complement?.price || 0,
                    rappiPrice: storeComplement?.rappiPrice || storeComplement?.price || complement?.price || 0
                }
            })
            setComplementsRender(complementsRender);
        }
    }, [complements, storeComplementsDicionary])

    const HandleStatus = async (row) => {
        setloading(true);
        if (!row.ifoodId) {
            await api.post('routesIfood/registerComplement',
                { storeInfo, complementInfo: { name: row.name, id: row.id } })
                .catch(() => { onShowAlert(PreAlert.error('Falha ao registrar no Ifood')) })
        } else if (row.ifoodId) {
            await api.post('routesIfood/deleteComplement',
                { storeInfo, complementInfo: { ifoodId: row.ifoodId, id: row.id } })
                .catch(() => { onShowAlert(PreAlert.error('Falha ao deletar do Ifood')) })
        }
        setloading(false);
    }

    const toggleRappiStatus = (row) => {
        if (!row.storeId) {
            return new StoreComplementDB(establishment.id, store.id).define(row.id, { toRappi: !row?.toRappi })
        }
        else {
            return new StoreComplementDB(establishment.id, store.id).update(row.id, { toRappi: !row?.toRappi })
        }
    };

    const handleIfoodPrice = (id, price) => {
        const priceFormated = price.replace(/\D/g, '');
        const productIndex = complementsRender.findIndex(p => p.id === id);

        if (productIndex !== -1) {
            const updatedProducts = [...complementsRender];
            updatedProducts[productIndex] = {
                ...updatedProducts[productIndex],
                ifoodPrice: priceFormated
            };
            setComplementsRender(updatedProducts);
        }
    };

    const handleRappiPrice = (id, price) => {
        const priceFormated = price.replace(/\D/g, '');
        const productIndex = complementsRender.findIndex(p => p.id === id);

        if (productIndex !== -1) {
            const updatedProducts = [...complementsRender];
            updatedProducts[productIndex] = {
                ...updatedProducts[productIndex],
                rappiPrice: priceFormated
            };
            setComplementsRender(updatedProducts);
        }
    };
    const onError = () => {
        onShowAlert(PreAlert.error('Falha ao atualizar preço'))
    }

    const saveDeliveryStorePrices = async (row) => {
        setloading(true);
        if (!row.storeId) {
            return new StoreComplementDB(establishment.id, store.id).define(row.id, {
                rappiPrice: row.rappiPrice ?? 0,
                ifoodPrice: row.ifoodPrice ?? 0,
            })
                .then(async () => { await handleIfoodComplementPrice(row) })
                .catch(onError)
                .finally(() => setloading(false))
        } else {
            return new StoreComplementDB(establishment.id, store.id).update(row.id, {
                rappiPrice: row.rappiPrice ?? 0,
                ifoodPrice: row.ifoodPrice ?? 0,
            })
                .then(async () => { await handleIfoodComplementPrice(row) })
                .catch(onError)
                .finally(() => setloading(false))
        }
    }

    const handleIfoodComplementPrice = async (row) => {
        if (row.ifoodId) {
            await api.post('routesIfood/editComplementPrice', {
                complementId: row.ifoodId,
                newPrice: row.ifoodPrice ?? 0,
                storeInfo
            }).catch(() => { onShowAlert(PreAlert.error('Falha ao atualizar preço no Ifood')) })
        }
    }

    const columns = [
        {
            label: 'Imagem',
            field: 'image_url',
            format: row => (
                <>
                    {row.image_url ? (
                        <img src={row.image_url} alt="Imagem" width={54} height={54} />
                    ) : (
                        <Logo width={54} black />
                    )}
                </>
            )
        },
        {
            label: 'Nome',
            field: 'name',
        },
        {
            label: 'Delivery-code',
            field: 'delivery_code',
        },
        {
            label: 'Preco ifood',
            field: 'ifoodPrice',
            format: row => {
                return (
                    <FormGroup id='ifoodPrice' className=''>
                        <Input
                            className="w-50"
                            onChange={(e) => handleIfoodPrice(row.id, e.target.value)}
                            value={Money.centsToMaskMoney(row.ifoodPrice ?? 0)}
                            placeholder='Ex: 18.00'
                        />
                    </FormGroup>
                )
            }
        },
        {
            label: 'Ifood Status',
            field: 'ifoodId',
            format: row => {
                return (
                    <>
                        {!loading ?
                            <Button
                                isLink
                                color={row.ifoodId ? 'success' : 'danger'}
                                icon='Circle'
                                onClick={() => HandleStatus(row)}
                                className='text-nowrap'>
                                {row.ifoodId ? 'Ativo' : 'Inativo'}
                            </Button>
                            :
                            <Spinner />
                        }
                    </>
                )
            }
        },
        {
            label: 'preco rappi',
            field: 'priceRappi',
            format: row => {
                return (
                    <FormGroup id='priceRappi' className=''>
                        <Input
                            className="w-50"
                            onChange={(e) => handleRappiPrice(row.id, e.target.value)}
                            value={Money.centsToMaskMoney(row.rappiPrice)}
                            placeholder='Ex: 18.00'
                        />
                    </FormGroup>
                )
            }
        },
        {
            label: 'Rappi Status',
            field: 'toRappi',
            format: row => {
                return (
                    <>
                        {!loading ?
                            <Button
                                isLink
                                color={row.toRappi ? 'success' : 'danger'}
                                icon='Circle'
                                onClick={() => toggleRappiStatus(row)}
                                className='text-nowrap'>
                                {row.toRappi ? 'Ativo' : 'Inativo'}
                            </Button>
                            :
                            <Spinner />
                        }
                    </>
                )
            }
        },
        {
            label: 'Ações',
            format: row => (
                <>
                    {!loading ?
                        <>
                            <Popovers
                                trigger="hover"
                                title="Salvar Preços">
                                <Button
                                    isLink
                                    color="dark"
                                    icon='save'
                                    onClick={() => saveDeliveryStorePrices(row)}
                                />
                            </Popovers>
                        </>
                        :
                        <Spinner />
                    }
                </>
            )
        }
    ]


    return (
        <Modal
            id={'modal-edit-complements'}
            titleId={'Editar Complementos'}
            isOpen={open}
            setIsOpen={handleOpen}
            isStaticBackdrop={true}
            isScrollable={false}
            isCentered={true}
            size="xl" // 'sm' || 'lg' || 'xl' 
            isAnimation={true}
            onSubmit={() => { }}
        >
            <ModalHeader
                setIsOpen={() => handleOpen(false)}
            >

            </ModalHeader>
            <ModalBody className='p-3'>
                <TableCustom
                    columns={columns}
                    rows={complementsRender}
                    fileName={'edit-complement-delivery'}
                />
            </ModalBody>
            <ModalFooter>

            </ModalFooter>
        </Modal>
    )
}