import React, { useContext, useMemo, useState } from "react"
import { DefaultContext } from "../../../../contexts/default";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import { demoPages } from "../../../../menu";
import SubHeader, { SubHeaderLeft, SubHeaderRight } from "../../../../layout/SubHeader/SubHeader";
import Breadcrumb from "../../../../components/bootstrap/Breadcrumb";
import Page from "../../../../layout/Page/Page";
import Card, { CardBody } from "../../../../components/bootstrap/Card";
import SelectEstablishment from "../../../../components/MyCustom/SelectEstablishment";
import TableCustom from "../../../../components/MyCustom/TableCustom";
import Button from "../../../../components/bootstrap/Button";
import ModalEditStore from "../../../../components/modals/Delivery/Store/ModalEditStore";



const Lojas = () => {
    const { user, stores, establishment } = useContext(DefaultContext);

    const [modalStoreInfo, setModalStoreInfo] = useState(false)
    const [selectedStore, setSelectedStore] = useState(null)

    const handleModalStoreInfo = () => {
        setModalStoreInfo(!modalStoreInfo)
    }

    const columns = [
        {
            label: 'Nome',
            field: 'name',
        },
        {
            label: 'id Ifood',
            field: 'id_loja_ifood',
        },
        {
            label: 'id catalogo ifood',
            field: 'id_catalog',
        },
        {
            label: 'id rappi',
            field: 'id_store_rappi',
        },
        {
            label: 'Ações',
            field: 'actions',
            format: row => (
                <div className='w-50 d-flex'>
                    <Button
                        color="light"
                        icon="edit"
                        shadow="sm"
                        hoverShadow="sm"
                        size="sm"
                        onClick={() => {
                            handleModalStoreInfo();
                            setSelectedStore(row)
                        }}
                    />
                </div>
            ),
            noExport: true
        },

    ]

    return (
        <>
            <PageWrapper title={demoPages.command.subMenu.boxOffice.text}>
                <SubHeader>
                    <SubHeaderLeft>
                        <Breadcrumb
                            list={[
                                { title: 'Delivery', to: '/delivery' },
                                {
                                    title: 'Lojas',
                                    to: '/delivery/lojas',
                                },
                            ]}
                        />
                    </SubHeaderLeft>
                    <SubHeaderRight>

                    </SubHeaderRight>
                </SubHeader>
                <Page container='fluid'>
                    <Card>
                        <CardBody className='table-responsive p-5'>
                            {
                                user.role === "admin"
                                    ?
                                    <SelectEstablishment />
                                    :
                                    <></>
                            }

                            <TableCustom
                                columns={columns}
                                rows={stores}
                            />
                        </CardBody>
                    </Card>
                </Page>
            </PageWrapper>

            <ModalEditStore
                open={modalStoreInfo}
                handleOpen={handleModalStoreInfo}
                store={selectedStore}
            />
        </>
    );

}

export default Lojas