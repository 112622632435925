import { limit, orderBy, QueryConstraint, where } from "firebase/firestore"
import { useEffect, useState } from "react"
import FirestorePipe from "../database/utils/pipe"
import DataFilter from "../interface/resultContext/DataFilter"

const useDashData = (hidden: boolean, dataFilter: DataFilter, instance: FirestorePipe) => {

  const [rows, setrows] = useState<any[]>([])
  const [_dataFilter, set_dataFilter] = useState<any>(null)
  const [loading, setloading] = useState(false)

  useEffect(() => {
    if (hidden || !dataFilter.estab || _dataFilter === dataFilter) return;

    set_dataFilter(dataFilter);

    const wheres = getWhere();

    setloading(true)
    instance
      .getAll(...wheres)
      .then(setrows)
      .catch((error: any) => console.error(error))
      .finally(() => setloading(false))
  },[hidden, dataFilter])

  function getWhere(): QueryConstraint[] {
    const { store, firstDate, secondDate } = dataFilter;

    const start = new Date(firstDate);
    const end = new Date(secondDate);

    return [
      where('created_at', '>', start),
      where('created_at', '<', end),
      where('storeId', '==', store),
    ].filter(w => !!w) as any[]
  }

  return { rows, loading};
}

export default useDashData;