import { QueryConstraint, Unsubscribe } from "firebase/firestore";
import FirestorePipe from "../utils/pipe";
import EstablishmentDB from "./establishment";
import LogFiscalErrors from "../entities/logFiscalErrors.entity";

class LogFiscalErrorsDB extends FirestorePipe {

	public static readonly colName = 'LogFiscalErrors'

	constructor(idEstab: string) {
		if (!idEstab)
			throw new Error('Informe o id do estabelecimento')
		super(`${EstablishmentDB.colName}/${idEstab}/${LogFiscalErrorsDB.colName}`);
	}

	public async create(data: any): Promise<any> {

	}
	public async update(id: string, data: LogFiscalErrors): Promise<any> {

	}
	public async delete(id: string): Promise<any> {
		return this._delete(id);
	}
	public getAll(...params: QueryConstraint[]): Promise<LogFiscalErrors[]> {
		return this._getAll<LogFiscalErrors>(...params);
	}
	public get(id: string): Promise<LogFiscalErrors> {
		return this._get(id);
	}
	public on(listener: (data: LogFiscalErrors[]) => void, ...params: QueryConstraint[]): Unsubscribe {
		return this._on(listener, ...params);
	}
}

export default LogFiscalErrorsDB;