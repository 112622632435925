import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useFormik } from 'formik';
import Button from '../../bootstrap/Button';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import Label from '../../bootstrap/forms/Label';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import Spinner from '../../bootstrap/Spinner';

// Database
import TotenDB from '../../../database/wrappers/toten';
import SelectStore from '../../../components/MyCustom/SelectStore';
import SelectMenu from '../../../components/MyCustom/SelectMenu';
import { DefaultContext } from '../../../contexts/default';
import PreAlert from '../../../helpers/utils/preAlert';

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Este campo é necessário';
  }

  if (!values.active) {
    errors.active = 'Você precisa escolher o status da categoria';
  }

  if (!values.otp) {
    errors.otp = 'Este campo é necessário.';
  }

  if (!values.terminal_uuid) {
    errors.terminal_uuid = 'Este campo é necessário.';
  }

  return errors;
}

const ModalRegisterTotens = ({ open, setIsOpen, setIsClose, totenSelected }) => {

  const { establishment, storeSelected, menuSelected, setstoreSelected, setmenuSelected, onShowAlert } = useContext(DefaultContext)

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!open) return formik.resetForm();
    if (totenSelected) {
      const { name, active, terminal_uuid, terminal, otp, storeId, menuId } = totenSelected;
      setstoreSelected(storeId);
      setmenuSelected(menuId);
      formik.setValues({
        name,
        active: active ? 'ativo' : 'inativo',
        terminal_uuid,
        terminal,
        otp,
        paymentDebit: totenSelected.payment ? totenSelected.payment.debit : false,
        paymentCredit: totenSelected.payment ? totenSelected.payment.credit : false,
        paymentPix: totenSelected.payment ? totenSelected.payment.pix : false,
        paymentPixMatera: totenSelected.pixMatera ? totenSelected.payment.pixMatera : false,
        paymentCash: totenSelected.payment ? totenSelected.payment.cash : false,
        paymentMealTicket: totenSelected.payment ? totenSelected.payment.meal_ticket : false,


      });

    }
  }, [totenSelected, open]);
  const formik = useFormik({
    initialValues: {
      name: '',
      active: '',
      terminal_uuid: '',
      key: '',
      otp: '',
      paymentDebit: false,
      paymentCredit: false,
      paymentCash: false,
      paymentPix: false,
      paymentMealTicket: false,
      paymentPixMatera: false,
    },
    validate,
    onSubmit: values => {
      const { name,
        active,
        terminal_uuid,
        otp,
        paymentDebit,
        paymentCredit,
        paymentPix,
        paymentCash,
        paymentMealTicket,
        paymentPixMatera,
      } = values;

      const data = {
        name: name,
        active: active === 'ativo',
        terminal_uuid: terminal_uuid,
        terminal: totenSelected?.terminal ? totenSelected.terminal : randomNumberTerminal(),
        storeId: storeSelected,
        menuId: menuSelected,
        otp: otp,
        payment: {
          debit: paymentDebit === undefined ? false : paymentDebit,
          credit: paymentCredit === undefined ? false : paymentCredit,
          cash: paymentCash === undefined ? false : paymentCash,
          pix: paymentPix === undefined ? false : paymentPix,
          meal_ticket: paymentMealTicket === undefined ? false : paymentMealTicket,
          pixMatera: paymentPixMatera === undefined ? false : paymentPixMatera

        },
      }

      const onSuccess = () => {
        onShowAlert(PreAlert.success('Registro cadastrado com sucesso'))
        setIsClose();
      }
      const onSuccessUpdate = () => {
        onShowAlert(PreAlert.success('Registro atualizado com sucesso'))
        setIsClose();
      }
      const onError = () => {
        onShowAlert(PreAlert.error('Falhou ao cadastrar o registro'))
      }

      setIsLoading(true);
      if (totenSelected) {
        new TotenDB(establishment.id)
          .update(totenSelected.id, data)
          .then(onSuccessUpdate)
          .catch(onError)
          .finally(() => setIsLoading(false))
      } else {
        new TotenDB(establishment.id)
          .create(data)
          .then(onSuccess)
          .catch(onError)
          .finally(() => setIsLoading(false))
      }
    },
  })
  const onChangePix = useCallback((e) => {
    formik.setFieldValue(
      e.target.id,
      !formik.values[e.target.id]
    )
    formik.setFieldValue(
      e.target.id === 'paymentPix' ? 'paymentPixMatera' : 'paymentPix',
      false
    )
  }, [formik.values.paymentPix, formik.values.paymentPixMatera])


  function randomNumberTerminal() {
    let min = 10000000;
    let max = 99999999;
    return parseInt(Math.random() * (+max - +min) + +min);
  }

  return (
    <Modal
      id={'modal-register-totem'}
      titleId={'Cadastro de Totem'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
      onSubmit={formik.handleSubmit}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="register-totem">{totenSelected ? 'Configuração de Totem' : 'Cadastro de Totem'}</ModalTitle>
      </ModalHeader>
      <ModalBody className='p-5'>
        <form noValidate onSubmit={formik.handleSubmit}>
          {/* Inputs */}
          <div lassName="row pb-2 d-flex">
            {/* Nome */}
            <FormGroup id="name" label="Nome" className='col-md-12 mb-4'>
              <Input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                isValid={formik.isValid}
                isTouched={formik.touched.name}
                invalidFeedback={formik.errors.name}
                validFeedback='Assim está bom!'
                placeholder='Ex: Entrada da Loja do Jão'
              />
            </FormGroup>

            <div className='row mb-4'>
              {/* Store */}
              <SelectStore />


              {/* menu */}
              <SelectMenu />
              {/* Status */}
              <FormGroup className='col-md-4'>
                <Label>Status</Label>
                <ChecksGroup
                  isValid={formik.isValid}
                  isTouched={formik.touched.active}
                  invalidFeedback={formik.errors.active}
                >
                  <Checks
                    type="radio"
                    id="active"
                    label="Ativo"
                    name="active"
                    value="ativo"
                    onChange={formik.handleChange}
                    checked={formik.values.active}
                    isInline
                  />
                  <Checks
                    type="radio"
                    id="active2"
                    label="Inativo"
                    name="active"
                    value="inativo"
                    onChange={formik.handleChange}
                    checked={formik.values.active}
                    isInline
                  />
                </ChecksGroup>
              </FormGroup>
            </div>


            <div className='row'>
              {/* otp */}
              <FormGroup
                id='otp'
                label='OTP'
                className='col-md-6 mb-4'
              >
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.otp}
                  isValid={formik.isValid}
                  isTouched={formik.touched.otp}
                  invalidFeedback={formik.errors.otp}
                  validFeedback='Assim está bom!'
                  placeholder='Insira o OTP'
                />
              </FormGroup>

              {/* terminal_uuid */}
              <FormGroup
                id='terminal_uuid'
                label='UUID'
                className='col-md-6 mb-4'
              >
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.terminal_uuid}
                  isValid={formik.isValid}
                  isTouched={formik.touched.terminal_uuid}
                  invalidFeedback={formik.errors.terminal_uuid}
                  validFeedback='Assim está bom!'
                  placeholder='Insira o Terminal UUID'
                />
              </FormGroup>
            </div>

            <Label>Habilitar Tipo Pagamento</Label>
            <div className='col-md-6 g-4 d-flex justify-content-between  '>
              <ChecksGroup>
                <Checks
                  type="switch"
                  id="paymentPix"
                  label="PIX (SE)"
                  name="paymentPix"
                  onChange={onChangePix}
                  checked={formik.values.paymentPix}
                />
                <Checks
                  type="switch"
                  id="paymentPixMatera"
                  label="PIX (MATERA)"
                  name="paymentPixMatera"
                  onChange={onChangePix}
                  checked={formik.values.paymentPixMatera}
                />
                <Checks
                  type="switch"
                  id="paymentMealTicket"
                  label="Vale Refeição"
                  name="paymentMealTicket"
                  onChange={formik.handleChange}
                  checked={formik.values.paymentMealTicket}
                />
              </ChecksGroup>
              <ChecksGroup>
              <Checks
                  type="switch"
                  id="paymentCash"
                  label="Dinheiro"
                  name="paymentCash"
                  onChange={formik.handleChange}
                  checked={formik.values.paymentCash}
                />
                <Checks
                  type="switch"
                  id="paymentDebit"
                  label="Débito"
                  name="paymentDebit"
                  onChange={formik.handleChange}
                  checked={formik.values.paymentDebit}
                />
                <Checks
                  type="switch"
                  id="paymentCredit"
                  label="Crédito"
                  name="paymentCredit"
                  onChange={formik.handleChange}
                  checked={formik.values.paymentCredit}
                />
              </ChecksGroup>

            </div>

          </div>

          {/* Buttons */}
          <div className="row pt-4">

            <div className='d-flex justify-content-end gap-2'>
              <Button
                color="danger"
                icon="cancel"
                rounded={1}
                onClick={setIsClose}
                shadow="sm"
                hoverShadow="sm"
                size="sm"
              >
                Cancelar
              </Button>
              {isLoading ? (
                <Button
                  color="success"
                  rounded={1}
                  hoverShadow="sm"
                  shadow="sm"
                  size="sm"
                >
                  <Spinner
                    color="light"
                    inButton
                    isGrow
                    isSmall
                    size={10}
                    tag="span"
                  >
                    Carregando...
                  </Spinner>
                  Carregando...
                </Button>
              ) : (
                <Button
                  isDisable={!formik.isValid && !!formik.submitCount}
                  type='submit'
                  color='success'
                  icon="check"
                  rounded={1}
                  hoverShadow="sm"
                  shadow="sm"
                  size="sm"
                >
                  Confirmar
                </Button>
              )}
            </div>
          </div>

        </form>
      </ModalBody>
    </Modal>
  )
}

export default ModalRegisterTotens;