import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Card, { CardHeader, CardLabel, CardTitle, CardBody } from '../../../components/bootstrap/Card';

import classNames from 'classnames';
import useDarkMode from '../../../hooks/useDarkMode';
import { getFirstLetter } from '../../../helpers/helpers';
import { ResultsContext } from '../../../contexts/results';
import Money from '../../../helpers/utils/money/money';

const RankingTerminal = () => {
  const { darkModeStatus } = useDarkMode();

  const { totemsDicionary, sales, dataFilter } = useContext(ResultsContext)

  const [totemsSales, settotemsSales] = useState([])

  useEffect(() => {
    if (totemsDicionary && sales) {
      const totemsDicionaryClone = structuredClone(totemsDicionary)
      Object.keys(totemsDicionaryClone).forEach(key => {
        totemsDicionaryClone[key].total = 0
        totemsDicionaryClone[key].qtd = 0
      })
      sales.forEach(sale => {
        if (!sale.reversed && totemsDicionaryClone[sale.totemId]) {
          totemsDicionaryClone[sale.totemId].total += sale.value
          totemsDicionaryClone[sale.totemId].qtd++
        }
      })

      const totemsRank = Object.keys(totemsDicionaryClone).map(key => totemsDicionaryClone[key])

      totemsRank.sort((a, b) => {
        if (a.total > b.total) return -1;
        if (a.total < b.total) return 1;
        return 0;
      })

      settotemsSales(totemsRank)
    }
  }, [totemsDicionary, sales])

  const TopSalesItem = useCallback(({ name, total, qtd }) => {
    return (
      <div className='col-12'>
        <div className='row'>
          <div className='col d-flex align-items-center'>
            <div className='flex-shrink-0'>
              <div className='ratio ratio-1x1 me-3' style={{ width: 48 }}>
                <div
                  className={classNames(
                    'rounded-2',
                    'd-flex align-items-center justify-content-center',
                    {
                      'bg-l10-dark': !darkModeStatus,
                      'bg-l90-dark': darkModeStatus,
                    },
                  )}>
                  <span className='fw-bold'>{getFirstLetter(name)}</span>
                </div>
              </div>
            </div>
            <div className='flex-grow-1'>
              <div className='fs-6'>{name}</div>
              <div className='text-muted'>
                <small>Vendas</small>
              </div>
            </div>
          </div>
          <div className='col-auto text-end'>
            <div>
              <strong>{Money.centsToMaskMoney(total)}</strong>
            </div>
            <div className='text-muted'>
              <small>{qtd}</small>
            </div>
          </div>
        </div>
      </div>
    );
  }, [darkModeStatus]);

  const dataRender = useMemo(() => {
    if (dataFilter.store === '#') return totemsSales;
    return totemsSales.filter(item => item.storeId === dataFilter.store)
  }, [totemsSales, dataFilter])
  return (
    <Card stretch>
      <CardHeader>
        <CardLabel icon='Assessment' iconColor='light'>
          <CardTitle>Ranking por Totem</CardTitle>
        </CardLabel>
      </CardHeader>
      <CardBody isScrollable>
        <div className='row g-3'>
          {dataRender.length === 0 ? <h5>Não há Ranking de totens. Selecione corretamente os filtros!</h5> : dataRender.map((i) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <TopSalesItem key={i.id} {...i} />
          ))}
        </div>
      </CardBody>
    </Card>
  )
}

export default memo(RankingTerminal);