import React from "react";
import Button from "../../bootstrap/Button";
import Spinner from "../../bootstrap/Spinner";

export default function BtnLoad({ className }) {
  return (
    <Button
      className={className}
      color="success"
      rounded={1}
      hoverShadow="sm"
      shadow="sm"
      size="sm"
    >
      <Spinner
        color="light"
        inButton
        isGrow
        isSmall
        size={10}
        tag="span"
      >
        Carregando...
      </Spinner>
      Carregando...
    </Button>
  );
}