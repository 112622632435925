import React, { useCallback, useContext } from 'react';
import Card, { CardHeader, CardActions, CardBody, CardLabel, CardTitle } from '../../bootstrap/Card';
import Money from '../../../helpers/utils/money/money';
import CardValue from '../../MyCustom/Dashboard/CardValue';
import TableCustomPdf from '../../MyCustom/TableCustomPdf';
import Chart from '../../extras/Chart';
import VendasPorHora from '../../../pages/dashboard/Geral/VendasPorHora';
import RankingTerminal from '../../../pages/dashboard/Geral/RankingTerminal';
import RankingPorOperador from '../../../pages/dashboard/Geral/RankingPorOperador';
import TicketMedio from '../../../pages/dashboard/Geral/TicketMedio';
import QuantidadeVenda from '../../../pages/dashboard/Geral/QuantidadeVenda';
import GraficoFaturamentoMedio from '../../../pages/dashboard/Geral/GraficoFaturamentoMedio';
import { ResultsContext } from '../../../contexts/results';



const ContentPdf = ({ data, graphics, ranking, salesPerHour, ticketMedio, salesQuantity, graficoFaturamentoMedio }) => {
    const { sales, ordersIfood } = useContext(ResultsContext);

    return (
        <div className='p-2 px-3'>
            {graphics && graphics.map((item, index) => (

                <Card stretch className={index !== 0 ? "pagebreak" : ""}>
                    <CardHeader>
                        <CardLabel icon={item.icon} iconColor="dark" >
                            <CardTitle className='d-flex flex-row align-items-center  text-uppercase '>
                                <CardActions>
                                    {item.title}
                                </CardActions>
                            </CardTitle>
                        </CardLabel>
                    </CardHeader>
                    <CardBody className='row'>

                        <div className='col-sm-6 col-xxl-6 col-lg-6'>


                            <Chart
                                height={350}
                                options={{
                                    chart: {
                                        height: 350,
                                        type: 'bar'
                                    },
                                    colors: [
                                        process.env.REACT_APP_WARNING_COLOR,
                                        process.env.REACT_APP_SECONDARY_COLOR,
                                        process.env.REACT_APP_SUCCESS_COLOR,
                                        process.env.REACT_APP_INFO_COLOR,
                                        process.env.REACT_APP_DANGER_COLOR,
                                        process.env.REACT_APP_PRIMARY_COLOR,
                                    ],
                                    dataLabels: {
                                        enabled: true
                                    },
                                    fill: {
                                        opacity: 1
                                    },

                                    stroke: {
                                        colors: [
                                            'transparent'
                                        ],
                                        show: true,
                                        width: 2,
                                    },
                                    tooltip: {
                                        theme: 'dark',
                                        fixed: {
                                            enabled: false,
                                        },
                                        x: {
                                            show: true,
                                        },
                                        y: {
                                            title: {
                                                // eslint-disable-next-line no-unused-vars
                                                formatter(seriesName) {
                                                    return '';
                                                },
                                            },
                                        },
                                    },
                                    xaxis: {
                                        categories: [
                                            'Valor (R$)',
                                        ],
                                        convertedCatToNumeric: false
                                    },

                                }}

                                series={item?.data?.filter(item => item.label !== 'Total').map(item => {

                                    return {
                                        data: [Money.centsToCoin(item.value || 0)],
                                        name: item.label,
                                    }

                                })}
                                type="bar"
                            />

                        </div>
                        <div className='col-sm-6 col-xxl-6 col-lg-6'>
                            <div className='row' >
                                {item.data && item.data.map((graphic) => (
                                    <div className={graphic.className || 'col-md-6'}>
                                        <CardValue
                                            shadow='none'
                                            color={graphic.color || 'warning'}
                                            label={graphic.label}
                                            value={Money.centsToMaskMoney(graphic.value || 0)}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </CardBody>
                </Card >
            ))}
            {data && data.map((item) => (
                <TableCustomPdf
                    fontSize={item.fontSize}
                    icon={item.icon}
                    title={item.title}
                    columns={item.columns.filter((c) => !c.noExport)}
                    rows={item.data}
                    keyExtractor={row => row.id}
                />
            )
            )}
            {salesPerHour &&

                <div className='' style={{ pageBreakBefore: 'always' }}>
                    <VendasPorHora />

                </div>

            }
            {ranking && <RankingTerminal />}
            {ranking && <RankingPorOperador />}
            {ticketMedio &&
                <div className='' style={{ pageBreakBefore: 'always' }}>

                    <TicketMedio total={ticketMedio.total} count={ticketMedio.count} />
                </div>
            }
            {salesQuantity && <QuantidadeVenda count={salesQuantity.count} />}
            {graficoFaturamentoMedio && <GraficoFaturamentoMedio />}


        </div>
    );
}

export default ContentPdf;