export const STATUS_ORDER = {
  CRIADO: 'PLC',
  CONFIRMADO: 'CFM',
  DESPACHADO: 'DSP',
  PRONTOENTREGA: 'RTP',
  CONCLUIDO: 'CON',
  CANCELADO: 'CAN'
} as const

export const STATUS_ORDER_PT_BR = {
  [STATUS_ORDER.CRIADO]: 'Criado',
  [STATUS_ORDER.CONFIRMADO]: 'Confirmado',
  [STATUS_ORDER.DESPACHADO]: 'Despachado',
  [STATUS_ORDER.PRONTOENTREGA]: 'Pronto para entrega',
  [STATUS_ORDER.CONCLUIDO]: 'Concluído',
  [STATUS_ORDER.CANCELADO]: 'Cancelado'
} as const