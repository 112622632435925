import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import Button from '../../bootstrap/Button';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import Spinner from '../../bootstrap/Spinner';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Label from '../../bootstrap/forms/Label';
import Select from '../../bootstrap/forms/Select';
import Textarea from '../../bootstrap/forms/Textarea';
// Database
import { where } from 'firebase/firestore';
import { DefaultContext } from '../../../contexts/default';
import CategoryComplementDB from '../../../database/wrappers/categoryComplement';
import ComplementDB from '../../../database/wrappers/complement';
import StoreDB from '../../../database/wrappers/store';
import PreAlert from '../../../helpers/utils/preAlert';
import api from '../../../services/api';
import { SELECT_TYPE } from '../../../types/categoryType';
import ListComponents from './ListComponents';
import UserHistory from '../../../database/wrappers/userHistory';

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Este campo é necessário';
  }

  if (!values.type_select) {
    errors.type_select = 'Este campo é necessário';
  }

  if (!values.type_required) {
    errors.type_required = 'Este campo é necessário';
  }

  if (!values.max_item) {
    errors.max_item = 'Este campo é necessário';
  } else if (isNaN(Number(values.max_item)) || Number(values.max_item) < 0) {
    errors.max_item = "O valor precisa ser um número válido.";
  }


  if (!values.min_item) {
    errors.min_item = 'Este campo é necessário';
  } else if (isNaN(Number(values.min_item)) || Number(values.min_item) < 0) {
    errors.min_item = "O valor precisa ser um número válido.";
  }

  if (!values.active) {
    errors.active = 'Você precisa escolher o status do complemento';
  }

  return errors;

}

const ModalRegisterCategoryComplement = ({ open, setIsOpen, setIsClose, categoryComplementSelected }) => {

  const { establishment,store, onShowAlert, user } = useContext(DefaultContext)
  const [complementSearch, setComplementSearch] = useState('')
  const [isLoading, setIsLoading] = useState(false);
  const [complementsResponse, setComplementsResponse] = useState([]);

  useEffect(() => {
    if (!open) return formik.resetForm();
    if (categoryComplementSelected) {
      const { name, active, description, type_select, type_required, order, max_item, min_item, complements, orderDelivery } = categoryComplementSelected;

      formik.setValues({
        name,
        active: active ? 'ativo' : 'inativo',
        description,
        type_select,
        type_required,
        order,
        orderDelivery,
        min_item,
        max_item,
        complements,
      });
    }
  }, [categoryComplementSelected, open])

  // Pega os complementos
  useEffect(async () => {
    if (establishment) {
      function sort(a, b) {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      }
      const response = await new ComplementDB(establishment.id).getAll(where('active', '==', true))
      response.sort(sort);
      setComplementsResponse(response)
    }
  }, [establishment])

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      type_select: '',
      type_required: '',
      min_item: '',
      order: '',
      orderDelivery: '',
      max_item: '',
      active: '',
      complements: [],
    },
    validate,
    onSubmit: values => {
      const { name, description, type_select, type_required, order, max_item, min_item, active, complements, orderDelivery } = values;
      const data = {
        name,
        active: active === 'ativo',
        description,
        type_select: Number(type_select),
        type_required: Number(type_required),
        min_item: Number(min_item),
        max_item: Number(max_item),
        order: Number(order),
        orderDelivery: Number(orderDelivery),
        complements,
      }

      const onSuccess = () => {
        onShowAlert(PreAlert.success('Registro cadastrado com sucesso'))
        setIsClose();
      }
      const onSuccessUpdate = () => {
        onShowAlert(PreAlert.success('Registro atualizado com sucesso'))
        setIsClose();
      }
      const onError = () => {
        onShowAlert(PreAlert.error('Falhou ao cadastrar o registro'))
      }

      setIsLoading(true);
      if (categoryComplementSelected) {
        new CategoryComplementDB(establishment.id)
          .update(categoryComplementSelected.id, data)
          .then(async () => {
            updateCompCate(establishment, data.complements, categoryComplementSelected.complements);
            onSuccessUpdate();
            new UserHistory(establishment.id, user, "a Categoria de Complemento")
              .update(categoryComplementSelected.id, { data, selected: categoryComplementSelected })
              .finally(() => { })
          })
          .catch(onError)
          .finally(() => setIsLoading(false))
      } else {
        new CategoryComplementDB(establishment.id)
          .create(data)
          .then(() => {
            onSuccess();
            new UserHistory(establishment.id, user, "a Categoria de Complemento")
              .create(data)
              .finally(() => { })
          })
          .catch(onError)
          .finally(() => setIsLoading(false))
      }
    },
  })

  const updateCompCate = async (establishment, complementsAtual, complementsAntigos) => {
    const firebaseStores = await new StoreDB(establishment.id).getAll()
    const stores = []
    for (const store of firebaseStores) {
      if (store.id_loja_ifood) {
        const storeInfo = {
          merchantId: store.id_loja_ifood,
          catalogId: store.id_catalog,
          establishmentId: establishment.id,
          storeId: store.id
        }
        stores.push(storeInfo)
      }
    }

    await api.put("routesIfood/editComplementCategorie", {
      stores,
      compCate: [categoryComplementSelected.id],
      complementsAntigos,
      complementsAtual
    }
    )

  }

  return (
    <Modal
      id={'modal-register-category-complement'}
      titleId={'Cadastro de Categoria de Complemento'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={complementsResponse.length >= 5 ? true : false}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
      onSubmit={formik.handleSubmit}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="register-category-complement">{categoryComplementSelected ? 'Atualização de Cadastro de Categoria' : 'Cadastro de Categoria de Complemento'}</ModalTitle>
      </ModalHeader>
      <ModalBody className='p-5'>
        <form noValidate onSubmit={formik.handleSubmit}>
          {/* Inputs */}
          <div className="row pb-2 d-flex">
            {/* Nome */}
            <FormGroup id="name" label="Nome da Categoria" className='col-md-8 mb-4'>
              <Input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                isValid={formik.isValid}
                isTouched={formik.touched.name}
                invalidFeedback={formik.errors.name}
                validFeedback='Assim está bom!'
                placeholder='Eletrodomésticos, lâmpadas, etc...'
              />
            </FormGroup>

            <FormGroup id="order" label="Ordem de Exibição" className='col-md-4 mb-4'>
              <Input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.order}
                isValid={formik.isValid}
                isTouched={formik.touched.order}
                invalidFeedback={formik.errors.order}
                validFeedback='Assim está bom!'
                placeholder='1, 2, 3, 4 etc...'
              />
            </FormGroup>
            {/* Tipo */}
            <FormGroup id="type_select" label="Seleção por item" className='col-md-6 mb-4'>
              <Select
                placeholder='Selecione o tipo'
                list={[{ value: SELECT_TYPE.UNIQUE, text: 'Seleção Única' }, { value: SELECT_TYPE.MULTI, text: 'Seleção Múltipla' }, { value: SELECT_TYPE.REMOVAL, text: 'Remoção de Ingrediente' }]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.type_select}
                isValid={formik.isValid}
                isTouched={formik.touched.type_select}
                invalidFeedback={formik.errors.type_select}
              />
            </FormGroup>

            {/* Tipo */}
            <FormGroup id="type_required" label="Selecione a obrigatoriedade" className='col-md-6 mb-4'>
              <Select
                placeholder='Selecione a obrigatoriedade'
                list={[{ value: 0, text: 'Obrigatório' }, { value: 1, text: 'Opcional' }]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.type_required}
                isValid={formik.isValid}
                isTouched={formik.touched.type_required}
                invalidFeedback={formik.errors.type_required}
              />
            </FormGroup>

            {/* Mínimo de itens */}
            <FormGroup id="min_item" label="Mínimo de itens" className='col-md-4 mb-4'>
              <Input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.min_item}
                isValid={formik.isValid}
                isTouched={formik.touched.min_item}
                invalidFeedback={formik.errors.min_item}
                validFeedback='Insira um número inteiro'
                placeholder='Ex.: 10'
              />
            </FormGroup>

            {/* Mínimo de itens */}
            <FormGroup id="max_item" label="Máximo de itens" className='col-md-4 mb-4'>
              <Input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.max_item}
                isValid={formik.isValid}
                isTouched={formik.touched.max_item}
                invalidFeedback={formik.errors.max_item}
                validFeedback='Insira um número inteiro'
                placeholder='Ex.: 10'
              />
            </FormGroup>
            {/* Ordem Delivery */}
            <FormGroup id="orderDelivery" label="Ordem no Delivey" className='col-md-4 mb-4'>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.orderDelivery || ''}
                  isValid={formik.isValid}
                  isTouched={formik.touched.orderDelivery}
                  invalidFeedback={formik.errors.orderDelivery}
                  validFeedback='Assim está bom!'
                  placeholder='Ex: 1'
                />
              </FormGroup>
            {/* Status */}
            <FormGroup className='col-md-4 mb-4'>
              <Label>Status</Label>
              <ChecksGroup
                isValid={formik.isValid}
                isTouched={formik.touched.active}
                invalidFeedback={formik.errors.active}
              >
                <Checks
                  type="radio"
                  id="active"
                  label="Ativo"
                  name="active"
                  value="ativo"
                  onChange={formik.handleChange}
                  checked={formik.values.active}
                  isInline
                />
                <Checks
                  type="radio"
                  id="active2"
                  label="Inativo"
                  name="active"
                  value="inativo"
                  onChange={formik.handleChange}
                  checked={formik.values.active}
                  isInline
                />
              </ChecksGroup>
            </FormGroup>

            {/* Descrição */}
            <FormGroup id="description" label="Descrição" className='col-md-12 mb-4'>
              <Textarea
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
                isValid={formik.isValid}
                isTouched={formik.touched.description}
                invalidFeedback={formik.errors.description}
                validFeedback='Assim está bom!'
                placeholder='
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur illum hic omnis ipsam fugiat incidunt'
              />
            </FormGroup>
          </div>

          <FormGroup className='col-md-4'>
              <Label>Buscar complemento</Label>
              <Input
                onChange={e => setComplementSearch(e.target.value)}
                value={complementSearch}
                placeholder='Digite o nome do complemento'
              />
          </FormGroup>

          {complementsResponse.length > 0 && (
            <div className='row g-4 mt-1'>
              <h5>Complementos</h5>
            </div>
          )}

          <ListComponents
            complements={complementsResponse.filter(comp => comp.name.toLowerCase().includes(complementSearch.toLowerCase()))}
            complementsSelected={formik.values.complements}
            onChange={formik.handleChange}
            categoryComplementSelected={categoryComplementSelected}
          />

          {/* Buttons */}
          <div className="row pt-4">
            <div className='d-flex justify-content-end gap-2'>
              <Button
                color="danger"
                icon="cancel"
                rounded={1}
                onClick={setIsClose}
                shadow="sm"
                hoverShadow="sm"
                size="sm"
              >
                Cancelar
              </Button>
              {isLoading ? (
                <Button
                  color="success"
                  rounded={1}
                  hoverShadow="sm"
                  shadow="sm"
                  size="sm"
                >
                  <Spinner
                    color="light"
                    inButton
                    isGrow
                    isSmall
                    size={10}
                    tag="span"
                  >
                    Carregando...
                  </Spinner>
                  Carregando...
                </Button>
              ) : (
                <Button
                  isDisable={!formik.isValid && !!formik.submitCount}
                  type='submit'
                  color='success'
                  icon="check"
                  rounded={1}
                  hoverShadow="sm"
                  shadow="sm"
                  size="sm"
                >
                  Confirmar
                </Button>
              )}
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default ModalRegisterCategoryComplement;