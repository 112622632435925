import React, { memo, useMemo } from "react";
import classNames from "classnames";
import useDarkMode from "../../../../hooks/useDarkMode";
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from "../../../bootstrap/Card";

const CardValue = ({ color, label, value, classHeader, style }) => {
  const { darkModeStatus } = useDarkMode()

  const classNameColor = useMemo(() => {
    const classNameColorOptions = {
      dark_light: 'bg-l25-dark bg-l10-dark-hover',
      dark_dark: 'bg-lo25-light bg-lo50-light-hover',
      primary_light: 'bg-l25-primary bg-l10-primary-hover',
      primary_dark: 'bg-lo50-primary bg-lo25-primary-hover',
      secondary_light: 'bg-l25-secondary bg-l10-secondary-hover',
      secondary_dark: 'bg-lo50-secondary bg-lo25-secondary-hover',
      success_light: 'bg-l25-success bg-l10-success-hover',
      success_dark: 'bg-lo50-success bg-lo25-success-hover',
      info_light: 'bg-l25-info bg-l10-info-hover',
      info_dark: 'bg-lo50-info bg-lo25-info-hover',
      warning_light: 'bg-l25-warning bg-l10-warning-hover',
      warning_dark: 'bg-lo50-warning bg-lo25-warning-hover',
      danger_light: 'bg-l25-danger bg-l10-danger-hover',
      danger_dark: 'bg-lo50-danger bg-lo25-danger-hover',
      uget_light: '#FFCC06',
      uget_dark: '#FFCC06',

    }

    return classNameColorOptions[color + (darkModeStatus ? '_dark' : '_light')];
  }, [darkModeStatus, color])

  return (
    <Card
      style={style}
      //borderSize="0"
      className={classNames(
        'transition-base rounded-2 mb-4',
        darkModeStatus ? 'text-white' : 'text-dark',
        classNameColor,
      )}
      shadow='sm'>
      <CardHeader size="sm"
        className={classNames(
          'pt-3 px-4 pb-0 bg-transparent',
          classHeader
        )}>
        <CardLabel >
          <CardTitle tag='h4' className='h5'>
            {label}
          </CardTitle>
        </CardLabel>
      </CardHeader>
      <CardBody className='p-0'>
        <div className='d-flex align-items-center p-0'>
          <div className='flex-grow-1 ms-0'>
            <div className='fw-bold fs-3 mb-0  text-center'>
              {value}
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

export default memo(CardValue);