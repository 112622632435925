import React, { useState, useEffect, useContext, memo } from 'react';
import { useFormik } from 'formik';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Checks from '../../bootstrap/forms/Checks';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import PreAlert from '../../../helpers/utils/preAlert';
import { DefaultContext } from '../../../contexts/default';
import BtnLoad from '../../MyCustom/BtnLoad';
import BtnCancel from '../../MyCustom/BtnCancel';
import BtnConfirm from '../../MyCustom/BtnConfirm';
import CouponDB from '../../../database/wrappers/coupon';

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Este campo é necessário.';
  }
  if (!values.local) {
    errors.local = 'Este campo é necessário.';
  }

  if (!values.percentage) {
    errors.name = 'Este campo é necessário.';
  }

  if (!values.cod) {
    errors.cod = 'Este campo é necessário.';
  }

  return errors;
}

const ModalRegisterCoupon = ({ open, setIsOpen, setIsClose, couponSelected }) => {

  const { onShowAlert, establishment, store } = useContext(DefaultContext)

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!open) return formik.resetForm();
    if (couponSelected) {
      const { sellerName,name, local, cod, percentage, active } = couponSelected;

      formik.setValues({
        sellerName,
        name,
        local,
        cod,
        percentage,
        active: active,
      });
    }
  }, [couponSelected, open])

  const formik = useFormik({
    initialValues: {
      sellerName: '',
      name: '',
      local: '',
      cod: '',
      percentage: '',
      active: true,
    },
    validate,
    onSubmit: values => {
      const { sellerName, name, local, cod, percentage, active } = values;
      const data = {
        sellerName,
        name,
        local,
        percentage: Number(percentage),
        cod,
        active,
      }

      const onSuccess = () => {
        onShowAlert(PreAlert.success('Registro cadastrado com sucesso'))
        setIsLoading(false);
        setIsClose();
      }
      const onSuccessUpdate = () => {
        onShowAlert(PreAlert.success('Registro atualizado com sucesso'))
        setIsLoading(false);
        setIsClose();
      }
      const onError = () => {
        onShowAlert(PreAlert.error('Falhou ao cadastrar o registro'))
        setIsLoading(false);
      }

      setIsLoading(true);
      if (couponSelected) {
        new CouponDB(establishment.id, store.id)
          .update(couponSelected.id, data)
          .then(onSuccessUpdate)
          .catch(onError)
          .finally(() => setIsLoading(false))
      } else {
        new CouponDB(establishment.id, store.id)
          .create(data)
          .then(onSuccess)
          .catch(onError)
          .finally(() => setIsLoading(false))
      }
    },
  })

  return (
    <Modal
      id={'modal-register-ticketBoxEntrance'}
      titleId={'Cadastro e Atualização de Entradas'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
      onSubmit={formik.handleSubmit}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="register-couponSelected">{couponSelected ? 'Atualização de Cupom' : 'Cadastro de Cupom'}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <form noValidate onSubmit={formik.handleSubmit}>

          {/* Inputs */}
          <div className="row g-4 px-2">

            {/* Nome */}
            <FormGroup id="sellerName" label="Nome" className='col-md-6'>
              <Input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.sellerName}
                isValid={formik.isValid}
                isTouched={formik.touched.sellerName}
                invalidFeedback={formik.errors.sellerName}
                validFeedback='Assim está bom!'
                placeholder='Nome do vendedor'
              />
            </FormGroup>

            {/* Cupom */}
            <FormGroup id="name" label="Nome do Cupom" className='col-md-6'>
              <Input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                isValid={formik.isValid}
                isTouched={formik.touched.name}
                invalidFeedback={formik.errors.name}
                validFeedback='Assim está bom!'
                placeholder='Nome do cupom'
              />
            </FormGroup>

            {/* Local */}
            <FormGroup id="local" label="Local" className='col-md-6'>
              <Input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.local}
                isValid={formik.isValid}
                isTouched={formik.touched.local}
                invalidFeedback={formik.errors.local}
                validFeedback='Assim está bom!'
                placeholder='Local'
              />
            </FormGroup>


            {/* Codigo */}
            <FormGroup id="cod" label="Código" className='col-md-6'>
              <Input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.cod}
                isValid={formik.isValid}
                isTouched={formik.touched.cod}
                invalidFeedback={formik.errors.cod}
                validFeedback='Assim está bom!'
                placeholder='Codigo do cupom'
              />
            </FormGroup>

            {/* % */}
            <FormGroup id="percentage" label="Porcentagem (%)" className='col-md-6'>
              <Input
                type='number'
                ariaDescribedby='inputGroupPrepend'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.percentage}
                isValid={formik.isValid}
                isTouched={formik.touched.percentage}
                invalidFeedback={formik.errors.percentage}
                validFeedback='Assim está bom!'
                placeholder="Ex: 10%"
              />
            </FormGroup>

            {/* Status */}
            <FormGroup
              id='active'
              label='Status'
              className='col-md-7'
            >
              <Checks
                type="switch"
                id="active"
                label={formik.values.active ? 'Ativo' : 'Inativo'}
                name="active"
                onChange={formik.handleChange}
                checked={formik.values.active}
              />
            </FormGroup>

          </div>
          {/* Buttons */}
          <div className="row pt-4">
            <div className="col-md-5 offset-md-7">
              <div className='d-flex justify-content-evenly'>
                <BtnCancel setIsClose={setIsClose} />
                {isLoading ? (
                  <BtnLoad />
                ) : (
                  <BtnConfirm isDisable={!formik.isValid} />
                )}
              </div>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default memo(ModalRegisterCoupon);