import { QueryConstraint, Unsubscribe } from "firebase/firestore";
import ClouserCashier from "../entities/cashier/clouserCashier.entity";
import FirestorePipe from "../utils/pipe";
import EstablishmentDB from "./establishment";
import StoreDB from "./store";

class ClouserCashierDB extends FirestorePipe {

  public static readonly colName = 'ClouserCashiers'

  constructor(idEstab: string, idStore: string) {
    if(!idEstab && !idStore)
      throw new Error('Informe o id do estabelecimento e loja')
    super(`${EstablishmentDB.colName}/${idEstab}/${StoreDB.colName}/${idStore}/${ClouserCashierDB.colName}`);
  }

  public define() {
    throw Error('Função não existe')
  }
  public create(): Promise<any> {
    throw Error('Função não existe')
  }
  public update(): Promise<any> {
    throw Error('Função não existe')
  }
  public delete(): Promise<any> {
    throw Error('Função não existe')
  }
  public getAll(...params: QueryConstraint[]): Promise<ClouserCashier[]> {
    return this._getAll<ClouserCashier>(...params);
  }
  public get(id: string): Promise<ClouserCashier> {
    return this._get(id);
  }
  public on(listener: (data: ClouserCashier[]) => void, ...params: QueryConstraint[]): Unsubscribe {
    return this._on(listener, ...params);
  }

}

export default ClouserCashierDB;