import Breadcrumb from "../../../../components/bootstrap/Breadcrumb"
import Button from "../../../../components/bootstrap/Button"
import Card, { CardBody } from "../../../../components/bootstrap/Card"
import logo from '../../../../assets/img/logo_uget_black.png'
import Page from "../../../../layout/Page/Page"
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper"
import ProductDB from "../../../../database/wrappers/product"
import SelectEstablishment from "../../../../components/MyCustom/SelectEstablishment"
import SelectStore from "../../../../components/MyCustom/SelectStore"
import StoreProductDB from "../../../../database/wrappers/storeProduct"
import SubHeader, { SubHeaderLeft, SubHeaderRight } from "../../../../layout/SubHeader/SubHeader"
import TableCustom from "../../../../components/MyCustom/TableCustom"
import { ACCESS_LEVEL } from "../../../../types/roles"
import { DefaultContext } from "../../../../contexts/default"
import { demoPages } from "../../../../menu"
import { orderBy } from "firebase/firestore"
import { useContext, useEffect, useState } from "react"
import PreAlert from "../../../../helpers/utils/preAlert"
import ModalCategories from "../../../../components/modals/Delivery/Categories"
import ModalProducts from "../../../../components/modals/Delivery/Products"
import CategorieComplements from '../../../../components/modals/Delivery/CategorieComplements'
import { Complements } from "../../../../components/modals/Delivery/Complements"
import api from "../../../../services/api"
import Spinner from "../../../../components/bootstrap/Spinner"
import { EditModalComplement } from "../../../../components/modals/Delivery/Complements/EditModalComplements"
import Popovers from "../../../../components/bootstrap/Popovers"
import FormGroup from "../../../../components/bootstrap/forms/FormGroup"
import Input from "../../../../components/bootstrap/forms/Input"
import Money from "../../../../helpers/utils/money/money"
import { ResultsContext } from "../../../../contexts/results"

const ManagementDelivery = () => {
    const { store, accessLevel, onShowAlert, estabSelected, storeSelected, establishment } = useContext(DefaultContext);
    const {storeInfo} = useContext(ResultsContext)
    const [inDeliveryRender, setInDeliveryRender] = useState([])
    const [products, setProducts] = useState([]);
    const [storeProductsDicionary, setStoreProductsDicionary] = useState({});

    const [modalCategories, setModalCategories] = useState(false)
    const [modalProducts, setModalProducts] = useState(false)
    const [modalCategorieComplements, setModalCategorieComplements] = useState(false)
    const [modalComplements, setModalComplements] = useState(false)
    const [categorySelected, setCategorySelected] = useState(null);
    const [productSelected, setProductSelected] = useState(null)
    const [categoryComplementSelected, setCategoryComplementSelected] = useState(null)
    const [loading, setloading] = useState(false);
    const [modalEditComplement, setModalEditComplement] = useState(false)

    useEffect(() => {
        console.log(storeInfo);
    },[storeInfo])
    const handleSubmit = () => {
        if ((store?.id_catalog && store?.id_loja_ifood) || (store?.id_store_rappi)) {
            return setModalCategories(true)
        }
        return onShowAlert(PreAlert.error('Cadastre dados do Delivery'))
    }

    const handleModalEditComplement = (row) => {
        if ((store?.id_catalog && store?.id_loja_ifood) || (store?.id_store_rappi)) {
            setProductSelected(row)
            return setModalEditComplement(true)
        }
        return onShowAlert(PreAlert.error('Cadastre dados do Delivery'))
    }

    useEffect(() => {
        if (estabSelected)
            new ProductDB(estabSelected)
                .getAll(orderBy('name', 'asc'))
                .then((data) => {
                    setProducts(data);
                })
    }, [estabSelected])

    useEffect(() => {
        if (!estabSelected || !storeSelected) return;
        const onSubscribe = new StoreProductDB(estabSelected, storeSelected).on(datas => {
            const dicionary = {};
            datas.forEach(data => dicionary[data.id] = data)
            setStoreProductsDicionary(dicionary)
        });
        return onSubscribe;
    }, [estabSelected, storeSelected])

    useEffect(() => {
        if (products !== null && storeProductsDicionary !== null) {
            const productsRender = products.map(product => {
                const storeProd = storeProductsDicionary[product.id];
                return {
                    id: product.id,
                    name: product.name,
                    delivery_code: product.delivery_code,
                    complements: product.complements,
                    statusIfood: storeProd?.statusIfood,
                    image_url: product.image_url,
                    rappiStatus: storeProd?.rappiStatus,
                    toRappi: storeProd?.toRappi,
                    storeId: storeProd?.id,

                    ifoodId: storeProd?.ifoodId,
                    ifoodItemId: storeProd?.ifoodItemId,
                    ifoodPrice: storeProd?.ifoodPrice || storeProd?.price || product?.price || 0,
                    rappiPrice: storeProd?.rappiPrice || storeProd?.price || product?.price || 0
                }
            })
            const productsWithStatus = productsRender.filter(product => {
                if (product.rappiStatus || product.statusIfood) {
                    return product
                }
                return null
            })
            setInDeliveryRender(productsWithStatus);
        }
    }, [products, storeProductsDicionary])

    const HandleStatus = async (row) => {
        setloading(true);
        if (row.statusIfood) {
            await api.post('routesIfood/desactiveItem',
                { storeInfo, productInfo: { id: row.id, ifoodId: row.ifoodId, status: true } })
                .catch(() => { onShowAlert(PreAlert.error('Falha ao desativar produto no Ifood')) })
        }
        setloading(false)
    }

    const onError = () => {
        onShowAlert(PreAlert.error('Falha ao atualizar preço'))
    }

    const saveDeliveryStorePrices = async (row) => {
        setloading(true);
        if (!row.storeId) {
            return new StoreProductDB(establishment.id, store.id).define(row.id, {
                rappiPrice: row.rappiPrice,
                ifoodPrice: row.ifoodPrice,
            })
                .then(async () => { await handleIfoodItemPrice(row) })
                .catch(onError)
                .finally(() => setloading(false))
        } else {
            return new StoreProductDB(establishment.id, store.id).update(row.id, {
                rappiPrice: row.rappiPrice,
                ifoodPrice: row.ifoodPrice,
            })
                .then(async () => { await handleIfoodItemPrice(row) })
                .catch(onError)
                .finally(() => setloading(false))
        }
    }

    const handleIfoodItemPrice = async (row) => {
        if (row?.ifoodId) {
            await api.post('routesIfood/editItemPrice', {
                itemId: row.ifoodItemId,
                newPrice: row.ifoodPrice,
                storeInfo
            }).catch(() => { onShowAlert(PreAlert.error('Falha ao atualizar preço no Ifood')) })
        }
    }

    const handleIfoodPrice = (id, price) => {
        const priceFormated = price.replace(/\D/g, '');
        const productIndex = inDeliveryRender.findIndex(p => p.id === id);

        if (productIndex !== -1) {
            const updatedProducts = [...inDeliveryRender];
            updatedProducts[productIndex] = {
                ...updatedProducts[productIndex],
                ifoodPrice: priceFormated
            };
            setInDeliveryRender(updatedProducts);
        }
    };

    const columns = [
        {
            label: 'Imagem',
            field: 'image_url',
            format: row => (
                <>
                    {row.image_url ? (
                        <img src={row.image_url} alt="Imagem" width={54} height={54} />
                    ) : (
                        <img src={logo} alt="Imagem" width={60} height='auto' />
                    )}
                </>
            )
        },
        {
            label: 'Nome',
            field: 'name',
        },
        {
            label: 'Delivery-code',
            field: 'delivery_code',
        },
        {
            label: 'Preco ifood',
            field: 'ifoodPrice',
            format: row => {
                return (
                    <FormGroup id='ifoodPrice' className=''>
                        <Input
                            className="w-50"
                            onChange={(e) => handleIfoodPrice(row.id, e.target.value)}
                            value={Money.centsToMaskMoney(row.ifoodPrice)}
                            placeholder='Ex: 18.00'
                        />
                    </FormGroup>
                )
            }
        },
        {
            label: 'Item Ifood Status',
            field: 'ifoodId',
            format: row => {
                return (
                    <>
                        {!loading ?
                            <Button
                                isLink
                                color={row.statusIfood ? 'success' : 'danger'}
                                icon='Circle'
                                onClick={() => HandleStatus(row)}
                                className='text-nowrap'>
                                {row.statusIfood ? 'Ativo' : 'Inativo'}
                            </Button>
                            :
                            <Spinner />
                        }
                    </>
                )
            }
        },
        {
            label: 'Item Rappi Status',
            field: 'rappiStatus',
            format: row => {
                return (
                    <>
                        {!loading ?
                            <Button
                                isLink
                                color={row.rappiStatus ? 'success' : 'danger'}
                                icon='Circle'
                                onClick={() => { }}
                                className='text-nowrap'>
                                {row.rappiStatus ? 'Ativo' : 'Inativo'}
                            </Button>
                            :
                            <Spinner />
                        }
                    </>
                )
            }
        },
        {
            label: 'Editar',
            field: 'editar',
            format: row => {
                return (
                    <>
                        {!loading ?
                            <>
                                <Popovers
                                    trigger="hover"
                                    title="Salvar Preços">
                                    <Button
                                        color='primary'
                                        isLink
                                        icon='save'
                                        shadow="sm"
                                        hoverShadow="sm"
                                        onClick={() => saveDeliveryStorePrices(row)}
                                    />
                                </Popovers>
                            </>
                            :
                            <Spinner />
                        }
                        {!loading ?
                            <Popovers
                                trigger="hover"
                                title="Editar Complementos">
                                <Button
                                    color='primary'
                                    isLink
                                    icon='pen'
                                    shadow="sm"
                                    hoverShadow="sm"
                                    onClick={() => handleModalEditComplement(row)}
                                />
                            </Popovers>
                            :
                            <Spinner />
                        }
                    </>
                )
            }
        },



    ]

    return (
        <>
            <PageWrapper title={demoPages.delivery.subMenu.gerenciamento.text}>
                <SubHeader>
                    <SubHeaderLeft>
                        <Breadcrumb
                            list={[
                                { title: 'Delivery', to: '/delivery' },
                                {
                                    title: 'Categorias de Gerenciamentos',
                                    to: '/delivery/Gerenciamentos',
                                },
                            ]}
                        />
                    </SubHeaderLeft>
                    <SubHeaderRight>
                        <Button
                            color='primary'
                            icon='plus'
                            shadow="sm"
                            hoverShadow="sm"
                            onClick={() => handleSubmit()}
                        >
                            Cadastrar Item
                        </Button>
                    </SubHeaderRight>
                </SubHeader>
                <Page container='fluid'>
                    <Card>
                        <CardBody className='table-responsive p-5 row'>
                            {accessLevel === ACCESS_LEVEL.ADMIN &&
                                <SelectEstablishment />
                            }
                            {accessLevel === ACCESS_LEVEL.ADMIN &&
                                <SelectStore />
                            }
                            <TableCustom
                                columns={columns}
                                rows={inDeliveryRender}
                                fileName={'Categoria-de-Gerenciamento'}
                            />
                        </CardBody>
                    </Card>
                </Page>
            </PageWrapper>

            <ModalCategories
                open={modalCategories}
                handleOpen={setModalCategories}
                handleNext={setModalProducts}
                setCategorySelected={setCategorySelected}
            />

            <ModalProducts
                open={modalProducts}
                handleOpen={setModalProducts}
                handleNext={setModalCategorieComplements}
                handleBack={() => {
                    setCategorySelected(null);
                    setModalProducts(false);
                    setModalCategories(true);
                }}
                categorySelected={categorySelected}
                setProductSelected={setProductSelected}
            />

            <CategorieComplements
                open={modalCategorieComplements}
                handleOpen={setModalCategorieComplements}
                handleNext={setModalComplements}
                handleBack={() => {
                    setModalCategorieComplements(false);
                    setModalProducts(true);
                }}
                productSelected={productSelected}
                setCategoryComplementSelected={setCategoryComplementSelected}
            />

            <Complements
                open={modalComplements}
                handleOpen={setModalComplements}
                handleBack={() => {
                    setModalComplements(false);
                    setModalCategorieComplements(true);
                }}
                categoryComplementSelected={categoryComplementSelected}
            />

            <EditModalComplement
                open={modalEditComplement}
                handleOpen={setModalEditComplement}
                productSelected={productSelected}
            />

            {/* <ListCategoryItemsDeliveryModal
                open={viewProducts}
                onClose={closeViewProducts}
                category={categorySelected}
            />  */}
        </>
    )
}

export default ManagementDelivery