import { QueryConstraint, Unsubscribe } from "firebase/firestore";
import FirestorePipe from "../utils/pipe";

class AppVersionDB extends FirestorePipe {

  public static readonly colName = 'AppVersions'

  constructor() {
    super(`${AppVersionDB.colName}`);
  }

  public define() {
    throw Error('Função não existe')
  }
  public create(): Promise<any> {
    throw Error('Função não existe')
  }
  public update(): Promise<any> {
    throw Error('Função não existe')
  }
  public delete(): Promise<any> {
    throw Error('Função não existe')
  }
  public getAll(...params: QueryConstraint[]): Promise<any[]> {
    return this._getAll<any>(...params);
  }
  public get(id: string): Promise<any> {
    return this._get(id);
  }
  public on(listener: (data: any[]) => void, ...params: QueryConstraint[]): Unsubscribe {
    return this._on(listener, ...params);
  }
  public onDicionary(listener: (data: any) => void, ...params: QueryConstraint[]): Unsubscribe {
    return this._onDicionary(listener, ...params);
  }

}

export default AppVersionDB;