import { QueryConstraint, Unsubscribe } from "firebase/firestore";
import Setting from "../entities/setting.entity";
import FirestorePipe from "../utils/pipe";
import EstablishmentDB from "./establishment";

class SettingDB extends FirestorePipe {

  public static readonly colName = 'Settings'

  constructor(idEstab: string) {
    if(!idEstab)
      throw new Error('Informe o id do estabelecimento')
    super(`${EstablishmentDB.colName}/${idEstab}/${SettingDB.colName}`);
  }

  public async create(data: Setting): Promise<any> {
    await this.saveFile(data, 'Home');
    await this.saveFile(data, 'Banner');
    try {
      return await this._create(data);
    } catch (error: any) {
      if(data.imageHome_ref) 
        await this.deleteFile(data.imageHome_ref)
      if(data.imageBanner_ref) 
        await this.deleteFile(data.imageBanner_ref)
      throw error.message
    }
  }
  public async update(id: string, data: Setting): Promise<any> {
    await this.saveFile(data, 'Home');
    await this.saveFile(data, 'Banner');
    return this._update(id, data);
  }
  public delete(id: string): Promise<any> {
    return this._delete(id);
  }
  public getAll(...params: QueryConstraint[]): Promise<Setting[]> {
    return this._getAll<Setting>(...params);
  }
  public get(id: string): Promise<Setting> {
    return this._get(id);
  }
  public on(listener: (data: Setting[]) => void, ...params: QueryConstraint[]): Unsubscribe {
    return this._on(listener, ...params);
  }

  private async saveFile(data: any, label: string) {
    if(data['image'+label]) {
      const resultUpload = await this.uploadFile(new Date().getTime().toString(), data['image'+label]);
      data['image'+label+'_ref'] = resultUpload.ref.fullPath;
      data['image'+label+'_url'] = resultUpload.url;
    }
    delete data['image'+label]
  }

}

export default SettingDB;