import { useFormik } from 'formik';
import Papa from 'papaparse';
import React, { memo, useCallback, useContext, useEffect, useState } from 'react';
import { DefaultContext } from '../../../contexts/default';
import PreAlert from '../../../helpers/utils/preAlert';
import api from '../../../services/api';
import BtnCancel from '../../MyCustom/BtnCancel';
import BtnConfirm from '../../MyCustom/BtnConfirm';
import BtnLoad from '../../MyCustom/BtnLoad';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';

const validate = (values) => {
  const errors = {};

  if (!values.file) {
    errors.file = 'Este campo é necessário';
  }

  return errors;
}

const ModalRegisterImport = ({ open, setIsOpen, setIsClose }) => {

  const { onShowAlert, establishment, event } = useContext(DefaultContext)

  const [isLoading, setIsLoading] = useState(false);
  const [originalFile, setoriginalFile] = useState(null);

  useEffect(() => {
    if (!open) return formik.resetForm();
  }, [open])

  const validateList = useCallback((rows) => {
    rows.forEach((row, index) => {
      if (row.name === null)
        throw new Error(`Linha ${index + 1}. Campo nome não é válido`)
      if (row.cpf === null)
        throw new Error(`Linha ${index + 1}. Campo cpf não é válido`)
      if (row.birthDate === null)
        throw new Error(`Linha ${index + 1}. Campo data de nascimento não é válido`)
      if (row.email === null)
        throw new Error(`Linha ${index + 1}. Campo email não é válido`)
      if (row.phone === null)
        throw new Error(`Linha ${index + 1}. Campo telefone não é válido`)
      if (typeof row.balance !== 'number' || isNaN(row.balance))
        throw new Error(`Linha ${index + 1}. Campo saldo não é um número válido`)
    })
  }, [])

  function readCSV(csv, saveData) {
    const results = Papa.parse(csv, { header: true }) // object with { data, errors, meta }
    const rows = results.data.filter(row => row.hasOwnProperty('CPF') && row['CPF'] !== '') // array of objects


    const datas = rows.map(row => ({
      name: row['Nome'] === undefined ? null : row['Nome'],
      cpf: row['CPF'] === undefined ? null : row['CPF'].toString().replace(/\D/g, ""),
      birthDate: row['Data Nasc.'] === undefined ? null : row['Data Nasc.'],
      email: row['Email'] === undefined ? null : row['Email'],
      phone: row['Telefone'] === undefined ? null : row['Telefone'].toString().replace(/\D/g, ""),
      nfc: row['NFC'] === undefined ? null : row['NFC'],
      balance: row['Saldo'] === undefined ? null : Math.round(Number(row['Saldo'] * 100)),

    }))

    try {
      validateList(datas)
    } catch (error) {
      onShowAlert(PreAlert.error(error.message))
      setIsLoading(false)
      return;
    }

    saveData(datas)
  }

  function sendFile(file, saveData) {
    const reader = new FileReader();
    reader.onload = function (e) {
      // Use reader.result
      readCSV(reader.result, saveData)
    }
    reader.readAsText(file);
  }

  const handleFiles = e => {
    const [file] = Array.from(e.target.files)
    setoriginalFile(file)
    formik.handleChange(e)
  }

  const formik = useFormik({
    initialValues: {
      file: '',
    },
    validate,
    onSubmit: values => {
      setIsLoading(true);
      const onSuccess = () => {
        onShowAlert(PreAlert.success('Lista importada com sucesso'))
        setIsClose();
      }
      const onError = (error) => {
        console.error(error);
        onShowAlert(PreAlert.error('Falhou ao importar a lista'))
      }

      try {
        sendFile(originalFile, datas => {
          const dataCustomer = {
            estabId: establishment.id,
            clients: datas
          }
          api.post('routes/clients', dataCustomer)
            .then(onSuccess)
            .catch(onError)
            .finally(() => setIsLoading(false))

        });
      } catch (error) {
        console.error("Erro ao enviar o arquivo:", error);
        onError();
        setIsLoading(false);
      }

    },
  })


  return (
    <Modal
      id={'modal-register-all'}
      titleId={'Cadastro clientes'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
      onSubmit={formik.handleSubmit}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="register-clients">Importar clientes</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <form noValidate onSubmit={formik.handleSubmit}>
          {/* Inputs */}
          <div className="row g-4">

            <FormGroup id="file" className='col-md-6'>
              <Input
                type='file'
                accept={'.csv'}
                onChange={handleFiles}
                onBlur={formik.handleBlur}
                value={formik.values.file}
                isValid={formik.isValid}
                isTouched={formik.touched.file}
                invalidFeedback={formik.errors.file}
                validFeedback='Assim está bom!'
                placeholder='Entrada de Bilheteria X'
              />
            </FormGroup>
          </div>
          {/* Buttons */}
          <div className="row pt-4">
            <div className="col-md-4 offset-md-8">
              <div className='d-flex justify-content-evenly'>
                <BtnCancel setIsClose={setIsClose} />
                {isLoading ? (
                  <BtnLoad />
                ) : (
                  <BtnConfirm isDisable={!formik.isValid} />
                )}
              </div>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default memo(ModalRegisterImport);