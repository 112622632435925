import masks from "../../helpers/utils/masks";

export function inputOnlyNumbers(formik: any, e: any) {
  if(isNaN(Number(e.target.value)))
    return;
  formik.setFieldValue(e.target.id, e.target.value)
}

export function inputOnlyNumbersNoStitch(formik: any, e: any) {
  formik.setFieldValue(e.target.id, e.target.value.replace(/\D/g, ""))
}

export function inputMoneyMask(formik: any, e: any) {
  formik.setFieldValue(e.target.id, masks.money(e.target.value))
}