import { QueryConstraint, Unsubscribe } from "firebase/firestore";
import SubCategory from "../entities/pdv/subCategory.entity";
import FirestorePipe from "../utils/pipe";
import EstablishmentDB from "./establishment";

class SubCategoryDB extends FirestorePipe {

  public static readonly colName = 'SubCategories'

  constructor(idEstab: string) {
    if (!idEstab)
      throw new Error('Informe o id do estabelecimento')
    super(`${EstablishmentDB.colName}/${idEstab}/${SubCategoryDB.colName}`);
  }

  public async create(data: SubCategory): Promise<any> {
    await this.saveFile(data);
    try {
      return await this._create(data);
    } catch (error: any) {
      if(data.image_ref) {
        await this.deleteFile(data.image_ref)
      }
      throw error.message
    }
  }
  public async update(id: string, data: SubCategory): Promise<any> {
    await this.saveFile(data);
    return this._update(id, data);
  }
  public delete(id: string): Promise<any> {
    return this._delete(id);
  }
  public getAll(...params: QueryConstraint[]): Promise<SubCategory[]> {
    return this._getAll<SubCategory>(...params);
  }
  public get(id: string): Promise<SubCategory> {
    return this._get(id);
  }
  public on(listener: (data: SubCategory[]) => void, ...params: QueryConstraint[]): Unsubscribe {
    return this._on(listener, ...params);
  }

  private async saveFile(data: SubCategory) {
    if(data.image) {
      const resultUpload = await this.uploadFile(new Date().getTime().toString(), data.image);
      data.image_ref = resultUpload.ref.fullPath;
      data.image_url = resultUpload.url;
    }
    delete data.image
  }

}

export default SubCategoryDB;