import axios from 'axios';
import { getIdToken } from 'firebase/auth'

let user = null;

const api = axios.create({
    baseURL: 'https://us-central1-ugetpdx-prod.cloudfunctions.net/'
});

api.interceptors.request.use(async config => {
    if (user) {
        const token = await getIdToken(user);
        if (token) {
            config.headers.Authorization = token;
        }
    }
    return config;
});

export function defineApiUser(newUser) {
    user = newUser;
}

export default api;