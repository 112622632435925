import { QueryConstraint, Unsubscribe } from "firebase/firestore";
import Store from "../entities/store.entity";
import FirestorePipe from "../utils/pipe";
import EstablishmentDB from "./establishment";

class StoreDB extends FirestorePipe {

  public static readonly colName = 'Stores'

  constructor(idEstab: string) {
    if(!idEstab)
      throw new Error('Informe o id do estabelecimento')
    super(`${EstablishmentDB.colName}/${idEstab}/${StoreDB.colName}`);
  }

  public async create(data: Store): Promise<any> {
    await this.saveFile(data, 'image');
    await this.saveFile(data, 'certified_digital');
    try {
      return await this._create(data);
    } catch (error: any) {
      if(data.image_ref) 
        await this.deleteFile(data.image_ref)
      if(data.certified_digital_ref) 
        await this.deleteFile(data.certified_digital_ref)
      throw error.message
    }
  }
  public async update(id: string, data: Store): Promise<any> {
    await this.saveFile(data, 'image');
    await this.saveFile(data, 'certified_digital');
    return this._update(id, data);
  }
  public delete(id: string): Promise<any> {
    return this._delete(id);
  }
  public getAll(...params: QueryConstraint[]): Promise<Store[]> {
    return this._getAll<Store>(...params);
  }
  public get(id: string): Promise<Store> {
    return this._get(id);
  }
  public on(listener: (data: Store[]) => void, ...params: QueryConstraint[]): Unsubscribe {
    return this._on(listener, ...params);
  }

  private async saveFile(data: any, label: string) {
    if(data[label]) {
      const resultUpload = await this.uploadFile(new Date().getTime().toString(), data[label]);
      data[label+'_ref'] = resultUpload.ref.fullPath;
      data[label+'_url'] = resultUpload.url;
    }
    delete data[label]
  }

}

export default StoreDB;