import Button from "../../../bootstrap/Button"
import Modal, { ModalBody, ModalHeader, ModalTitle, ModalFooter } from "../../../bootstrap/Modal"
import TableCustom from "../../../MyCustom/TableCustom"
import { useContext, useEffect, useState } from "react";
import { orderBy } from "firebase/firestore";
import { DefaultContext } from "../../../../contexts/default";
import StoreCategoriesComplementDB from "../../../../database/wrappers/storeCategoryComplement";
import CategoryComplementDB from "../../../../database/wrappers/categoryComplement";
import api from "../../../../services/api";
import Spinner from "../../../bootstrap/Spinner";
import Popovers from "../../../bootstrap/Popovers";
import PreAlert from "../../../../helpers/utils/preAlert";
import { ResultsContext } from "../../../../contexts/results";

const CategorieComplements = ({ open, handleOpen, handleNext, handleBack, productSelected, setCategoryComplementSelected }) => {
    const { establishment, store, onShowAlert } = useContext(DefaultContext);
    const {storeInfo} = useContext(ResultsContext)

    const [categoriesComplements, setCategoriesComplements] = useState([])
    const [storeCategoriesComplementsDicionary, setStoreCategoriesComplementsDicionary] = useState([])
    const [categoriesComplementsRender, setCategoriesComplementsRender] = useState([])
    const [loading, setloading] = useState(false);

    useEffect(() => {
        if (establishment && productSelected) {
            new CategoryComplementDB(establishment.id)
                .getAll(orderBy('name', 'asc'))
                .then((data) => {
                    setCategoriesComplements(data.filter((category) => productSelected?.complements?.includes(category.id)))
                })
        }
    }, [establishment, productSelected])

    useEffect(() => {
        if (!establishment || !store) return;
        const onSubscribe = new StoreCategoriesComplementDB(establishment.id, store.id).on(datas => {
            const dicionary = {};
            datas.forEach(data => dicionary[data.id] = data)
            setStoreCategoriesComplementsDicionary(dicionary)
        });
        return onSubscribe;
    }, [establishment, store])

    useEffect(() => {
        if (categoriesComplements !== null && storeCategoriesComplementsDicionary !== null) {
            const categoriesComplementsRender = categoriesComplements.map(categorie => {
                const storeCategorie = storeCategoriesComplementsDicionary[categorie.id];
                return {
                    id: categorie.id,
                    name: categorie.name,
                    delivery_code: categorie.delivery_code,
                    ifoodId: storeCategorie?.ifoodId,
                    toRappi: storeCategorie?.toRappi,
                    min: categorie.min_item,
                    max: categorie.max_item,
                    complements: categorie.complements,
                    storeId: storeCategorie?.id,
                }
            })
            setCategoriesComplementsRender(categoriesComplementsRender);
        }
    }, [categoriesComplements, storeCategoriesComplementsDicionary])

    const HandleStatus = async (row) => {
        setloading(true);
        if (!row.ifoodId) {
            await api.post('routesIfood/registerComplementCategory',
                { storeInfo, categorieInfo: { name: row.name, id: row.id } })
                .catch(() => { onShowAlert(PreAlert.error('Falha ao registrar no Ifood')) })
        } else if (row.ifoodId) {
            await api.post('routesIfood/deleteComplementCategory',
                { storeInfo, categorieInfo: { ifoodId: row.ifoodId, id: row.id } })
                .catch(() => { onShowAlert(PreAlert.error('Falha ao deletar do Ifood')) })
        }
        setloading(false)
    }

    const toggleRappiStatus = (row) => {
        if (!row.storeId) {
            return new StoreCategoriesComplementDB(establishment.id, store.id).define(row.id, { toRappi: !row?.toRappi })
        }
        else {
            return new StoreCategoriesComplementDB(establishment.id, store.id).update(row.id, { toRappi: !row?.toRappi })
        }
    };



    const columns = [
        {
            label: 'Nome',
            field: 'name',
        },
        {
            label: 'Min',
            field: 'min',
        },
        {
            label: 'Max',
            field: 'max',
        },
        {
            label: 'Delivery-code',
            field: 'delivery_code',
        },
        {
            label: 'Ifood Status',
            field: 'ifoodId',
            format: row => {
                return (
                    <>
                        {!loading ?
                            <Button
                                isLink
                                color={row.ifoodId ? 'success' : 'danger'}
                                icon='Circle'
                                onClick={() => HandleStatus(row)}
                                className='text-nowrap'>
                                {row.ifoodId ? 'Ativo' : 'Inativo'}
                            </Button>
                            :
                            <Spinner />
                        }
                    </>
                )
            }
        },
        {
            label: 'Rappi Status',
            field: 'toRappi',
            format: row => {
                return (
                    <>
                        {!loading ?
                            <Button
                                isLink
                                color={row.toRappi ? 'success' : 'danger'}
                                icon='Circle'
                                onClick={() => toggleRappiStatus(row)}
                                className='text-nowrap'>
                                {row.toRappi ? 'Ativo' : 'Inativo'}
                            </Button>
                            :
                            <Spinner />
                        }
                    </>
                )
            }
        },
        {
            label: 'Ações',
            format: row => (
                <>
                    <Popovers
                        trigger="hover"
                        title="Registrar Produtos"
                    >
                        <Button
                            isLink
                            color="dark"
                            icon='Pen'
                            onClick={() => {
                                handleOpen(false)
                                handleNext(true)
                                setCategoryComplementSelected(row)
                            }}
                        >
                        </Button>
                    </Popovers>
                </>
            )
        }
    ]

    return (
        <>
            <Modal
                id={'modal-categories-complements'}
                titleId={'Cadastro Categories Complements'}
                isOpen={open}
                setIsOpen={handleOpen}
                isStaticBackdrop={true}
                isScrollable={false}
                isCentered={true}
                size="xl" // 'sm' || 'lg' || 'xl' 
                isAnimation={true}
                onSubmit={() => {
                    handleOpen(false)
                    handleNext(true)
                }}
            >
                <ModalHeader
                    setIsOpen={() => handleOpen(false)}
                >
                    <ModalTitle id="register-item">Cadastro de Categorias de Complentos</ModalTitle>
                </ModalHeader>
                <ModalBody className='p-3'>
                    <TableCustom
                        columns={columns}
                        rows={categoriesComplementsRender}
                        fileName={'Categoria-de-Gerenciamento'}
                    />
                </ModalBody>

                <ModalFooter>
                    <Button
                        color='info'
                        rounded={1}
                        hoverShadow="sm"
                        shadow="sm"
                        size="sm"
                        onClick={handleBack}
                    >
                        Voltar
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default CategorieComplements