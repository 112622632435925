import React, { useMemo, useState, useCallback, useEffect, useContext, useRef } from 'react'
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import Button from '../../bootstrap/Button';
import TableCustom from '../../MyCustom/TableCustom';
import Input from '../../bootstrap/forms/Input';
import FormGroup from '../../bootstrap/forms/FormGroup';
import { ResultsContext } from '../../../contexts/results';
import { DefaultContext } from '../../../contexts/default';
import PaymentCommandDB from '../../../database/wrappers/paymentCommand';
import PreAlert from '../../../helpers/utils/preAlert';
import Spinner from '../../bootstrap/Spinner';
import Textarea from '../../bootstrap/forms/Textarea';
import Money from '../../../helpers/utils/money/money';

import SalesDB from '../../../database/wrappers/sales';
import CommandDB from '../../../database/wrappers/command';
import { where } from 'firebase/firestore';
import ModalEditProduct from './editProduct';
import { PAYMENT_TYPE_PTBR } from '../../../types/payment';
import ProductDB from '../../../database/wrappers/product';
import ComplementDB from '../../../database/wrappers/complement';
import StoreDB from '../../../database/wrappers/store';
import { processSaleProducts, processCommandProducts } from '../../../utils/fiscal/fiscalFunctions';
import LogFiscalReemissao from '../../../database/wrappers/logFiscalReemissao';
import masks, { unmask } from '../../../helpers/utils/masks';
import Fiscal from '../../../utils/fiscal';

const ModalFixFiscalReports = ({ open, setIsOpen, setIsClose, paymentSelected, estabId, storeId }) => {
  const { onShowAlert, user } = useContext(DefaultContext)
  const [loadingProducts, setLoadingProducts] = useState(false)
  const [openProduct, setOpenProduct] = useState(false);
  const [productSelected, setProductSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [products, setProducts] = useState([]);
  const [productsDicionary, setProductsDicionary] = useState({})
  const [complementsDicionary, setComplementsDicionary] = useState({})
  const [storesDicionary, setstoresDicionary] = useState({})
  const [cpfCnpj, setCpfCnpj] = useState('');
  const toggleModal = useCallback(() => setOpenProduct(state => !state), [])
  const onEditProduct = useCallback((productSelected) => {
    setProductSelected(productSelected);
    toggleModal();
  }, [toggleModal])

  const handlechangeCpfCnpj = (e) => {
    if (e.target.value.length <= 18) {
      setCpfCnpj(e.target.value)
    }
  }
  

  const fetchProducts = async (productsDicionary, complementsDicionary) => {
    if (!estabId || !paymentSelected) {
      onShowAlert(PreAlert.error('Ocorreu um erro inesperado, tente novamente mais tarde.'))
      setIsClose();
      return;
    }
    try {
      setLoadingProducts(true);
      let productsArray = [];

      productsArray = processSaleProducts(paymentSelected.products, productsDicionary, complementsDicionary);
      if(productsArray.length <= 0) {
        setIsClose();
        onShowAlert(PreAlert.error('Ocorreu um erro inesperado.'))
      }

      setProducts(productsArray.sort((a, b) => a.name > b.name ? 1 : -1) || []);
    } catch (error) {
      console.error('Erro ao buscar produtos:', error);
      setIsClose();
    } finally {
      setLoadingProducts(false);
    }
  };

  const onSuccessUpdate = useCallback(() => {
    onShowAlert(PreAlert.success('Sua nota fiscal foi gerada com sucesso.'))
    setIsClose();
  }, [onShowAlert]);

  const onError = useCallback(() => {
    onShowAlert(PreAlert.error('Ocorreu um erro ao gerar nota fiscal, verifique os dados e tente novamente..'))
  }, [onShowAlert])

  useEffect(() => {
    if (!estabId || !storeId) return;
    const loadStores = () => {
      new StoreDB(estabId)
        .getAll()
        .then(stores => {
          setstoresDicionary(stores)
        })
        .catch(error => console.error(error))
    }

    const loadProducts = () => {
      new ProductDB(estabId)
        .getAll()
        .then(products => {
          const productsDicionary = {};
          products.forEach(product => productsDicionary[product.id] = product)
          setProductsDicionary(productsDicionary)
        })
        .catch(error => console.error(error))
    }

    const loadComplements = () => {
      new ComplementDB(estabId)
        .getAll()
        .then(complements => {
          const complementsDicionary = {};
          complements.forEach(complement => complementsDicionary[complement.id] = complement)
          setComplementsDicionary(complementsDicionary)
        })
        .catch(error => console.error(error))
    }
    loadComplements();
    loadProducts();
    loadStores();
  }, [estabId, storeId, openProduct])

  useEffect(() => {
    if (paymentSelected && estabId) fetchProducts(productsDicionary, complementsDicionary);
  }, [paymentSelected, estabId, productsDicionary, complementsDicionary])

  useEffect(() => {
    setError(null)
    setErrorMsg(null)
  }, [paymentSelected])

  async function checkFiscalIssued(estab, idTransaction) {
    const payment = await new SalesDB(estab).get(idTransaction);
    if (payment && payment?.fiscal?.issued) {
      onShowAlert(PreAlert.error('Nota fiscal já emitida.'));
      setIsClose();
      return true;
    }
  }

  const handleGenerateFiscal = useCallback(async () => {
    if (!paymentSelected || !estabId || products.length === 0) {
      onShowAlert(PreAlert.error('Dados incompletos ou inválidos para gerar nota fiscal.'));
      return;
    }
    if(await checkFiscalIssued(estabId, paymentSelected?.id)) return;
    setIsLoading(true);

    try {
      const date = new Date();
      const { payment_type, valueDiscount, storeId, id } = paymentSelected;
      const hashFiscal = paymentSelected?.fiscal?.hash ?? (date.getTime() + user.uid);
      const store = storesDicionary?.find((e) => e.id === storeId);
      if(!store){
        onShowAlert(PreAlert.error('Dados incompletos ou inválidos para gerar nota fiscal.'));
        return;
      }
      const cpfUnmask = unmask(cpfCnpj) === '00000000000' ? null : unmask(cpfCnpj);
      const fiscalInstance = new Fiscal(productsDicionary, complementsDicionary);
      const responseFiscal = await fiscalInstance.generateFiscal(
        hashFiscal,
        products,
        store,
        payment_type,
        valueDiscount,
        cpfUnmask,
      );

      if(!responseFiscal){
        return onShowAlert(PreAlert.error("Ocorreu um erro ao gerar o fiscal, tente novamente mais tarde."))
      }
      if (responseFiscal?.status === 400 ) {
        const logMsg = responseFiscal?.data?.msg || responseFiscal?.data;
        setErrorMsg(logMsg);
        setError(responseFiscal?.data?.Documentos?.[0]?.Situacao?.SitDescricao || 'Erro desconhecido');
        return;
      }

      const fiscal = {
        file_url: responseFiscal.data?.file_url,
        id: responseFiscal.data?.fiscal_id,
        issued: responseFiscal.data?.issued,
      };
      const dataFiscal = { fiscal };
      const dbAction = new SalesDB(estabId).update(id, dataFiscal)
      
      await dbAction;

      new LogFiscalReemissao(estabId, storeId, user.uid).send(fiscalInstance.getSentData(), fiscal, id);
      await new Promise(resolve => setTimeout(resolve, 3000));
      setIsClose();
      onSuccessUpdate();

    } catch (error) {
      console.error('Erro ao gerar nota fiscal:', error);
      onError();
    } finally {
      setIsLoading(false);
    }
  }, [
    paymentSelected,
    estabId,
    products,
    complementsDicionary,
    onSuccessUpdate,
    onError,
    productsDicionary,
    storesDicionary,
    user,
    cpfCnpj
  ]);


  const columns = useMemo(() => ([
    {
      label: 'Nome',
      field: 'name',
    },
    {
      label: 'Ação',
      field: 'action',
      format: row => {
        return <Button
          color="light"
          icon="edit"
          shadow="sm"
          hoverShadow="sm"
          size="sm"
          onClick={() => onEditProduct(row)}
        />
      }
    }
  ]), [onEditProduct])

  return (
    <>
      <Modal
        id={'modal-request'}
        titleId={'request'}
        isOpen={open}
        setIsOpen={setIsOpen}
        isStaticBackdrop={true}
        isScrollable={false}
        isCentered={true}
        size="lg" // 'sm' || 'lg' || 'xl' 
        isAnimation={true}
      >
        <ModalHeader setIsOpen={setIsClose}>
          <ModalTitle id="register-request"> Correção de nota fiscal</ModalTitle>
        </ModalHeader>
        <ModalBody className="p-4">
          {isLoading ?
            <div className='d-flex justify-content-center flex-column  align-items-center  '>
              <Spinner size="4rem" color='success' />
              <p className='pt-3'>Gerando fiscal...</p>
            </div>
            :
            <div>
              <div className="row g-4">
                <div className='d-flex gap-2  flex-column'>

                  <FormGroup id='name' label='Nome' >
                    <Input
                      value={paymentSelected?.name || 'Não informado'}
                      disabled
                      placeholder='100'
                    />
                  </FormGroup>

                  <FormGroup id='cpf' label='CPF' >
                      <Input
                        value={cpfCnpj.length <= 14 ? masks.cpf(cpfCnpj) : masks.cnpj(cpfCnpj)}
                        onChange={handlechangeCpfCnpj}
                    // disabled
                      />
                  </FormGroup>

                  <FormGroup className="d-flex gap-4 ">
                    <FormGroup id='total' label='Valor pago' >
                      <Input
                        value={Money.centsToMaskMoney(paymentSelected?.value || 0)}
                        disabled
                      />
                    </FormGroup>


                    <FormGroup id='discount' label='Desconto' >
                      <Input
                        value={Money.centsToMaskMoney(paymentSelected?.valueDiscount || 0)}
                        disabled
                      />
                    </FormGroup>

                  </FormGroup>

                  <FormGroup id='payment_type' label='Tipo de Pagamento' >
                    <Input
                      value={PAYMENT_TYPE_PTBR[paymentSelected?.payment_type] || ''}
                      disabled
                    />
                  </FormGroup>

                </div>

                {loadingProducts &&
                  <div className='d-flex justify-content-center flex-column  align-items-center  '>
                    <Spinner size="4rem" color='success' />
                    <p className='pt-3'>Carregando produtos...</p>
                  </div>
                }
                {!loadingProducts && (
                  <TableCustom
                    title="Produtos"
                    columns={columns}
                    rows={products}
                    // keyExtractor={item => item.id}
                    noShowHeader
                  />
                )}
              </div>
              {(error || errorMsg) && (
                <Textarea
                  value={error || errorMsg}
                  disabled
                  rows={5}
                />
              )}


              {/* Buttons */}
              <div className="d-flex flex-row-reverse gap-3" style={{ marginTop: 10 }}>
                {isLoading ? (
                  <Button color='success' rounded={1} hoverShadow='sm' shadow='sm'>
                    <Spinner
                      color='light'
                      inButton
                      isGrow
                      isSmall
                      size={10}
                      tag='span'>
                      Carregando...
                    </Spinner>
                    Carregando...
                  </Button>
                ) : (
                  <Button
                    color="success"
                    icon="Send"
                    rounded={1}
                    onClick={handleGenerateFiscal}
                    shadow="sm"
                    hoverShadow="sm"
                    disabled={isLoading}
                    size="sm">
                    Emitir
                  </Button>
                )}
                <Button
                  color="danger"
                  icon="cancel"
                  rounded={1}
                  onClick={setIsClose}
                  shadow="sm"
                  hoverShadow="sm"
                  size="sm"
                >
                  Fechar
                </Button>
              </div>
            </div>
          }
        </ModalBody>
      </Modal>

      <ModalEditProduct
        productSelected={productSelected}
        open={openProduct}
        setIsClose={toggleModal}
        setIsOpen={toggleModal}
        productsDicionary={productsDicionary}
        complementsDicionary={complementsDicionary}
        estabId={estabId}
      />
    </>
  );
}
export default ModalFixFiscalReports;