import React, { memo ,useState} from 'react';
import YoutLogo from '../../../assets/img/yourlogo.png';
import './avatar.css';

function AvatarVideo({ id, value, onChange, limit, withVideo }) {
    const photo = value ? value : YoutLogo;
    const [event, setevent] = useState(false);

    // const [alertVisible, setalertVisible] = useState(false)

    const LIMIT_SIZE = 10000

    const onLocalChange = e => {
        // const [file] = Array.from(e.target.files)
        // const limitSize = LIMIT_SIZE_KB * 1000
        // if(file.size > limitSize)
        //     return setalertVisible(true)
        onChange(e);
        if(e) {
            setevent(true)
        } else {
            setevent(false)
        }
    }

    return (
        <>
            <div className="personal-image">
            <label className="label">
                <input type="file" id={id} accept="image/*,video/mp4" multiple onChange={onLocalChange} />
                <figure className="personal-figure" style={{width: 'auto', height:'auto', maxHeight: '100px'}}>
                    <div  className={`personal-avatar rounded`} >
                        {}
                        <img src={photo}  style={{zIndex: '5', width: 'auto', height:'auto', maxHeight: '100px'}} alt=""/>

                        <video src={photo} style={{zIndex: '4', width: "auto", height:"auto",  maxHeight: "100px", position: "absolute", top: 0, left: -87}} muted loop autoPlay={true}/>
                    </div>
                    <figcaption className="personal-figcaption">
                        <img src="https://raw.githubusercontent.com/ThiagoLuizNunes/angular-boilerplate/master/src/assets/imgs/camera-white.png" />
                    </figcaption>
                </figure>
            </label>
        </div>

            {/* <div style={{ position: 'absolute', top: 0 }}>
                <AlertCustom
                    show={alertVisible}
                    icon='ErrorOutline'
                    title='Imagem muito grande'
                    msm={`Limite de ${LIMIT_SIZE_KB} KB por imagem`}
                    color='danger'
                    timer={10000}
                    onClose={() => setalertVisible(false)}
                />
            </div> */}
        </>
    );
}

export default memo(AvatarVideo)