import React from 'react';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Button from '../../../components/bootstrap/Button';
import Avatar from '../../../components/Avatar';
import Input from '../../../components/bootstrap/forms/Input';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import { demoPages } from '../../../menu';
import USERS from '../../../common/data/userDummyData';
import Card, {
  CardActions,
  CardBody,
  CardFooter,
  CardFooterLeft,
  CardFooterRight,
  CardHeader,
  CardLabel,
  CardSubTitle,
  CardTitle,
} from '../../../components/bootstrap/Card';

const Profile = () => {
  return (
    <PageWrapper title={demoPages.reports.text}>
      <Page>
        <CardHeader>
          <CardLabel icon='Person' iconColor='info'>
            <CardTitle>Perfil</CardTitle>
            <CardSubTitle>Configurações de Usuario</CardSubTitle>
          </CardLabel>
        </CardHeader>
        <CardBody className='pb-0' isScrollable>
          <div className='row g-3'>
            <div className='col-12'>
              <div className='row g-3 align-items-center'>
                <div className='col-lg-auto'>
                  <Avatar
                    srcSet={USERS.JOHN.srcSet}
                    src={USERS.JOHN.src}
                    color={USERS.JOHN.color}
                  />
                </div>
                <div className='col-lg'>
                  <div className='row g-2'>
                    <div className='col-auto'>
                      <Input
                        type='file'
                        autoComplete='photo'
                      />
                    </div>
                    <div className='col-auto'>
                      <Button
                        color='dark'
                        isLight
                        icon='Delete'>
                        Apagar imagem de perfil
                      </Button>
                    </div>
                    <div className='col-12'>
                      <p className='lead text-muted'>
                        Uma foto ajuda seus companheiros de equipe a saber sobre você!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 border-bottom' />
            <div className='col-md-6'>
              <FormGroup id='firstName' label='Nome' isFloating>
                <Input
                  disabled
                  placeholder='Primeiro Nome'
                  autoComplete='additional-name'
                  // onChange={formik.handleChange}
                  // onBlur={formik.handleBlur}
                  // value={formik.values.firstName}
                  // isValid={formik.isValid}
                  // isTouched={formik.touched.firstName}
                  // invalidFeedback={formik.errors.firstName}
                  validFeedback='Está bom!'
                />
              </FormGroup>
            </div>
            <div className='col-md-6'>
              <FormGroup id='occupation' label='Função' isFloating>
                <Input
                  disabled
                  placeholder='Função'
                  autoComplete='family-name'

                  // onChange={formik.handleChange}
                  // onBlur={formik.handleBlur}
                  // value={formik.values.lastName}
                  // isValid={formik.isValid}
                  // isTouched={formik.touched.lastName}
                  // invalidFeedback={formik.errors.lastName}
                  validFeedback='Está bom!'
                />
              </FormGroup>
            </div>
            <div className='col-12'>
              <FormGroup

                id='displayName'
                label='E-mail de Acesso'
                isFloating
                formText='Será assim que seu nome será exibido na seção da conta e nas avaliações'>
                <Input
                  disabled
                  placeholder='E-mail'
                  autoComplete='username'
                  // onChange={formik.handleChange}
                  // onBlur={formik.handleBlur}
                  // value={formik.values.displayName}
                  // isValid={formik.isValid}
                  // isTouched={formik.touched.displayName}
                  // invalidFeedback={formik.errors.displayName}
                  validFeedback='Está bom!'
                />
              </FormGroup>
            </div>

            <div className='col-12'>
              <Card>
                <CardHeader>
                  <CardLabel
                    icon='LocalPolice'
                    iconColor='success'>
                    <CardTitle>Trocar Senha</CardTitle>
                  </CardLabel>
                </CardHeader>
                <CardBody>
                  <div className='row g-2'>
                    <div className='col-12'>
                      <FormGroup
                        id='currentPassword'
                        label='Senha atual'
                        isFloating>
                        <Input
                          type='password'
                          placeholder='Senha atual'
                          autoComplete='current-password'
                        // onChange={formik.handleChange}
                        // value={
                        //   formik.values
                        //     .currentPassword
                        //}
                        />
                      </FormGroup>
                    </div>
                    <div className='col-12'>
                      <FormGroup
                        id='newPassword'
                        label='Nova senha'
                        isFloating>
                        <Input
                          type='password'
                          placeholder='Nova senha'
                          autoComplete='new-password'
                        // onChange={formik.handleChange}
                        // onBlur={formik.handleBlur}
                        // value={
                        //   formik.values.newPassword
                        // }
                        // isValid={formik.isValid}
                        // isTouched={
                        //   formik.touched.newPassword
                        // }
                        // invalidFeedback={
                        //   formik.errors.newPassword
                        // }
                        //validFeedback='Looks good!'
                        />
                      </FormGroup>
                    </div>
                    <div className='col-12'>
                      <FormGroup
                        id='confirmPassword'
                        label='Confirmar nova senha'
                        isFloating>
                        <Input
                          type='password'
                          placeholder='Confirmar nova senha'
                          autoComplete='new-password'
                        // onChange={formik.handleChange}
                        // onBlur={formik.handleBlur}
                        // value={
                        //   formik.values
                        //     .confirmPassword
                        // }
                        // isValid={formik.isValid}
                        // isTouched={
                        //   formik.touched
                        //     .confirmPassword
                        // }
                        // invalidFeedback={
                        //   formik.errors
                        //     .confirmPassword
                        // }
                        //validFeedback='Looks good!'
                        />
                      </FormGroup>
                    </div>
                  </div>
                </CardBody>
                <CardFooter>
                  <CardFooterLeft>
                    <Button
                      color='danger'
                      isLink
                      type='reset'
                    // onClick={formik.resetForm}
                    >
                      Limpar todos os campos
                    </Button>
                  </CardFooterLeft>
                  <CardFooterRight>
                    <Button
                      type='submit'
                      icon='Save'
                      color='success'
                      isOutline
                    // isDisable={!formik.isValid && !!formik.submitCount}
                    >
                      Salvar
                    </Button>
                  </CardFooterRight>
                </CardFooter>
              </Card>
            </div>
          </div>
        </CardBody>
      </Page>
    </PageWrapper>
  );
};

export default Profile;