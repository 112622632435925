import dateFormat from 'dateformat';
import { limit, orderBy } from 'firebase/firestore';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import Breadcrumb from '../../../../components/bootstrap/Breadcrumb';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../../../components/bootstrap/Card';
import SelectEstablishment from '../../../../components/MyCustom/SelectEstablishment';
import TableCustom from '../../../../components/MyCustom/TableCustom';
import { DefaultContext } from '../../../../contexts/default';
import UsersHistoryDB from '../../../../database/wrappers/history';
import Page from '../../../../layout/Page/Page';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft } from '../../../../layout/SubHeader/SubHeader';
import { demoPages } from '../../../../menu';


const UserHistory = () => {

    const { establishment, usersDicionary } = useContext(DefaultContext)


    const [usersInEstab, setUsersInEstab] = useState([])
    const [history, setHistory] = useState([])

    useEffect(() => {
        if (!establishment) return;
        const onSubscribe = new UsersHistoryDB(establishment.id).on(setHistory, orderBy('created_at', 'desc'), limit(1000))

        return onSubscribe;
    }, [establishment]);


    useEffect(() => {
        if (establishment) {

            const usersFilter = history.filter(user => user.estabId === establishment.id);

            setUsersInEstab(usersFilter)
        }

    }, [establishment, history])


    const columns = useMemo(() => ([

        {
            label: 'Data',
            field: 'created_at',
            format: row => dateFormat(row.created_at.toDate(), 'dd/mm/yyyy HH:MM'), 
            formatExport: value => dateFormat(value.toDate(), 'dd/mm/yyyy HH:MM'),
        },

        {
            label: 'Estabelecimento',
            field: 'estabId',
            format: row => establishment !== null ? establishment?.name|| '' : '',
            formatExport: value => establishment?.id === value ? establishment.name : '',

        },

        {
            label: 'Usuário',
            field: 'userId',
            format: row => usersDicionary !== null ? usersDicionary[row.userId]?.displayName || '' : '',
            formatExport: value => usersDicionary[value]?.displayName || '',
        },
        {
            label: 'Mensagem',
            field: 'message',
        },

    ]), [usersDicionary,establishment])
 
    return (
        <>
            <PageWrapper title={demoPages.reports.subMenu.userHistory.text}>
                <SubHeader>
                    <SubHeaderLeft>
                        <Breadcrumb
                            list={[
                                { title: 'Relatórios', to: '/reports' },
                                { title: 'Histórico de usuários', to: 'reports/userHistory' },
                            ]}
                        />
                    </SubHeaderLeft>
                </SubHeader>

                <Page container="fluid">
                    <Card>
                        <CardHeader>
                            <CardLabel icon='SupervisorAccount' iconColor='light'>
                                <CardTitle>Histórico de usuários</CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody className='table-responsive'>
                            <div className='row'>
                                <SelectEstablishment />
                            </div>

                            <TableCustom
                                columns={columns}
                                rows={usersInEstab}
                                keyExtractor={item => item.id}
                                fileName='Histórico-de-usuários '
                            />
                        </CardBody>
                    </Card>
                </Page>
            </PageWrapper>

            {/* <ModalUserHistoryLogs
            open={openModalHistory}
            setIsClose={handleCloseModalLogs}
            onClose={handleCloseModalLogs}
            historySelected={userHistory}         
            /> */}

        </>
    );
};

export default UserHistory;
