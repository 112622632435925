import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../../layout/SubHeader/SubHeader';
import Page from '../../../../layout/Page/Page';
import Breadcrumb from '../../../../components/bootstrap/Breadcrumb';
import { demoPages } from '../../../../menu';
import Card, { CardBody } from '../../../../components/bootstrap/Card';
import Button from '../../../../components/bootstrap/Button';
import ModalRegisterboxOffice from '../../../../components/modals/BoxOffice/ModalRegister';
import { DefaultContext } from '../../../../contexts/default';
import SelectEstablishment from '../../../../components/MyCustom/SelectEstablishment';
import TableCustom from '../../../../components/MyCustom/TableCustom';
import showNotification from '../../../../components/extras/showNotification';
import notificationBody from '../../../../helpers/utils/functions/notificationBody';
import { orderBy } from 'firebase/firestore';
import SelectStore from '../../../../components/MyCustom/SelectStore';
import BoxOfficeDB from '../../../../database/wrappers/boxOffice';
import Money from '../../../../helpers/utils/money/money';
import Input from '../../../../components/bootstrap/forms/Input';
import useDarkMode from '../../../../hooks/useDarkMode';

const BoxOffice = () => {
    const { darkModeStatus } = useDarkMode();

    const { establishment, store, onShowAlert, onShowQuestion } = useContext(DefaultContext);

    const [openModalRegister, setOpenModalRegister] = useState(false);
    const [boxOffice, setboxOffice] = useState([]);
    const [boxOfficeSelected, setboxOfficeSelected] = useState({});

    useEffect(() => {
        if (!establishment || !store) return;
        const onSubscribe = new BoxOfficeDB(establishment.id, store.id).on(setboxOffice, orderBy('name', 'asc'));
        return onSubscribe;
    }, [establishment, store])

    const handleOpenModalRegister = useCallback(() => {
        setboxOfficeSelected(null)
        setOpenModalRegister(true);
    }, [])

    const handleCloseModalRegister = useCallback(() => {
        setOpenModalRegister(false);
    }, [])

    const handleOpenModalEdit = useCallback((item) => {
        setboxOfficeSelected(item);
        setOpenModalRegister(true)
    }, [])

    const handleOpenModalDelete = useCallback((id) => {
        onShowQuestion({
            title: 'Exclusão de Entrada',
            message: 'Você está excluindo uma Entrada. Está ação é irreversível. Deseja continuar?',
            onConfirm: () => {
                new BoxOfficeDB(establishment.id)
                    .delete(id)
                    .then(res => showNotification('', notificationBody('Registro deletado com sucesso'), 'success'))
                    .catch(err => showNotification('', notificationBody('Falhou ao deletar o registro'), 'danger'))
            }
        })
    }, [])

    const handleChangeStatus = useCallback((id, active) => {
        new BoxOfficeDB(establishment.id, store.id)
            .update(id, {
                active: !active
            })
            .catch(error => onShowAlert({
                title: 'ERRO!',
                msm: 'Não foi possível atualizar o status do entrada',
                icon: 'Error',
                color: 'danger'
            }))
    }, [establishment, store])

    const columns = useMemo(() => ([
        {
            label: 'Nome',
            field: 'name',
        },
        {
            label: 'Valor',
            field: 'value',
            format: row => Money.centsToMaskMoney(row.value)
        },
        {
            label: 'Consumação',
            field: 'consummation',
            format: row => Money.centsToMaskMoney(row.consummation)
        },
        {
          label: 'Cor',
          field: 'color',
          format: row => (
            <Input
              type='color'
              disabled
              value={row.color}
            />
          )
        },
        {
            label: 'Status',
            field: 'status',
            format: row => (
                <Button
                    isLink
                    color={row.active ? 'success' : 'danger'}
                    icon='Circle'
                    onClick={() => handleChangeStatus(row.id, row.active)}
                    className='text-nowrap'>
                    {row.active ? 'Ativo' : 'Inativo'}
                </Button>
            )
        },
        {
            label: 'Ações',
            field: 'actions',
            format: row => (
                <div className='w-25 d-flex justify-content-between'>
                    <Button
                        color={darkModeStatus ? 'light' : 'dark'}
                        icon="edit"
                        shadow="sm"
                        hoverShadow="sm"
                        size="sm"
                        onClick={() => handleOpenModalEdit(row)}
                    />

                    <Button
                        color="danger"
                        icon="Trash"
                        shadow="sm"
                        hoverShadow="sm"
                        size="sm"
                        onClick={() => handleOpenModalDelete(row.id)}
                    />
                </div>
            )
        },
    ]), [handleChangeStatus, handleOpenModalEdit, handleOpenModalDelete])

    return (
        <>
            <PageWrapper title={demoPages.command.subMenu.boxOffice.text}>
                <SubHeader>
                    <SubHeaderLeft>
                        <Breadcrumb
                            list={[
                                { title: 'Comanda', to: '/command' },
                                {
                                    title: 'Bilheteria',
                                    to: '/command/boxoffice',
                                },
                            ]}
                        />
                    </SubHeaderLeft>
                    <SubHeaderRight>
                        <Button
                            color='primary'
                            icon='plus'
                            shadow="sm"
                            hoverShadow="sm"
                            onClick={handleOpenModalRegister}
                        >
                            Cadastrar
                        </Button>
                    </SubHeaderRight>
                </SubHeader>
                <Page container="flui">
                    <Card>
                        <CardBody className='table-responsive p-5'>
                            <div className='row d-flex align-items-center mt-4 mx-1' >
                                <SelectEstablishment />
                                <SelectStore />
                            </div>

                            <TableCustom columns={columns} rows={boxOffice} />
                        </CardBody>
                    </Card>
                </Page>
            </PageWrapper>

            <ModalRegisterboxOffice
                open={openModalRegister}
                setIsOpen={setOpenModalRegister}
                setIsClose={handleCloseModalRegister}
                boxOfficeSelected={boxOfficeSelected}
            />
        </>
    );
};

export default BoxOffice;
