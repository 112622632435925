// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDZNpaZVVeD0fDNz-e9ziAXkGrnEUQRFDs",
  authDomain: "ugetpdx-prod.firebaseapp.com",
  databaseURL: "https://ugetpdx-prod-default-rtdb.firebaseio.com",
  projectId: "ugetpdx-prod",
  storageBucket: "ugetpdx-prod.appspot.com",
  messagingSenderId: "140341703184",
  appId: "1:140341703184:web:b85dadd138369cba43d095",
  measurementId: "G-7Z2F5KF564"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;