import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { DefaultContext } from '../../../contexts/default';
import ClientEstabDB from '../../../database/wrappers/clientEstab';
import RechargesDB from '../../../database/wrappers/recharges';
import masks from '../../../helpers/utils/masks';
import Button from '../../bootstrap/Button';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import Spinner from '../../bootstrap/Spinner';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import { increment } from 'firebase/firestore';
import Label from '../../bootstrap/forms/Label';
import Money from '../../../helpers/utils/money/money';
import PreAlert from '../../../helpers/utils/preAlert';





const ModalSaldo = ({ open, setIsOpen, client, setIsClose }) => {
    const { estabSelected, storeSelected, user, onShowAlert } = useContext(DefaultContext);
    const [isLoading, setIsLoading] = useState(false);
    function applyBalanceRecharge(valueRecharge) {
        return new ClientEstabDB(estabSelected).update(client.id, {
            balance: increment(valueRecharge)
        });
    }

    useEffect(() => {
        if (!open) return formik.resetForm() && setIsLoading(false);
    }, [open])

    const formik = useFormik({
        initialValues: {
            balance: ''
        },
        validate: values => {
            const errors = {}
            if (!values.balance) {
                errors.balance = 'Campo obrigatório'
            }
            return errors
        },
        onSubmit: async (values) => {
            setIsLoading(true);
            let value = values.balance.replace(/[,\.]/g, '');
            const data = {
                userId: user.uid,
                clientId: client.id,
                storeId: storeSelected,
                terminal: 'Dashboard',
                value: Number(value),
                payment_type: 10,
                name_card: null,
                number_card: null,
                transactionId: null,
            }

            const onSuccess = () => {
                onShowAlert(PreAlert.success('Recarga realizada com sucesso!'))
                setIsClose();
            }
            const onError = (error) => {
                console.error(error);
                onShowAlert(PreAlert.error('Falhou ao realizar recarga!'))
            }
            try {
                await applyBalanceRecharge(value);
                new RechargesDB(estabSelected).create(data);
                setIsClose();
                setIsLoading(false);
                onSuccess();
            } catch (error) {
                console.error(error);
                setIsClose();
                setIsLoading(false);
                onError();
            }
        }

    })


    return (
        <Modal
            id={'modal-saldo'}
            titleId={'Saldo'}
            isOpen={open}
            setIsClose={setIsClose}
            setIsOpen={setIsOpen}
            isStaticBackdrop={true}
            isScrollable={false}
            isCentered={true}
            size="sm" // 'sm' || 'lg' || 'xl' 
            isAnimation={true}
            onSubmit={formik.handleSubmit}
        >
            <ModalHeader setIsOpen={setIsOpen}>
                <ModalTitle id="saldo">{'Saldo'}</ModalTitle>
            </ModalHeader>
            <ModalBody className='p-5'>
                <form onSubmit={formik.handleSubmit}>
                    <div className="row pb-2 d-flex">
                        <FormGroup id="saldo disponível" label="Saldo disponível: " className='col-md-12 mb-4 p-2'>
                            <Label className='ml-2'>{` ${Money.centsToMaskMoney(client?.balance)}`}</Label>
                        </FormGroup>

                        <FormGroup id="balance" label="Adicionar Saldo:" className='col-md-12 mb-4'>
                            <Input
                                value={masks.money(formik.values.balance)}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isValid={formik.values.balance}
                            />
                        </FormGroup>
                    </div>
                    {/* Buttons */}
                    <div className="row pt-4">
                        <div className='d-flex justify-content-end gap-2'>

                            {isLoading ? (
                                <Button
                                    color="success"
                                    rounded={1}
                                    hoverShadow="sm"
                                    shadow="sm"
                                    size="sm"
                                >
                                    <Spinner
                                        color="light"
                                        inButton
                                        isGrow
                                        isSmall
                                        size={10}
                                        tag="span"
                                    >
                                        Carregando...
                                    </Spinner>
                                    Carregando...
                                </Button>
                            ) : (
                                <Button
                                    type='submit'
                                    color='success'
                                    icon="check"
                                    rounded={1}
                                    hoverShadow="sm"
                                    shadow="sm"
                                    size="sm"
                                >
                                    {'Adicionar'}
                                </Button>
                            )}
                        </div>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    )
}
export default ModalSaldo