import type { Timestamp } from "firebase/firestore";
import { useMemo, useState } from "react";
import type Sale from "../database/entities/sales/sale.entity";
import { DELIVERY_PAYMENT, PAYMENT_TYPE } from "../types/payment";

const useTotalByDay = (sales: any) => {
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const calculateValues = (items: any) => {
		let qtdChargeback = 0;
		let totalCash = 0;
		let totalDebit = 0;
		let totalCredit = 0;
		let totalPix = 0;
		let totalMealTicket = 0;
		let totalCourtesy = 0;
		let totalDiscount = 0;
		let count = 0;
		let countIfood= 0;
		let countRappi = 0;
		let totalIfood = 0;
		let totalRappi = 0;
		let created_at: Date | undefined;
		let created_at_noFormat: Timestamp | undefined;

		const sumTypeValue = (paymentType: number, value: number) => {
			if (paymentType === PAYMENT_TYPE.CASH) totalCash += value;
			else if (paymentType === PAYMENT_TYPE.CARD_CREDIT) totalCredit += value;
			else if (paymentType === PAYMENT_TYPE.CARD_DEBIT) totalDebit += value;
			else if (paymentType === PAYMENT_TYPE.PIX) totalPix += value;
			else if (paymentType === PAYMENT_TYPE.MEAL_TICKET)
				totalMealTicket += value;
			else if (paymentType === PAYMENT_TYPE.COURTESY) totalCourtesy += value;
			else if (paymentType === DELIVERY_PAYMENT.IFOOD_METHOD)
				totalIfood += value;
			else if (paymentType === DELIVERY_PAYMENT.RAPPI_METHOD)
				totalRappi += value;
		};

		for (const item of items) {
			if (item.reversed) {
				qtdChargeback++;
			} else {
				totalDiscount += item.discountValue || 0;
				if (item.origin === "IFOOD") {
					countIfood += 1
					sumTypeValue(DELIVERY_PAYMENT.IFOOD_METHOD, item.subTotal);
				} else if (item.origin === "RAPPI") {
					countRappi += 1
					sumTypeValue(DELIVERY_PAYMENT.RAPPI_METHOD, item.subTotal);
				}else {
					count += 1
				}
				if (item.payment_type === PAYMENT_TYPE.DIVIDED)
					for (const split of item.splitPayment) {
						sumTypeValue(split.payment_type, split.value);
					}
				else sumTypeValue(item.payment_type, item.value);
			}
		}

		const total =
			totalCash + totalDebit + totalCredit + totalPix + totalMealTicket;

		return {
			qtdChargeback,
			cash: totalCash,
			debit: totalDebit,
			credit: totalCredit,
			pix: totalPix,
			mealTicket: totalMealTicket,
			courtesy: totalCourtesy,
			discount: totalDiscount,
			total,
			totalIfood,
			totalRappi,
			count,
			countIfood,
			countRappi,
			created_at,
			created_at_noFormat,
		};
	};

	const groupByDay = (items: Sale[]) => {
		const groupedItems: Record<string, ReturnType<typeof calculateValues>> = {};
		for (const item of items) {
			const date = item.created_at?.toDate();
			const created_at_noFormat = item.created_at;
			const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
			const key = formattedDate;
			if (!groupedItems[key]) {
				groupedItems[key] = {
					...calculateValues([item]),
					created_at: date,
					created_at_noFormat: created_at_noFormat,
				};
			} else {
				groupedItems[key].qtdChargeback += calculateValues([
					item,
				]).qtdChargeback;
				groupedItems[key].cash += calculateValues([item]).cash;
				groupedItems[key].debit += calculateValues([item]).debit;
				groupedItems[key].credit += calculateValues([item]).credit;
				groupedItems[key].pix += calculateValues([item]).pix;
				groupedItems[key].mealTicket += calculateValues([item]).mealTicket;
				groupedItems[key].courtesy += calculateValues([item]).courtesy;
				groupedItems[key].discount += calculateValues([item]).discount;
				groupedItems[key].total += calculateValues([item]).total;
				groupedItems[key].totalIfood += calculateValues([item]).totalIfood;
				groupedItems[key].totalRappi += calculateValues([item]).totalRappi;
				groupedItems[key].countIfood += calculateValues([item]).countIfood;
				groupedItems[key].countRappi += calculateValues([item]).countRappi;
				groupedItems[key].count += calculateValues([item]).count;
				groupedItems[key].created_at = item.created_at?.toDate();
				groupedItems[key].created_at_noFormat = item.created_at;
			}
		}

		const result = Object.keys(groupedItems).map((key) => ({
			...groupedItems[key],
			date: key,
		}));

		return result;
	};

	const mergeAndSumByDate = (arr1: any[]) => {
		const merged: Record<string, any> = {};
		for (const item of arr1) {
			const date = item.date;
			if (!merged[date]) {
				merged[date] = { ...item };
			} else {
				merged[date].qtdChargeback += item.qtdChargeback;
				merged[date].cash += item.cash;
				merged[date].debit += item.debit;
				merged[date].credit += item.credit;
				merged[date].pix += item.pix;
				merged[date].mealTicket += item.mealTicket;
				merged[date].courtesy += item.courtesy;
				merged[date].discount += item.discount;
				merged[date].total += item.total;
				merged[date].count += item.count;
				merged[date].countRappi += item.countRappi;
				merged[date].countIfood += item.countIfood;

			}
		}

		const result = Object.keys(merged).map((key) => ({
			...merged[key],
			date: key,
		}));
		return result;
	};

	const salesByDay = useMemo(() => {
		if (!sales) {
			return [];
		}
		return groupByDay(sales as Sale[]);
	}, [sales]);

	const mergedByDay = useMemo(() => {
		if (!sales) {
			return [];
		}
		return mergeAndSumByDate(salesByDay).sort(
			(a, b) => a.created_at_noFormat.seconds - b.created_at_noFormat.seconds,
		);
	}, [salesByDay]);

	return { mergedByDay, isLoading, setIsLoading };
};

export default useTotalByDay;
