import React, { useContext, useMemo, useState,useEffect } from 'react';
import Breadcrumb from '../../../../components/bootstrap/Breadcrumb';
import Card, { CardBody } from '../../../../components/bootstrap/Card';
import Page from '../../../../layout/Page/Page';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft } from '../../../../layout/SubHeader/SubHeader';
import { demoPages } from '../../../../menu';
import SelectEstablishment from '../../../../components/MyCustom/SelectEstablishment';
import SelectStore from '../../../../components/MyCustom/SelectStore';
// Database
import logo from '../../../../assets/img/logo_uget_black.png';
import { DefaultContext } from '../../../../contexts/default';
import FeedbacksDB from '../../../../database/wrappers/feedbacks';
import { where } from 'firebase/firestore';
import TableCustom from '../../../../components/MyCustom/TableCustom';
import { ResultsContext } from '../../../../contexts/results';
import Chart from '../../../../components/extras/Chart';
import FeedbackDash from '../../../../components/MyCustom/Dashboard/FeedbackDash';
import { CardHeader,CardLabel,CardTitle } from '../../../../components/bootstrap/Card';

const Feedbacks = () => {

  const { estabSelected,storeSelected} = useContext(DefaultContext)
  const [feedbacks, setFeedbacks] = useState([])


  useEffect(() => {
    if(!estabSelected || !storeSelected) return;
    const onSubscribe = new FeedbacksDB()
      .on(data => {
        const feedbacks = [];
        data.forEach((feed) => {
          const categoria = 
            feed.feedback === ""  || feed.feedback === 0 ? 'Péssimo' :
            feed.feedback === 1 ? 'Ruim' :
            feed.feedback === 2 ? 'Razoável' :
            feed.feedback === 3 ? 'Bom' :
            feed.feedback === 4 ? 'Ótimo' :
            null;
          
          const existingFeed = feedbacks.find((f) => categoria === f.categoria);
          if(existingFeed){
            existingFeed.total++;
          } else {
            feedbacks.push({
              ...feed,
              total: 1,
              categoria,
            })
          }
        })

        setFeedbacks(feedbacks);
      }, 
      where('estabId', '==', estabSelected), 
      where('storeId', '==', storeSelected)
    )
    return onSubscribe;
  }, [estabSelected, storeSelected])


  const columns = useMemo(() => ([
    {
      label: 'Avaliação',
      field: 'categoria',
    },
    {
      label: 'Total',
      field: 'total',
    }
    
  ]), [])

  return (
    <>
      <PageWrapper title={demoPages.reports.subMenu.feedbacks.text}>
        <SubHeader>
          <SubHeaderLeft>
            <Breadcrumb
              list={[
                { title: 'Relatórios', to: '/reports/feedbacks' },
                {
                  title: 'Feedbacks',
                  to: '/reports/feedbacks',
                },
              ]}
            />
          </SubHeaderLeft>
          

        </SubHeader>
        <Page container='fluid'>
          <Card>
            <CardBody className='table-responsive p-5'>
            <div className='row'>
                  <SelectEstablishment />
                  <SelectStore />
            </div>
            </CardBody>
          </Card>

        
          <div className='col-xxl-12 d-flex justify-content-between'>
            <Card className='col-xxl-4'>
              <CardHeader>
                <CardLabel icon='ThumbUpAlt' iconColor='light'>
                  <CardTitle>Avaliações</CardTitle>
                </CardLabel>
              </CardHeader>
              <CardBody className='table-responsive p-5'>
                <FeedbackDash
                  labels={feedbacks?.map((f) => f.categoria)}
                  series={feedbacks?.map((f) => f.total)}
                />
              </CardBody>
            </Card>

            <Card className='col-xxl-7'>
              <CardHeader>
                  <CardLabel icon='ThumbUpAlt' iconColor='light'>
                    <CardTitle>Avaliações</CardTitle>
                  </CardLabel>
              </CardHeader>
              <CardBody className='table-responsive p-5'>
                <TableCustom
                  columns={columns}
                  rows={feedbacks}
                  fileName={'Feedbacks'}
                />
              </CardBody>
            </Card>
          </div>
    

        </Page>
      </PageWrapper>

    </>
  );
};

export default Feedbacks;
