export const TRIBUTARY_SITUATION = {
  ISENTO: 0,
  TRIBUTADO: 1,
  SUBS_TRIBUTARIA: 2,
} as const;

export function convertTrybuIntToPTBR(option: number) {
  if (option === TRIBUTARY_SITUATION.ISENTO) return 'Isento';
  if (option === TRIBUTARY_SITUATION.TRIBUTADO) return 'Tributado';
  if (option === TRIBUTARY_SITUATION.SUBS_TRIBUTARIA) return 'Substituição Tributária';
  return '';
}


export function convertTrybuImport(option: string) {
  if(option === 'i') return TRIBUTARY_SITUATION.ISENTO;
  if(option === 't') return TRIBUTARY_SITUATION.TRIBUTADO;
  if(option === 's') return TRIBUTARY_SITUATION.SUBS_TRIBUTARIA;
  return TRIBUTARY_SITUATION.ISENTO;
}