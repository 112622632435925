import React, { useContext } from "react";

import LogoUget from '../../../assets/img/logo_uget.png'


const style = {
    backgroundColor: '#323232',
    borderRadius: '20px',
    marginTop: '20px'

};
const FooterPdf = ({ }) => {

    return (
        <div className="d-flex justify-content-center  align-content-center my-auto ">
            <div  style={style} className="px-2 py-3 row justify-content-center  align-content-center "> 
                <img src={LogoUget} width={80} height={11.18} alt="logo uget" />
            </div>
        </div >
    );
}

export default FooterPdf;