import axios from 'axios';

export const api = axios.create({
	baseURL: "https://fiscal-production-token-7n3oeyxokq-uc.a.run.app/api",
	headers: {
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*'
	}
});



export const getAuthToken = async (cnpj,certification_password) => {
	try {
		const body = {
			cnpj: cnpj.replace(/\D/g, ""),
			certification_password: certification_password,
		};
		const response = await api.post('/session/auth', body);
		if (response.data.token) {
			const token = response.data.token;
			localStorage.setItem('tokenPDV', token);
			return token;
		} else {
			console.error('Token de autorização não encontrado na resposta.');
			localStorage.removeItem('tokenPDV');
			return null;
		}
	} catch (error) {
		console.error('Erro ao obter o token de autorização:', error);
		localStorage.removeItem('tokenPDV');
		return null;
	}
}
export const getFiscalData = async (idFiscal) => {
	try {
		if (idFiscal) {
			const authToken = localStorage.getItem('tokenPDV');
			if (authToken) {
				const config = {
					headers: {
						Authorization: `Bearer ${authToken}`
					}
				};
				const response = await api.get(`/fiscal/list/${idFiscal}`, config);
				return response;
			} else {
				console.error('Token de autorização não encontrado.');
			}
		} else {
			console.error('Sem ID da nota fiscal.');
		}
	} catch (error) {
		console.error(error);
	}
};
export const getIssuedFiscal = async (bodyReq) => {
	try {
		if (bodyReq) {
			const authToken = localStorage.getItem('tokenPDV');
			if (authToken) {
				const config = {
					headers: {
						Authorization: `Bearer ${authToken}`
					}
				};
				const response = await api.post('/fiscal/store', bodyReq, config);
				return response;
			}
		} else {
			console.error('Body não encontrado.');
		}
	} catch (error) {
		console.error('Erro:', error);
		return error.response;
	}
}


export default { getIssuedFiscal, getAuthToken, api, getFiscalData };