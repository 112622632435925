import { QueryConstraint, Unsubscribe } from "firebase/firestore";
import FirestorePipe from "../utils/pipe";

class FeedbacksDB extends FirestorePipe {

  public static readonly colName = 'Feedbacks'

  constructor() {
    super(FeedbacksDB.colName);
  }

  public create(data: any): Promise<any> {
    return this._create(data);
  }
  public update(id: string, data: any): Promise<any> {
    return this._update(id, data);
  }
  public delete(id: string): Promise<any> {
    return this._delete(id);
  }
  public getAll(...params: QueryConstraint[]): Promise<any[]> {
    return this._getAll<any>(...params);
  }
  public get(id: string): Promise<any> {
    return this._get(id);
  }
  public on(listener: (data: any[]) => void, ...params: QueryConstraint[]): Unsubscribe {
    return this._on(listener, ...params);
  }


}

export default FeedbacksDB;