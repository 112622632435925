import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft } from '../../../../layout/SubHeader/SubHeader';
import Page from '../../../../layout/Page/Page';
import Breadcrumb from '../../../../components/bootstrap/Breadcrumb';
import { demoPages } from '../../../../menu';
import { DefaultContext } from '../../../../contexts/default';
import Card, { CardBody, CardHeader, CardLabel, CardSubTitle, CardTitle } from '../../../../components/bootstrap/Card';
import SelectEstablishment from '../../../../components/MyCustom/SelectEstablishment';
import SelectStore from '../../../../components/MyCustom/SelectStore';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../components/bootstrap/forms/Input';
import { useFormik } from 'formik';
import Button from '../../../../components/bootstrap/Button';
import Spinner from '../../../../components/bootstrap/Spinner';
import api from '../../../../services/api';
import PreAlert from '../../../../helpers/utils/preAlert';
import NfcsAccessDB from '../../../../database/wrappers/nfcsAccess';
import TableCustom from '../../../../components/MyCustom/TableCustom';
import showNotification from '../../../../components/extras/showNotification';
import notificationBody from '../../../../helpers/utils/functions/notificationBody';
import ModalRegisterNFC from '../../../../components/modals/nfcAcess'

const AccessPassword = () => {
  const { establishment, store, onShowAlert, onShowQuestion, usersDicionary } = useContext(DefaultContext)

  const [nfcs, setnfcs] = useState([])
  const [openNfc, setopenNfc] = useState();
  const [loading, setloading] = useState(false)
  const [passwordSelected, setPasswordSelected] = useState(null)


  const toggleModalNFC = useCallback(() => {
    setopenNfc(state => !state)
    setPasswordSelected(null)
  }, [])

  useEffect(() => {
    if (!establishment || !store) return;
    const onSubscriber = new NfcsAccessDB(establishment.id, store.id).on(setnfcs)
    return onSubscriber
  }, [establishment, store])

  const validate = useCallback((values) => {
    const errors = {};

    if (!values.password) {
      errors.password = 'Este campo é necessário';
    } else if (values.password.length < 6) {
      errors.password = 'Mínimo de 6 caracteres';
    }

    return errors
  }, [])

  const formik = useFormik({
    initialValues: {
      password: ''
    },
    validate,
    onSubmit: values => {
      const { password } = values;
      const data = {
        estabId: establishment?.id,
        storeId: store?.id,
        password
      }
      const onSuccess = () => {
        onShowAlert(PreAlert.success('Senha cadastrada com sucesso'))
      }
      const onError = (error) => {
        console.error(error);
        onShowAlert(PreAlert.error('Falhou ao cadastrar a senha'))
      }
      setloading(true)
      api.post('routes/createPasswordTotem', data)
        .then(onSuccess)
        .catch(onError)
        .finally(() => setloading(false))
    }
  })


  const handleOpenModalEdit = useCallback((item) => {
    setPasswordSelected(item);
    setopenNfc(true)
  }, [])

  const handleOpenModalDelete = useCallback((id) => {
    onShowQuestion({
      title: 'Exclusão de Totem',
      message: 'Você está excluindo um Totem. Está ação é irreversível. Deseja continuar?',
      onConfirm: () => {
        new NfcsAccessDB(establishment.id, store.id)
          .delete(id)
          .then(res => showNotification('', notificationBody('Registro deletado com sucesso'), 'success'))
          .catch(err => showNotification('', notificationBody('Falhou ao deletar o registro'), 'danger'))
      }
    })
  }, [establishment, store])

  const columns = useMemo(() => ([
    {
      label: 'Nome',
      field: 'userId',
      format: row => {
        const displayName = usersDicionary !== null ? usersDicionary[row.userId]?.displayName : '';
        if (displayName) {
          return displayName.length > 22 ? displayName.substring(0, 20) + '...' : displayName;
        } else {

          return 'Não informado';
        }
      }
    },
    {
      label: 'NFC',
      field: 'nfc'
    },
    {
      label: 'Ações',
      field: 'actions',
      format: row => (
        <div className='w-50 d-flex justify-content-start gap-3'>
            <Button
            color="light"
            icon="edit"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleOpenModalEdit(row)}
          />
          <Button
            color="danger"
            icon="Trash"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleOpenModalDelete(row.id)}
          />
        </div>
      ),
      noExport: true
    },
  ]), [handleOpenModalDelete,usersDicionary])

  return (
    <PageWrapper title={demoPages.store.subMenu.accessPassword.text}>
      <SubHeader>
        <SubHeaderLeft>
          <Breadcrumb
            list={[
              { title: 'Loja', to: '/store/accessPasswrod' },
              { title: 'Senha de Acesso', to: '/store/accessPasswrod' },
            ]}
          />
        </SubHeaderLeft>
      </SubHeader>
      <Page container='fluid'>
        <div className='row d-flex align-items-center mt-4 mx-1 mb-4 ' >
          <SelectEstablishment />
          <SelectStore />
        </div>

        <div className=" gap-5  p-4">
          <form id="reg-password" className="col-md-12 mb-4" noValidate onSubmit={formik.handleSubmit}>
            <Card >
              <CardHeader>
                <CardLabel className='gap-2' icon='Tv'>
                  <CardTitle>Acesso aos totems e pos</CardTitle>
                  <CardSubTitle>Cadastre uma senha para acessar a área administrativa dos totems e pos</CardSubTitle>
                </CardLabel>
              </CardHeader>
              <CardBody className='p-5 mb-4'>
                <FormGroup id='password' label='Senha' className='col-md-4 mb-4'>
                  <Input
                    type='password'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    isValid={formik.isValid}
                    isTouched={formik.touched.password}
                    invalidFeedback={formik.errors.password}
                    validFeedback='Assim está bom!'
                    placeholder='Informe uma senha'
                  />
                </FormGroup>

                <div className='d-flex justify-content-end gap-2'>
                  <Button
                    type='submit'
                    color={loading ? 'success' : 'primary'}
                    icon={loading ? '' : 'Save'}
                    shadow="sm"
                    hoverShadow="sm"
                    isDisable={loading}
                  >
                    {loading ? (
                      <>
                        <Spinner
                          color="light"
                          inButton
                          isGrow
                          isSmall
                          size={10}
                          tag="span"
                        />
                        Carregando...
                      </>
                    ) : (
                      'Salvar'
                    )}
                  </Button>
                </div>
              </CardBody>
            </Card>
          </form>

          <Card className="col-md-12">
            <CardHeader>
              <CardLabel className='gap-2' icon='SdCard'>
                <CardTitle>Acesso com NFC</CardTitle>
                <CardSubTitle>Cadastre os nfcs que terão acesso a área administrativa das pos</CardSubTitle>
              </CardLabel>
            </CardHeader>
            <CardBody>
              <div className='text-end'>
                <Button
                  color='primary'
                  icon='plus'
                  shadow="sm"
                  hoverShadow="sm"
                  onClick={toggleModalNFC}
                >
                  Cadastrar
                </Button>
              </div>

              <TableCustom
                rows={nfcs}
                columns={columns}
                noShowHeader
                keyExtractor={item => item.id}
              />
            </CardBody>
          </Card>
        </div>
      </Page>
      <ModalRegisterNFC
        open={openNfc}
        setIsOpen={toggleModalNFC}
        setIsClose={toggleModalNFC}
        passwordSelected={passwordSelected}
       
      />
    </PageWrapper>
  );
};

export default AccessPassword;
