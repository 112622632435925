import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useFormik } from 'formik';
import Button from '../../bootstrap/Button';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import Label from '../../bootstrap/forms/Label';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import Textarea from '../../bootstrap/forms/Textarea';
import Select from '../../bootstrap/forms/Select';
import BtnLoad from '../../MyCustom/BtnLoad'
// Database
import { DefaultContext } from '../../../contexts/default';
import PreAlert from '../../../helpers/utils/preAlert';
import { SUGGESTION_LABEL, SUGGESTION_TYPE } from '../../../types/suggestions';
import SuggestionDB from '../../../database/wrappers/suggestion';
import ProductDB from '../../../database/wrappers/product';
import { where } from 'firebase/firestore';
import SubCategoryDB from '../../../database/wrappers/subCategory';
import CategoryDB from '../../../database/wrappers/category';
import ListCategories from '../Menus/ListCategories';
import ListProducts from '../../MyCustom/ListProducts';
import UserHistory from '../../../database/wrappers/userHistory';

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Este campo é necessário';
  }

  if (!values.type) {
    errors.type = 'Este campo é necessário';
  }

  if (!values.active) {
    errors.active = 'Você precisa escolher o status do complemento';
  }

  return errors;

}

const ModalRegisterSuggestion = ({ open, setIsOpen, setIsClose, suggestionSelected }) => {

  const { establishment, onShowAlert, user } = useContext(DefaultContext)

  const [isLoading, setIsLoading] = useState(false);
  const [products, setproducts] = useState([]);
  const [categories, setcategories] = useState([]);
  const [subCategories, setsubCategories] = useState([]);
  const [showCategoryItems, setshowCategoryItems] = useState(false)

  useEffect(() => {
    if(!open) return formik.resetForm();
    if (suggestionSelected) {
      const { name, active, description, type, elements } = suggestionSelected;

      formik.setValues({
        name,
        active: active ? 'ativo' : 'inativo',
        description,
        type: String(type),
        products: type === SUGGESTION_TYPE.PRODUCT ? elements : [],
        categories: type === SUGGESTION_TYPE.CATEGORY ? elements : [],
      });
    }
  }, [suggestionSelected, open])

  // Pega os complementos
  useEffect(async () => {
    if (establishment) {
      function sort(a, b) {
        if(a.name > b.name) return 1;
        if(a.name < b.name) return -1;
        return 0;
      }
      async function getProducts() {
        const datas = await new ProductDB(establishment.id).getAll(where('active', '==', true))
        datas.sort(sort);
        setproducts(datas);
      }
      async function getSubCategories() {
        const datas = await new SubCategoryDB(establishment.id).getAll(where('active', '==', true))
        datas.sort(sort);
        setsubCategories(datas);
      }
      async function getCategories() {
        const datas = await new CategoryDB(establishment.id).getAll(where('active', '==', true))
        datas.sort(sort);
        setcategories(datas);
      }

      getProducts();
      getCategories();
      getSubCategories();
    }
  }, [establishment])

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      type: '',
      active: '',
      products: [],
      categories: [],
    },
    validate,
    onSubmit: values => {
      const { name, description, type, active, products, categories } = values;

      const data = {
        name,
        active: active === 'ativo',
        description,
        type: Number(type),
        elements: Number(type) === SUGGESTION_TYPE.PRODUCT ? products : categories,
      }

      const onSuccess = () => {
        onShowAlert(PreAlert.success('Registro cadastrado com sucesso'))
        setIsClose();
      }
      const onSuccessUpdate = () => {
        onShowAlert(PreAlert.success('Registro atualizado com sucesso'))
        setIsClose();
      }
      const onError = () => {
        onShowAlert(PreAlert.error('Falhou ao cadastrar o registro'))
      }

      setIsLoading(true);
      if (suggestionSelected) {
        new SuggestionDB(establishment.id)
          .update(suggestionSelected.id, data)
          .then(() => {
            onSuccessUpdate();
            new UserHistory(establishment.id, user, "a Sugestão")
            .update(suggestionSelected.id, { data, selected: suggestionSelected })
            .finally(() => { })
          })
          .catch(onError)
          .finally(() => setIsLoading(false))
      } else {
        new SuggestionDB(establishment.id)
          .create(data)
          .then(() => {
            onSuccess();
            new UserHistory(establishment.id, user, "a Sugestão")
            .create(data)
            .finally(() => { })
          })
          .catch(onError)
          .finally(() => setIsLoading(false))
      }
    },
  })

  const optionsSuggestion = useMemo(() => Object.keys(SUGGESTION_TYPE).map(key => ({ value: SUGGESTION_TYPE[key], text: SUGGESTION_LABEL[SUGGESTION_TYPE[key]] })), [])

  return (
    <Modal
      id={'modal-register-category-complement'}
      titleId={'Cadastro de Categoria de Complemento'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={true}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
      onSubmit={formik.handleSubmit}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="register-category-complement">{suggestionSelected ? 'Atualização de Cadastro de Sugestão' : 'Cadastro de Sugestão'}</ModalTitle>
      </ModalHeader>
      <ModalBody className='p-5' style={showCategoryItems ? { opacity: 0.1, backgroundColor: 'black' } : {}}>
        <form noValidate onSubmit={formik.handleSubmit}>
          {/* Inputs */}
          <div className="row pb-2 d-flex">
            {/* Nome */}
            <FormGroup id="name" label="Nome da Categoria" className='col-md-12 mb-4'>
              <Input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                isValid={formik.isValid}
                isTouched={formik.touched.name}
                invalidFeedback={formik.errors.name}
                validFeedback='Assim está bom!'
                placeholder='Eletrodomésticos, lâmpadas, etc...'
              />
            </FormGroup>

            {/* Tipo */}
            <FormGroup id="type" label="Seleção por item" className='col-md-6 mb-4'>
              <Select
                placeholder='Selecione o tipo'
                list={optionsSuggestion}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.type}
                isValid={formik.isValid}
                isTouched={formik.touched.type}
                invalidFeedback={formik.errors.type}
              />
            </FormGroup>
            {/* Status */}
            <FormGroup className='col-md-6'>
              <Label>Status</Label>
              <ChecksGroup
                isValid={formik.isValid}
                isTouched={formik.touched.active}
                invalidFeedback={formik.errors.active}
              >
                <Checks
                  type="radio"
                  id="active"
                  label="Ativo"
                  name="active"
                  value="ativo"
                  onChange={formik.handleChange}
                  checked={formik.values.active}
                  isInline
                />
                <Checks
                  type="radio"
                  id="active2"
                  label="Inativo"
                  name="active"
                  value="inativo"
                  onChange={formik.handleChange}
                  checked={formik.values.active}
                  isInline
                />
              </ChecksGroup>
            </FormGroup>

            {/* Descrição */}
            <FormGroup id="description" label="Descrição" className='col-md-12'>
              <Textarea
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
                isValid={formik.isValid}
                isTouched={formik.touched.description}
                invalidFeedback={formik.errors.description}
                validFeedback='Assim está bom!'
                placeholder='
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur illum hic omnis ipsam fugiat incidunt'
              />
            </FormGroup>

            
          </div>

          <ListCategories
            hidden={formik.values.type != SUGGESTION_TYPE.CATEGORY}
            products={products}
            categories={categories}
            subCategories={subCategories}
            categoriesSelected={formik.values.categories}
            onChange={formik.handleChange}
            onChangeShowCategory={setshowCategoryItems}
          />

          <ListProducts
            hidden={formik.values.type != SUGGESTION_TYPE.PRODUCT}
            products={products}
            productsSelected={formik.values.products}
            onChange={formik.handleChange}
          />

          {/* Buttons */}
          <div className="row pt-4">       
              <div className='d-flex justify-content-end gap-2'>
                <Button
                  color="danger"
                  icon="cancel"
                  rounded={1}
                  onClick={setIsClose}
                  shadow="sm"
                  hoverShadow="sm"
                  size="sm"
                >
                  Cancelar
                </Button>
                {isLoading ? (
                  <BtnLoad />
                ) : (
                  <Button
                    isDisable={!formik.isValid && !!formik.submitCount}
                    type='submit'
                    color='success'
                    icon="check"
                    rounded={1}
                    hoverShadow="sm"
                    shadow="sm"
                    size="sm"
                  >
                    Confirmar
                  </Button>
                )}
              </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default ModalRegisterSuggestion;