import { Firestore, QueryConstraint, Unsubscribe, doc, getDoc, getFirestore } from "firebase/firestore";
import ClientEstab from "../entities/clientEstab";
import FirestorePipe from "../utils/pipe";
import EstablishmentDB from "./establishment";

class ClientEstabDB extends FirestorePipe {

  public static readonly colName = 'Clients'
  private idEstab: string;


  constructor(idEstab: string) {
    if (!idEstab)
      throw new Error('Informe o id do estabelecimento')
    super(`${EstablishmentDB.colName}/${idEstab}/${ClientEstabDB.colName}`);
    this.idEstab = idEstab;

  }

  public create(data: ClientEstab): Promise<any> {
    return this._create(data);
  }

  public createWithCustomId(id: string, data: ClientEstab): Promise<any> {
    return this._define(id, data);
  }

  public update(id: string, data: ClientEstab): Promise<any> {
    return this._update(id, data);
  }
  public delete(id: string): Promise<any> {
    return this._delete(id);
  }
  public getAll(...params: QueryConstraint[]): Promise<ClientEstab[]> {
    return this._getAll<ClientEstab>(...params);
  }
  public get(id: string): Promise<ClientEstab> {
    return this._get(id);
  }
  public on(listener: (data: ClientEstab[]) => void, ...params: QueryConstraint[]): Unsubscribe {
    return this._on(listener, ...params);
  }

  async getById(id: string): Promise<ClientEstab | null> {
    try {
      const firestoreInstance: Firestore = getFirestore();
      const clientDocument = doc(firestoreInstance, `${EstablishmentDB.colName}/${this.idEstab}/${ClientEstabDB.colName}`, id);
      const documentSnapshot = await getDoc(clientDocument);

      if (documentSnapshot.exists()) {
        const data = documentSnapshot.data();
        return { id, ...data } as ClientEstab;
      }

      return null;
    } catch (e) {
      console.error('Ocorreu um erro ao buscar o cliente:', e);
      return null;
    }
  }

}

export default ClientEstabDB;