import React from "react";
import Button from "../../bootstrap/Button";

export default function BtnConfirm({ className, isDisable, label }) {
  return (
    <Button
      className={className}
      isDisable={isDisable}
      type='submit'
      color='primary'
      icon="save"
      rounded={1}
      hoverShadow="sm"
      shadow="sm"
      size="sm"
    >
      {label || 'Confirmar'}
    </Button>
  );
}