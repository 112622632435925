import { memo, useEffect, useMemo, useState } from "react"
import Money from "../../../../helpers/utils/money/money";
import Button from "../../../bootstrap/Button";
import FormGroup from "../../../bootstrap/forms/FormGroup";
import Input from "../../../bootstrap/forms/Input";
import Modal, { ModalBody, ModalHeader, ModalTitle } from "../../../bootstrap/Modal";

function ProductItem({product}) {

  return(
    <div className='row g-4 py-2'>
      <div className='col-md-1'>
        <img
          src={product.image_url}
          alt='Imagem'
          width={40}
          height={40}
        />
      </div>
      <div className='col-md-9'>
        <FormGroup>
          <Input value={product.name} disabled readOnly />
        </FormGroup>
      </div>
      <div className='col-md-2'>
        <FormGroup>
          <Input
            value={Money.centsToCoin(product.price).toFixed(2)}
            readOnly
          />
        </FormGroup>
      </div>
      {/* <div className='col-md-2'>
        <FormGroup>
          <Input
            value={Money.centsToCoin(product.discount).toFixed(2)}
            readOnly
          />
        </FormGroup>
      </div> */}
    </div>
  );
}

const ProductElement = memo(ProductItem);

function ListSubCategoryItemsModal({ open, onClose, products, category }) {

  const [productsRender, setproductsRender] = useState([])

  useEffect(() => {
    if(category) {
      const productsInCategory = category.products;

      const productsRender = [];
      productsInCategory.forEach(productId => {
        const pFind = products.find(p => p.id === productId);
        if(pFind) {
          productsRender.push({
            name: pFind.name,
            image_url: pFind.image_url,
            price: pFind.price,
            // discount: pFind.discount,
          })
        }
      })

      setproductsRender(productsRender)
    }
  },[products, category])

  const Header = useMemo(() => (
    <div className='row g-4 py-2'>
      <div className='col-md-1'>
        <p>Imagem</p>
      </div>
      <div className='col-md-9'>
        <p>Nome</p>
      </div>
      <div className='col-md-2'>
        <p>Preço</p>
      </div>
      {/* <div className='col-md-2'>
        <p>Desconto</p>
      </div> */}
    </div>
  ), [])

  return(
    <Modal
      id={'modal-category-items'}
      titleId={'Items da Sub-categoria'}
      isOpen={open}
      setIsOpen={onClose}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
    >
      <ModalHeader setIsOpen={onClose}>
        <ModalTitle id="register-category">{category?.name}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        { Header }

        {productsRender.map((product, index) => (
          <ProductElement
            key={'lpmInSubCat' + index}
            product={product}
          />
        ))}

        {/* Buttons */}
        <div className="d-flex flex-row-reverse" style={{ marginTop: 10 }}>
          <Button
            color="danger"
            icon="cancel"
            rounded={1}
            onClick={onClose}
            shadow="sm"
            hoverShadow="sm"
            size="sm"
          >
            Fechar
          </Button>
      </div>
      </ModalBody>
    </Modal>
  );
}

export default memo(ListSubCategoryItemsModal);