import { QueryConstraint, Unsubscribe } from "firebase/firestore";
import StoreProduct from "../entities/stores/product";
import FirestorePipe from "../utils/pipe";
import EstablishmentDB from "./establishment";
import StoreDB from "./store";

class StoreProductDB extends FirestorePipe {

  public static readonly colName = 'Products'

  constructor(idEstab: string, idStore: string) {
    if(!idEstab)
      throw new Error('Informe o id do estabelecimento')
    super(`${EstablishmentDB.colName}/${idEstab}/${StoreDB.colName}/${idStore}/${StoreProductDB.colName}`);
  }

  public define(id: string, data: StoreProduct): Promise<any> {
    return this._define(id, data);
  }
  public create(data: StoreProduct): Promise<any> {
    return this._create(data);
  }
  public update(id: string, data: StoreProduct): Promise<any> {
    return this._update(id, data);
  }
  public delete(id: string): Promise<any> {
    return this._delete(id);
  }
  public getAll(...params: QueryConstraint[]): Promise<StoreProduct[]> {
    return this._getAll<StoreProduct>(...params);
  }
  public get(id: string): Promise<StoreProduct> {
    return this._get(id);
  }
  public on(listener: (data: StoreProduct[]) => void, ...params: QueryConstraint[]): Unsubscribe {
    return this._on(listener, ...params);
  }

}

export default StoreProductDB;