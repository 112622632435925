import React, { memo, useCallback, useState, useContext ,useEffect} from 'react';
import Money from '../../../../helpers/utils/money/money';
import Checks, { ChecksGroup } from '../../../bootstrap/forms/Checks';
import FormGroup from '../../../bootstrap/forms/FormGroup';
import Input from '../../../bootstrap/forms/Input';
import Button from '../../../bootstrap/Button';
import ModalImageComplements from './ModalImageComplements';
import { DefaultContext } from '../../../../contexts/default';
import SettingDB from '../../../../database/wrappers/settings';

function ComponentItem({ complement, onChange, checked, categoryComplementSelected }) {
  const { settings } = useContext(DefaultContext)
  const [showComplementModal, setshowComplementModal] = useState(false)
  const [complementSelected, setComplementSelected] = useState(null);

  const handleOpenComplementModal = useCallback((item) => {
    setComplementSelected(item);
    setshowComplementModal(true);
  }, [])

  const handleCloseComplementModal = useCallback(() => {
    setComplementSelected(null);
    setshowComplementModal(false);
  }, [])


  return (
    <div className='row g-4 py-2'>
      <div className='col-md-1'>
        <div className='h-100 d-flex align-items-center justify-content-center'>
          <ChecksGroup>
            <Checks
              type='switch'
              id='complements'
              name='complements'
              value={complement.id}
              onChange={onChange}
              checked={checked}
              isInline
            />
          </ChecksGroup>
        </div>
      </div>
      <div className='col-md-1'>
        <img
          src={complement.image_url}
          alt='Imagem'
          width={40}
          height={40}
        />
      </div>

      <div className='d-flex flex-row col-md-7 w-full'>
        {settings?.ecommerce &&
          <FormGroup > 
            <Input value={complement.secondName} disabled readOnly />
          </FormGroup>
        }
        <FormGroup className={settings?.ecommerce ? "" : "col-md-12" }>
          <Input value={complement.name} disabled readOnly />
        </FormGroup>
      </div>
      <div className='col-md-2'>
        <FormGroup>
          <Input
            value={Money.centsToMaskMoney(complement.price)}
            disabled
            readOnly
          />
        </FormGroup>
      </div>
      {settings?.ecommerce &&
        <Button
          color="light"
          icon="wallpaper"
          shadow="sm"
          hoverShadow="sm"
          size="md"
          onClick={() => handleOpenComplementModal(complement)}
        />
      }
      <ModalImageComplements
        open={showComplementModal}
        setIsOpen={handleOpenComplementModal}
        setIsClose={handleCloseComplementModal}
        complement={complementSelected}
        categoryComplementSelected={categoryComplementSelected}


      />
    </div>

  );
}

const ComponentElement = memo(ComponentItem);

function ListComponents({ complements, complementsSelected, onChange, categoryComplementSelected,settings }) {
  if (!complementsSelected) return null;
  return (


    <div style={{ maxHeight: 300 }} className='overflow-y ps-2'>
      {complements.map((complement, index) => {
        return (
          <ComponentElement
            key={'ch_comp' + index}
            complement={complement}
            onChange={onChange}
            checked={complementsSelected.includes(complement.id)}
            categoryComplementSelected={categoryComplementSelected}
            settings={settings}

          />
        );
      })}
    </div>

  );
}

export default memo(ListComponents);
