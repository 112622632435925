import dateFormat from 'dateformat';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import TableCustom from '../../../../components/MyCustom/TableCustom';
import FilterReports from '../../../../components/MyCustom/filterReports';
import Breadcrumb from '../../../../components/bootstrap/Breadcrumb';
import Button from '../../../../components/bootstrap/Button';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../../../components/bootstrap/Card';
import Popovers from '../../../../components/bootstrap/Popovers';
import Spinner from '../../../../components/bootstrap/Spinner';
import ModalFixFiscalReports from '../../../../components/modals/FixFiscalReports';
import { DefaultContext } from '../../../../contexts/default';
//import TotenNotesDB from '../../../../database/wrappers/totemNotes';
import StoreDB from '../../../../database/wrappers/store';
import Money from '../../../../helpers/utils/money/money';
import useFiscalUnissued from '../../../../hooks/reports/useFiscalUnissued';
import Page from '../../../../layout/Page/Page';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../../layout/SubHeader/SubHeader';
import { getAuthToken } from '../../../../services/apiFiscal';
import { DEVICE } from '../../../../types/device';
import { PAYMENT_TYPE_PTBR } from '../../../../types/payment';
import TotenDB from '../../../../database/wrappers/toten';
//import ModalLogs from '../../../../components/modals/LogsModal/ModalLogs';

const UnissuedNotes = () => {
  const { usersDicionary,stores } = useContext(DefaultContext);
  const [filterMenu, setFilterMenu] = useState(false);
  const [dataFilter, setDataFilter] = useState({})
  const [paymentSelected, setPaymentSelected] = useState(null);
  const [openModalHistory, setOpenModalHistory] = useState(false);
  const [openFiscal, setOpenFiscal] = useState(false);
  const [storesDicionary, setstoresDicionary] = useState(null);
  const [totemsNotesDicionary, settotemsNotesDicionary] = useState(null);
  const { rows, loading } = useFiscalUnissued(dataFilter)
  const handleOpenHistory = useCallback((row) => {
    setOpenModalHistory(true)
    setPaymentSelected(row)
  }, [])


  const handleOpenFiscal = useCallback((row) => {
    setOpenFiscal(true)
    setPaymentSelected(row)
  }, [])

  const handleCloseFiscal = useCallback((row) => {
    setOpenFiscal(false)
    setPaymentSelected(null)
  }, [])

  useEffect(() => {
    if(dataFilter.store){ 
      const store = stores.find((s) => s.id === dataFilter.store);
      if(store) getAuthToken(store?.cnpj, store.fiscal_data?.password)
    }
  },[dataFilter.store, stores])

  useEffect(() => {
    if (dataFilter.estab) {
      const loadStores = () => {
        new TotenDB(dataFilter.estab)
          .getAll()
          .then(totens => {
            const totensDicionary = {};
            totens.forEach(store => totensDicionary[store.id] = store)
            settotemsNotesDicionary(totensDicionary)
          })
          .catch(error => console.error(error))
      }

      loadStores();
    }
  }, [dataFilter.estab])

  useEffect(() => {
    if (dataFilter.estab) {
      const loadStores = () => {
        new StoreDB(dataFilter.estab)
          .getAll()
          .then(stores => {
            const storesDicionary = {};
            stores.forEach(store => storesDicionary[store.id] = store)
            setstoresDicionary(storesDicionary)
          })
          .catch(error => console.error(error))
      }

      loadStores();
    }
  }, [dataFilter.estab])



  const columns = useMemo(() => ([
    {
      label: 'Data / Hora',
      field: 'created_at',
      format: row => dateFormat(row.created_at.toDate(), 'dd/mm/yyyy HH:MM'),
      formatExport: value => dateFormat(value.toDate(), 'dd/mm/yyyy HH:MM'),
    },
    {
      label: 'Loja',
      field: 'storeId',
      format: row => storesDicionary ? storesDicionary[row?.storeId]?.name : 'Indefinido',
      formatExport: value => storesDicionary ? storesDicionary[value]?.name : 'Indefinido'
    },
    {
      label: 'Nome do operador',
      field: 'operador',
      format: (row) => {
        if (!row) return 'Indefinido';
        const { totemId, userId } = row;
        const totemNotesName = totemId && totemsNotesDicionary ? totemsNotesDicionary[totemId]?.name : null;
        const userName = usersDicionary && userId ? usersDicionary[userId]?.displayName : null;

        return totemNotesName || userName || 'Indefinido';
      },
    },
    {
      label: 'Forma Pag.',
      field: 'payment_type',
      format: row => PAYMENT_TYPE_PTBR[row.payment_type],
      formatExport: row => PAYMENT_TYPE_PTBR[row.payment_type]
    },
    // {
    //   label: 'Taxa de Serviço',
    //   field: 'valueTaxService',
    //   format: row => Money.centsToMaskMoney(row.valueTaxService),
    //   formatExport: value => Money.centsToCoinCsv(value),
    // },
    // {
    //   label: 'Desconto',
    //   field: 'valueDiscount',
    //   format: row => Money.centsToMaskMoney(row.valueDiscount),
    //   formatExport: value => Money.centsToCoinCsv(value),
    // },
    {
      label: 'Valor Pag.',
      field: 'value',
      format: row => Money.centsToMaskMoney(row.value),
      formatExport: value => Money.centsToCoinCsv(value),
    },
    // {
    //   label: '#',
    //   field: '{row}',
    //   noExport: true,
    //   format: row => {
    //     return (
    //       <Popovers
    //         trigger="hover"
    //         title="Ver detalhes"
    //       >
    //         <Button color='dark' icon='Visibility' onClick={() => handleOpenHistory(row)} />
    //       </Popovers>

    //     )
    //   }
    // },
    {
      label: 'Fiscal',
      field: '{row}',
      format: (row) => (
        <Popovers trigger="hover" title="Corrigir fiscal">

          <Button color='warning' icon='VisibilityOff' onClick={() => handleOpenFiscal(row)} />

        </Popovers>
      ),
      formatExport: row => row.fiscal && row.fiscal.issued ? 'Emitido' : 'Não emitido',
    },

  ]), [handleOpenHistory, storesDicionary, totemsNotesDicionary, usersDicionary])



  return (
    <>
      <PageWrapper >
        <SubHeader>
          <SubHeaderLeft>
            <Breadcrumb
              list={[
                { title: 'Relatórios', to: '/registers/establishment' },
                {
                  title: 'Notas não emitidas',
                  to: '/registers/establishment',
                },
              ]}
            />
          </SubHeaderLeft>
          <SubHeaderRight>
            <FilterReports
              filterMenu={filterMenu}
              setFilterMenu={setFilterMenu}
              setDataFilter={setDataFilter}
            />
          </SubHeaderRight>
        </SubHeader>
        <Page container='fluid' className='h-100'>
          {loading ?
            <div className='d-flex flex-column justify-content-center align-items-center h-100'>
              <Spinner size={50} />
            </div>
            :
            <Card>
              <CardHeader borderSize={1}>
                <CardLabel icon='InsertDriveFile' iconColor='dark'>
                  <CardTitle>NOTAS NÃO EMITIDAS</CardTitle>
                </CardLabel>
              </CardHeader>

              <CardBody className='table-responsive p-5'>
                <TableCustom

                  columns={columns}
                  rows={rows}
                />
              </CardBody>

            </Card>
          }
        </Page>

        <ModalFixFiscalReports
          open={openFiscal}
          setIsOpen={handleOpenFiscal}
          setIsClose={handleCloseFiscal}
          paymentSelected={paymentSelected}
          estabId={dataFilter.estab}
          storeId={dataFilter.store}
        />

      </PageWrapper>
    </>
  );
};

export default UnissuedNotes;