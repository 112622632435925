import Coupon from "../entities/stores/coupon.entity";
import FirestorePipe from "../utils/pipe";
import EstablishmentDB from "./establishment";
import StoreDB from "./store";
import { QueryConstraint, Unsubscribe } from "firebase/firestore";


class CouponDB extends FirestorePipe {

    public static readonly colName = 'Coupons'

    constructor(idEstab: string, idStore: string) {
        if (!idEstab || !idStore)
            throw new Error('Informe o id do estabelecimento e loja')
        super(`${EstablishmentDB.colName}/${idEstab}/${StoreDB.colName}/${idStore}/${CouponDB.colName}`);
    }

    public create(data: Coupon): Promise<any> {
        return this._create(data);
    }
    public update(id: string, data: Coupon): Promise<any> {
        return this._update(id, data);
    }
    public delete(id: string): Promise<any> {
        return this._delete(id);
    }
    public getAll(...params: QueryConstraint[]): Promise<Coupon[]> {
        return this._getAll<Coupon>(...params);
    }
    public get(id: string): Promise<Coupon> {
        return this._get(id);
    }
    public on(listener: (data: Coupon[]) => void, ...params: QueryConstraint[]): Unsubscribe {
        return this._on(listener, ...params);
    }
    public onDicionary(listener: (data: any) => void, ...params: QueryConstraint[]): Unsubscribe {
        return this._onDicionary(listener, ...params);
    }

}

export default CouponDB;