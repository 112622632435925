import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({ width, black }) => {
	return (
		<img
			src={black ? require('../assets/img/logo_uget_black.png') : require('../assets/img/logo_uget.png')}
			alt=" imagem da uget "
			width={width}
			// height={width / 2}
		/>
	);
};
Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
Logo.defaultProps = {
	width: 2155,
	height: 854,
};

export default Logo;
