import React, { memo, useCallback, useMemo, useState } from 'react';
import Checks, { ChecksGroup } from '../../../bootstrap/forms/Checks';
import FormGroup from '../../../bootstrap/forms/FormGroup';
import Input from '../../../bootstrap/forms/Input';
import Icon from '../../../icon/Icon';
import ListSubCategoryItemsModal from '../ListSubCategoryItems'

function SubCategoryItem({category, onChange, checked, showSubCategory}) {
  
  const onShowSubCategory = useCallback(() => showSubCategory(category),[])

  return(
    <div className='row g-4 py-2'>
      <div className='col-md-1 d-flex align-items-center justify-content-center'>
        <ChecksGroup>
          <Checks
            type='switch'
            id='subCategories'
            name='subCategories'
            value={category.id}
            onChange={onChange}
            checked={checked}
            isInline
          />
        </ChecksGroup>
      </div>
      <div className='col-md-1'>
        <img
          src={category.image_url}
          alt='Imagem'
          width={40}
          height={40}
        />
      </div>
      <div className='col-md-9'>
        <FormGroup>
          <Input value={category.name} disabled readOnly />
        </FormGroup>
      </div>
      <div className='col-md-1'>
        <a href='#' onClick={onShowSubCategory}>
          <Icon
            style={{ marginTop: 5 }}
            icon='RemoveRedEye'
            size='2x'// null || 'sm' || 'md' || 'lg' || '2x' || '3x' || '4x' || '5x' || '6x' || '7x' || '8x' || '9x' || '10x'
          />
        </a>
      </div>
    </div>
  );
}

const SubCategoryElement = memo(SubCategoryItem);

function ListSubCategories({ hidden, products, categories, categoriesSelected, onChange, onChangeShowCategory }) {

  const [categoryModal, setcategoryModal] = useState(false)
  const [categoryShow, setcategoryShow] = useState(null)

  const Header = useMemo(() => (
    <div className='row g-4 py-2'>
      <div className='col-md-1'>
        <p>#</p>
      </div>
      <div className='col-md-1'>
        <p>Imagem</p>
      </div>
      <div className='col-md-10'>
        <p>Nome</p>
      </div>
    </div>
  ), [])

  const onShowSubCategory = useCallback((category) => {
    setcategoryShow(category)
    onChangeShowCategory(true)
    setcategoryModal(true)
  },[])

  const onCloseSubCategory = useCallback(() => {
    onChangeShowCategory(false)
    setcategoryModal(false)
  },[])

  if(!categoriesSelected) return null;
	return (
    <div hidden={hidden}>
      { Header }

      <div 
        style={{ maxHeight: 400 }} 
        className='overflow-y'
      >
        {categories.map((category, index) => {
          return (
            <SubCategoryElement
              key={'ch_comp'+index}
              category={category}
              onChange={onChange}
              checked={categoriesSelected.includes(category.id)}
              showSubCategory={onShowSubCategory}
            />
          );
        })}
      </div>

      <ListSubCategoryItemsModal
        open={categoryModal}
        onClose={onCloseSubCategory}
        products={products}
        category={categoryShow}
      />
    </div>
	);
}

export default memo(ListSubCategories);
