import { QueryConstraint, Unsubscribe } from "firebase/firestore";
import Sales from "../entities/sales/sale.entity";
import FirestorePipe from "../utils/pipe";
import EstablishmentDB from "./establishment";

class SalesDB extends FirestorePipe {

  public static readonly colName = 'Sales'

  constructor(idEstab: string) {
    if(!idEstab)
      throw new Error('Informe o id do estabelecimento')
    super(`${EstablishmentDB.colName}/${idEstab}/${SalesDB.colName}`);
  }

  public async create(data: any): Promise<any> {
    
  }
  public async update(id: string, data: Sales): Promise<any> {
    return this._update(id, data);
  }
  public async delete(id: string): Promise<any> {
    
  }
  public getAll(...params: QueryConstraint[]): Promise<Sales[]> {
    return this._getAll<Sales>(...params);
  }
  public get(id: string): Promise<Sales> {
    return this._get(id);
  }
  public on(listener: (data: Sales[]) => void, ...params: QueryConstraint[]): Unsubscribe {
    return this._on(listener, ...params);
  }
}

export default SalesDB;