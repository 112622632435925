import React, { memo } from "react";
import useDarkMode from "../../../../hooks/useDarkMode";
import { COLORS_DARK, COLORS_LIGHT } from "../../../../types/dashColors";
import Chart from "../../../extras/Chart";

const FeedBackDash = ({ labels, series, colors }) => {
  const { darkModeStatus } = useDarkMode()
  const displaySeries = series && series.length > 0 ? series : Array(labels.length).fill(0);
  return (
    <Chart
      className='pb-3'
      style={{ color: '#333' }}
      height={window.innerWidth < 550 ? 250 : 380}
      options={{
        chart: {
          type: 'donut',
          width: 380,
        },
        stroke: {
          width: 0,
        },
        labels: labels || [],
        dataLabels: {
          enabled: true,
        },
        legend: {
          show: true,
          position: 'bottom', 
          horizontalAlign: 'center', 
        },
        // Este tema coloca as cores dos gráficos como padrão de escuro para claro
        // theme: {
        //   monochrome: {
        //     enabled: true,
        //     color: '#333',
        //     shadeTo: 'light',
        //     shadeIntensity: 0.65
        //   }
        // },
        colors: colors ? colors
          : [
            (darkModeStatus ? COLORS_DARK : COLORS_LIGHT).PRIMARY,
            (darkModeStatus ? COLORS_DARK : COLORS_LIGHT).SECONDARY,
            (darkModeStatus ? COLORS_DARK : COLORS_LIGHT).SUCCESS,
            (darkModeStatus ? COLORS_DARK : COLORS_LIGHT).INFO,
            (darkModeStatus ? COLORS_DARK : COLORS_LIGHT).WARNING,
            (darkModeStatus ? COLORS_DARK : COLORS_LIGHT).DANGER,
          ]
      }}
      series={displaySeries}
      type="donut"
    />
  );
}

export default memo(FeedBackDash);