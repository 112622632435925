import React, { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../../layout/SubHeader/SubHeader';
import Page from '../../../../layout/Page/Page';
import Breadcrumb from '../../../../components/bootstrap/Breadcrumb';
import { demoPages } from '../../../../menu';
import Card, { CardBody } from '../../../../components/bootstrap/Card';
import ModalRegisterTotens from '../../../../components/modals/Totens/ModalRegister';
import Button from '../../../../components/bootstrap/Button';
import { DefaultContext } from '../../../../contexts/default';
// Database
import TotenDB from '../../../../database/wrappers/toten';
import SelectEstablishment from '../../../../components/MyCustom/SelectEstablishment';
import TableCustom from '../../../../components/MyCustom/TableCustom';
import showNotification from '../../../../components/extras/showNotification';
import notificationBody from '../../../../helpers/utils/functions/notificationBody';
import app from '../../../../database/config';
import dateFormat from 'dateformat'
import { orderBy, where } from 'firebase/firestore';
import { getDatabase, ref, onValue } from "firebase/database";
import { ROLE } from '../../../../types/roles';
import Icon from '../../../../components/icon/Icon';

const Totens = () => {
  const { user, establishment, onShowQuestion, onShowAlert, stores, menus } = useContext(DefaultContext);

  const [openModalRegister, setOpenModalRegister] = useState(false);
  const [totensRender, setTotensRender] = useState([])
  const [totens, setTotens] = useState([])
  const [totensOnline, setTotensOnline] = useState([])
  const [totenSelected, setTotenSelected] = useState({})

  useEffect(() => {
    if (!establishment) return;
    const reference = ref(getDatabase(app), `Establishments/${establishment.id}/Totens`)
    const onSubscribe2 = onValue(reference, snapshot => {
      const totensOnline = [];
      if (snapshot.hasChildren()) {
        snapshot.forEach(item => {
          const data = item.val()
          totensOnline.push({
            id: item.key,
            online: data.online,
            last_changed: data.last_changed
          })
        })
      }
      setTotensOnline(totensOnline)
    })

    const wheres = user.role === ROLE.COORDINATOR ? [where('storeId', 'in', user.stores)] : [];
    const onSubscribe = new TotenDB(establishment.id).on(totems => {
      totems.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      })
      setTotens(totems)
    }, ...wheres);
    return () => {
      onSubscribe();
      onSubscribe2();
    };
  }, [establishment])

  useEffect(() => {
    totens.forEach(totem => {
      const totemFind = totensOnline.find(totemOn => totemOn.id === totem.id)
      if (totemFind) {
        totem.online = totemFind.online;
        totem.last_changed = totemFind.last_changed;
      }
    })
    setTotensRender(totens.slice())
  }, [totens, totensOnline])

  const handleOpenModalRegister = useCallback(() => {
    setTotenSelected(null)
    setOpenModalRegister(true);
  }, [])

  const handleCloseModalRegister = useCallback(() => {
    setOpenModalRegister(false);
  }, [])

  const handleOpenModalEdit = useCallback((item) => {
    setTotenSelected(item);
    setOpenModalRegister(true)
  }, [])

  const handleOpenModalDelete = useCallback((id) => {
    onShowQuestion({
      title: 'Exclusão de Totem',
      message: 'Você está excluindo um Totem. Está ação é irreversível. Deseja continuar?',
      onConfirm: () => {
        new TotenDB(establishment.id)
          .delete(id)
          .then(res => showNotification('', notificationBody('Registro deletado com sucesso'), 'success'))
          .catch(err => showNotification('', notificationBody('Falhou ao deletar o registro'), 'danger'))
      }
    })
  }, [establishment])

  const handleChangeStatus = useCallback((id, active) => {
    new TotenDB(establishment.id)
      .update(id, {
        active: !active
      })
      .catch(error => onShowAlert({
        title: 'ERRO!',
        msm: 'Não foi possível atualizar o status do produto',
        icon: 'Error',
        color: 'danger'
      }))
  }, [establishment])

  const columns = useMemo(() => ([
    {
      label: '',
      field: 'icon',
      format: row => <Icon icon="Dock" size="3x" />,
      noExport: true
    },
    {
      label: 'Nome',
      field: 'name',
    },
    {
      label: 'Loja',
      field: 'storeId',
      format: (row) => stores.find(store => store.id === row.storeId)?.name || '',
      formatExport: (value) => stores.find(store => store.id === value)?.name || '',
    },
    {
      label: 'Cardápio',
      field: 'menuId',
      format: (row) => menus.find(menu => menu.id === row.menuId)?.name || '',
      formatExport: (value) => menus.find(menu => menu.id === value)?.name || '',
    },
    {
      label: 'Key',
      field: 'key',
    },
    {
      label: 'OTP',
      field: 'otp',
    },
    {
      label: 'UUID',
      field: 'terminal_uuid',
    },
    {
      label: 'Última alteração',
      field: 'last_changed',
      format: row => typeof row.last_changed === 'number' ? dateFormat(new Date(row.last_changed), 'dd/mm/yyyy HH:MM') : ''
    },
    {
      label: 'Online',
      field: 'online',
      format: row => (
        <Button
          isLink
          color={row.online ? 'success' : 'danger'}
          icon='Circle'
          className='text-nowrap'>
          {row.online ? 'Online' : 'Offline'}
        </Button>
      )
    },
    {
      label: 'Status',
      field: 'active',
      format: row => (
        <Button
          isLink
          color={row.active ? 'success' : 'danger'}
          icon='Circle'
          onClick={() => handleChangeStatus(row.id, row.active)}
          className='text-nowrap'>
          {row.active ? 'Ativo' : 'Inativo'}
        </Button>
      )
    },
    {
      label: 'Ações',
      field: 'actions',
      format: row => (
        <div className='w-50 d-flex justify-content-between gap-2'>
          <Button
            color="light"
            icon="edit"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleOpenModalEdit(row)}
          />
          <Button
            color="danger"
            icon="Trash"
            shadow="sm"
            hoverShadow="sm"
            size="sm"
            onClick={() => handleOpenModalDelete(row.id)}
          />
        </div>
      ),
      noExport: true
    },
  ]), [stores, menus, handleChangeStatus, handleChangeStatus])

 

  return (
    <>
      <PageWrapper title={demoPages.pageLayout.subMenu.totens.text}>
        <SubHeader>
          <SubHeaderLeft>
            <Breadcrumb
              list={[
                { title: 'Cadastros', to: '/registers/totens' },
                {
                  title: 'Totem',
                  to: '/registers/totens',
                },
              ]}
            />
          </SubHeaderLeft>
          <SubHeaderRight>
            <Button
              color='primary'
              icon='plus'
              shadow="sm"
              hoverShadow="sm"
              onClick={handleOpenModalRegister}
            >
              Cadastrar
            </Button>
          </SubHeaderRight>
        </SubHeader>
        <Page container='fluid'>
          <Card>
            <CardBody className='table-responsive p-5'>
              <SelectEstablishment />

              <TableCustom
                rows={totensRender}
                columns={columns}
                fileName={'Totem'}
              />
            </CardBody>
          </Card>
        </Page>
      </PageWrapper>

      <ModalRegisterTotens
        open={openModalRegister}
        setIsOpen={setOpenModalRegister}
        setIsClose={handleCloseModalRegister}
        totenSelected={totenSelected}
      />
    </>
  );
};

export default Totens;
