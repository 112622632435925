import React, { useState, useEffect, useContext, memo, useCallback } from 'react';
import { useFormik, F } from 'formik';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import InputGroup, { InputGroupText } from '../../bootstrap/forms/InputGroup';
import Label from '../../bootstrap/forms/Label';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import showNotification from '../../extras/showNotification';
import StoreDB from '../../../database/wrappers/store';
import { Options } from '../../bootstrap/Option';
import Select from '../../bootstrap/forms/Select';
import masks from '../../../helpers/utils/masks';
import Wizard, { WizardItem } from '../../Wizard';
import apiViaCep from '../../../services/viaCep';
import Avatar from '../../MyCustom/Avatar';
import { DefaultContext } from '../../../contexts/default';
import { GeoPoint } from 'firebase/firestore';
import PreAlert from '../../../helpers/utils/preAlert';
import apiFiscal from '../../../services/apiFiscal';
import { CardBody } from '../../bootstrap/Card';

const validate = (values) => {
  const errors = {};
  if (!values.cnpj) {
    errors.cnpj = 'Este campo é necessário';
  } else if (!/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/.test(values.cnpj) || values.cnpj.length !== 14) {
    if (values.cnpj.length < 18) {
      errors.cnpj = 'O CNPJ precisa ter 14 dígitos';
    }
  }

  if (!values.name) {
    errors.name = 'Este campo é necessário';
  }

  if (!values.phone) {
    errors.phone = 'Este campo é necessário';
  } else if (!/(\d{2})(\d{5})(\d{4})/.test(values.phone) || values.phone.length !== 11) {
    if (values.phone.length < 14) {
      errors.phone = 'O telefone precisa ter 11 dígitos';
    }
  }

  if (!values.email) {
    errors.email = 'Este campo é necessário';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Email inválido';
  }

  // if (!values.latitude) {
  //   errors.latitude = 'Este campo é necessário';
  // } else if (Number(values.latitude) < -90 || Number(values.latitude) > 90) {
  //   errors.latitude = 'Por favor insira uma latitude válida';
  // }

  // if (!values.longitude) {
  //   errors.longitude = 'Este campo é necessário';
  // } else if (Number(values.longitude) < -180 || Number(values.longitude) > 180) {
  //   errors.longitude = 'Por favor insira uma longitude válida';
  // }

  if (!values.stone_code) {
    errors.stone_code = 'Este campo é necessário';
  }

  if (!values.store_id) {
    errors.store_id = 'Este campo é necessário';
  }

  if (!values.pagseguro_code) {
    errors.pagseguro_code = 'Este campo é necessário';
  }

  if (!values.code_auth) {
    errors.code_auth = 'Este campo é necessário';
  }

  // if (!values.type) {
  //   errors.type = 'Este campo é necessário';
  // }

  if (!values.zip_code) {
    errors.type = 'Este campo é necessário';
    if (values.zip_code.length < 9) {
      errors.zip_code = 'O CEP precisa ter 8 dígitos';
    }
  }

  if (!values.street) {
    errors.street = 'Este campo é necessário';
  }

  if (!values.number) {
    errors.number = 'Este campo é necessário';
  }

  if (!values.neighborhood) {
    errors.neighborhood = 'Este campo é necessário';
  }

  if (values.tax_regime === '3' || values.tax_regime === '4') {
    if (!values.pis)
      errors.pis = 'Para o regime escolhido, este campo é necessário';

    if (!values.cofins)
      errors.cofins = 'Para o regime escolhido, este campo é necessário';
  }

  return errors;

}

const ModalRegisterStores = ({ open, setIsOpen, setIsClose, storeSelected }) => {

  const { establishment, onShowAlert } = useContext(DefaultContext);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!open) return formik.resetForm();
    if (storeSelected) {
      const {
        name,
        phone,
        email,
        cnpj,
        store_id,
        stone_code,
        pagseguro_code,
        code_auth,
        type,
        logo,
        address,
        fiscal_data,

        image,
        image_url,
      } = storeSelected;

      formik.setValues({
        name,
        phone,
        email,
        cnpj,
        store_id,
        stone_code,
        pagseguro_code,
        code_auth,
        type,
        logo,

        city: address.city,
        state: address.state,
        neighborhood: address.neighborhood,
        street: address.street,
        number: address.number,
        complement: address.complement,
        zip_code: address.zip_code,

        fantasy_name: fiscal_data.fantasy_name,
        municipal_code: fiscal_data.municipal_code,
        state_code: fiscal_data.state_code,
        csc_code: fiscal_data.csc,
        surname: fiscal_data.surname,
        password: fiscal_data.password,
        type_certified: fiscal_data.type_certified,
        tax_regime: fiscal_data.tax_regime,
        certified_digital: fiscal_data.certified_digital,
        pis: fiscal_data.pis,
        cofins: fiscal_data.cofins,

        image,
        image_url,
      });
    }
  }, [storeSelected, open])

  const formik = useFormik({
    // initialValues: {
    //   name: 'Lojão do Jão',
    //   phone: '11999885577',
    //   email: 'lojaodojao@gmail.com',
    //   cnpj: '10434000152',
    //   store_id: '1',
    //   stone_code: '1',
    //   pagseguro_code: '1',
    //   code_auth: '1',
    //   type: '1',
    //   logo: '',
    //   zip_code: '39100000',
    //   city: 'Diamantina',
    //   state: 'MG',
    //   neighborhood: 'Centro',
    //   street: 'Rua dos Bobos',
    //   number: '0',
    //   complement: '',
    //   latitude: '20',
    //   longitude: '20',
    //   fantasy_name: 'Fantasia',
    //   municipal_code: '3571',
    //   state_code: '',
    //   csc_code: '',
    //   surname: '',
    //   type_certified: 'A1',
    //   tax_regime: '',
    //   certified_digital: '',
    //   password: '',
    // },
    initialValues: {
      name: '',
      phone: '',
      email: '',
      cnpj: '',
      store_id: '',
      stone_code: '',
      pagseguro_code: '',
      code_auth: '',
      type: '1',
      logo: '',
      zip_code: '',
      city: '',
      state: '',
      neighborhood: '',
      street: '',
      number: '',
      complement: '',
      fantasy_name: '',
      municipal_code: '',
      state_code: '',
      latitude: '',
      longitude: '',
      csc_code: '',
      surname: '',
      type_certified: '',
      tax_regime: '',
      certified_digital: '',
      password: '',
      pis: '',
      cofins: '',

      image: null,
      image_url: null,
    },
    validate,
    onSubmit: values => {
      const {
        name,
        phone,
        email,
        cnpj,
        store_id,
        stone_code,
        pagseguro_code,
        code_auth,
        type,
        logo,
        zip_code,
        city,
        state,
        neighborhood,
        street,
        number,
        complement,
        latitude,
        longitude,
        fantasy_name,
        municipal_code,
        state_code,
        surname,
        csc_code,
        certified_digital,
        type_certified,
        tax_regime,
        pis,
        cofins,
        password,

        image
      } = values;

      const saveData = {
        name,
        phone,
        email,
        cnpj,
        store_id,
        stone_code,
        pagseguro_code,
        code_auth,
        type,
        // logo,
        active: true,

        // geopoint: new GeoPoint(Number(latitude), Number(longitude)),

        address: {
          city,
          state,
          neighborhood,
          street,
          zip_code,
          number,
          complement,
        },

        fiscal_data: {
          fantasy_name,
          municipal_code,
          state_code,
          csc: csc_code,
          surname,
          password,
          type_certified,
          tax_regime,
          pis,
          cofins,
          // certified_digital,
        },

        image,
        certified_digital
      }

      const onSuccess = (id) => {
        integrationInvoice(id, 'CREATE')
        onShowAlert(PreAlert.success('Registro cadastrado com sucesso'))
        setIsClose();
      }
      const onSuccessUpdate = (id) => {
        onShowAlert(PreAlert.success('Registro atualizado com sucesso'))
        setIsClose();
        integrationInvoice(id, 'UPDATE')
      }
      const onError = () => {
        onShowAlert(PreAlert.error('Falhou ao cadastrar o registro'))
      }

      setIsLoading(true);
      if (storeSelected) {
        new StoreDB(establishment.id)
          .update(storeSelected.id, saveData)
          .then(onSuccessUpdate(storeSelected.id))
          .catch(onError)
          .finally(() => setIsLoading(false))
      } else {
        new StoreDB(establishment.id)
          .create(saveData)
          .then(onSuccess)
          .catch(onError)
          .finally(() => setIsLoading(false))
      }
    },
  });


  const integrationInvoice = async (storeId, status) => {

    const storeData = await new StoreDB(establishment.id).get(storeId)

    if (storeData.certified_digital_url && storeData.fiscal_data.csc && storeData.fiscal_data.password) {
      const payload = {
        cnpj: storeData.cnpj.replace(/[^0-9]/g, ''),
        name: storeData.name,
        fantasy_name: storeData.fiscal_data.fantasy_name,
        phone: storeData.phone.replace(/[^0-9]/g, ''),
        email: storeData.email,
        city: storeData.address.city,
        state: storeData.address.state,
        neighborhood: storeData.address.neighborhood,
        street: storeData.address.street,
        zip_code: storeData.address.zip_code.replace(/[^0-9]/g, ''),
        number: storeData.address.number,
        complement: storeData.address.complement,

        municipal_code: storeData.fiscal_data.municipal_code,
        state_code: storeData.fiscal_data.state_code,

        certificate_type: storeData.fiscal_data.type_certified,
        certificate: storeData.certified_digital_url,
        certificate_password: storeData.fiscal_data.password,
        nick_name: '',
        csc_token: storeData.fiscal_data.csc,
        regime: storeData.fiscal_data.tax_regime,
        state_registration: 'INSCRIÇÃO ESTUDUAL',
        municipal_registration: storeData.fiscal_data.municipal_code,

        pis: storeData.fiscal_data.pis,
        confins: storeData.fiscal_data.cofins
      }

      try {

        let response;
        if (status === 'CREATE') {

          response = await apiFiscal.post(`/establishment/store`, {
            ...payload
          })

        } else {

          response = await apiFiscal.put(`/establishment/update/${payload.cnpj}`, {
            ...payload
          })

        }


        const saveDataAux = storeData;
        saveDataAux.fiscal_data.status = 'success';
        saveDataAux.fiscal_data.message = JSON.stringify(response.data)

        new StoreDB(establishment.id)
          .update(storeId, saveDataAux)
          .finally(() => setIsLoading(false))
          .catch(() => { })
          .finally(() => { })

      } catch (error) {

        const saveDataAux = storeData;
        saveDataAux.fiscal_data.status = 'error';
        saveDataAux.fiscal_data.message = JSON.stringify(error.response.data)

        new StoreDB(establishment.id)
          .update(storeId, saveDataAux)
          .finally(() => setIsLoading(false))
          .catch(() => { })
          .finally(() => { })
      }
    }
  }

  const handleCallbackCep = async (cep) => {
    await apiViaCep(`${cep}/json`)
      .then(res => {
        if (res.data.erro) {
          showNotification('', 'CEP não encontrado', 'danger');
          formik.setFieldValue('neighborhood', '');
          formik.setFieldValue('street', '');
          formik.setFieldValue('zip_code', '');
          formik.setFieldValue('state', '');
          formik.setFieldValue('city', '');
          formik.setFieldValue('municipal_code', '');
          formik.setFieldValue('state_code', '');
        } else {
          if (!storeSelected)
            showNotification('', 'CEP localizado', 'success');
          if (res.data.bairro) formik.setFieldValue('neighborhood', res.data.bairro);
          if (res.data.logradouro) formik.setFieldValue('street', res.data.logradouro);
          formik.setFieldValue('zip_code', res.data.cep);
          formik.setFieldValue('state', res.data.uf);
          formik.setFieldValue('city', res.data.localidade);
          formik.setFieldValue('municipal_code', res.data.siafi);
          formik.setFieldValue('state_code', res.data.ibge.substring(0, 2));
        }
      }).catch(err => {
        showNotification('', 'CEP não encontrado', 'danger');
      }).finally(res => {
        //setIsLoading(false);
      });
  }

  const checkStepIsValid = (step) => {
    if (step === 'step1') {
      if (formik.values.cnpj !== '' && formik.values.name !== '' && formik.values.phone !== '' && formik.values.email !== '' && formik.values.store_id !== '' && formik.values.stone_code !== '' && formik.values.pagseguro_code !== '' && formik.values.code_auth !== '') {
        return false;
      } else {
        return true;
      }
    }
    if (step === 'step2') {
      if (formik.values.zip_code !== '' && formik.values.city !== '' && formik.values.neighborhood !== '' && formik.values.street !== '' && formik.values.number !== '') {
        return false;
      } else {
        return true;
      }
    }
  }

  useEffect(() => {
    if (formik.values.zip_code?.length === 9 && formik.values.zip_code !== '') {
      let cep = formik.values.zip_code.replace('-', '');
      handleCallbackCep(cep);
    }
  }, [formik.values.zip_code]);

  const handleImage = useCallback((e) => {
    const [file] = Array.from(e.target.files)
    formik.setValues({
      ...formik.values,
      image: file,
      image_url: URL.createObjectURL(file)
    })
  }, [formik.values])

  const handleCertifiedDigital = useCallback((e) => {
    const [file] = Array.from(e.target.files)
    formik.setValues({
      ...formik.values,
      certified_digital: file
    })
  }, [formik.values])

  return (
    <Modal
      id={'modal-register-stores'}
      titleId={'Cadastro de Estabelecimentos'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
    // onSubmit={formik.handleSubmit}
    >
      <ModalHeader className='p-4 mt-2 justify-content-start' setIsOpen={setIsOpen}>
        <ModalTitle  id="register-stores">Cadastro de Lojas</ModalTitle>    
      </ModalHeader>
      <ModalBody className= 'p-4'>
        {/* <form noValidate onSubmit={formik.handleSubmit}> */}
        {/* Inputs */}
        <Wizard
          isHeader
          color='info'
          isLoading={isLoading}
          onSubmit={formik.handleSubmit}
          className='p-0'
       >
          <WizardItem isLoading={isLoading} id='step1' title='Dados Principais' required={checkStepIsValid('step1')} >
           
              <div className='row align-items-center justify-content-center'>
                <FormGroup
                  id='image_url'
                  ariaLabel='Logo'
                >
                  <Avatar
                    id='image_url'
                    value={formik.values.image_url}
                    onChange={handleImage} />
                </FormGroup>
              </div>
              {/* CNPJ */}
              < div className="row g-4">
                
                {/* Nome */}
                <FormGroup id="name" label="Nome da loja" className='col-md-12 mb-4'>
                  <Input
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    isValid={formik.isValid}
                    isTouched={formik.touched.name}
                    invalidFeedback={formik.errors.name}
                    validFeedback='Assim está bom!'
                    placeholder='Lojão do Jão'
                  />
                </FormGroup>
                <FormGroup
                  id='cnpj'
                  label='CNPJ'
                  className='col-md-4 mb-4'
                >
                  <Input
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={masks.cnpj(formik.values.cnpj)}
                    isValid={formik.isValid}
                    isTouched={formik.touched.cnpj}
                    invalidFeedback={formik.errors.cnpj}
                    validFeedback='Assim está bom!'
                    placeholder='34237209000195'
                  />
                </FormGroup>


                {/* store_id */}
                <FormGroup
                  id='store_id'
                  label='Código da Loja'
                  className='col-md-4'
                >
                  <Input
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.store_id}
                    isValid={formik.isValid}
                    isTouched={formik.touched.store_id}
                    invalidFeedback={formik.errors.store_id}
                    validFeedback='Assim está bom!'
                    placeholder='XXXXXxxXXx'
                  />
                </FormGroup>

                {/* stone_code */}
                <FormGroup
                  id='stone_code'
                  label='Código da Stone'
                  className='col-md-4'
                >
                  <Input
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.stone_code}
                    isValid={formik.isValid}
                    isTouched={formik.touched.stone_code}
                    invalidFeedback={formik.errors.stone_code}
                    validFeedback='Assim está bom!'
                    placeholder='xxxxxxxxxxxxxx'
                  />
                </FormGroup>

                {/* pagseguro_code */}
                <FormGroup
                  id='pagseguro_code'
                  label='Código do PagSeguro'
                  className='col-md-4'
                >
                  <Input
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.pagseguro_code}
                    isValid={formik.isValid}
                    isTouched={formik.touched.pagseguro_code}
                    invalidFeedback={formik.errors.pagseguro_code}
                    validFeedback='Assim está bom!'
                    placeholder='xxxxxxxxxxxxxx'
                  />
                </FormGroup>

                {/* code_auth */}
                <FormGroup
                  id='code_auth'
                  label='Código de Autorização'
                  className='col-md-4'
                >
                  <Input
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.code_auth}
                    isValid={formik.isValid}
                    isTouched={formik.touched.code_auth}
                    invalidFeedback={formik.errors.code_auth}
                    validFeedback='Assim está bom!'
                    placeholder='xxxxxxxxxxxxxx'
                  />
                </FormGroup>

                {/* type */}
                <FormGroup
                  id='type'
                  label='Tipo'
                  className='col-md-4'
                >
                  <Select
                    style={{ cursor: 'pointer' }}
                    id="example"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    isValid={formik.isValid}
                    isTouched={formik.touched.type}
                    value={formik.values.type}
                  >
                    <Options
                      list={[
                        {
                          text: 'Quiosque',
                          value: 1
                        },
                        {
                          text: 'Loja de Conveniência',
                          value: 2
                        },
                        {
                          text: 'Loja de Varejo',
                          value: 3
                        },
                      ]}
                    />
                  </Select>
                </FormGroup>
               
                {/* Email */}
                <FormGroup
                  className='col-md-8'>
                  <Label htmlFor="email">Email</Label>
                  <InputGroup>
                    <InputGroupText id="inputGroupPrepend">
                      @
                    </InputGroupText>
                    <Input
                      id="email"
                      ariaDescribedby='inputGroupPrepend'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      isValid={formik.isValid}
                      isTouched={formik.touched.email}
                      invalidFeedback={formik.errors.email}
                      validFeedback='Assim está bom!'
                      placeholder="joaodasilva@email.com"
                    />
                  </InputGroup>
                </FormGroup>
                 {/* Telefone */}
                 <FormGroup
                  id='phone'
                  label='Telefone'
                  className='col-md-4'
                >
                  <Input
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={masks.phone(formik.values.phone)}
                    isValid={formik.isValid}
                    isTouched={formik.touched.phone}
                    invalidFeedback={formik.errors.phone}
                    validFeedback='Assim está bom!'
                    placeholder='(11) 99999-9999'
                  />
                </FormGroup>
              </div>
          </WizardItem>

          <WizardItem isLoading={isLoading} id='step2' title='Endereço' required={checkStepIsValid('step2')}>
            {/* CEP */}
            <div className="row g-4">
              <FormGroup
                id='zip_code'
                label='CEP'
                className='col-md-3 mb-4'
              >
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={masks.cep(formik.values.zip_code)}
                  isValid={formik.isValid}
                  isTouched={formik.touched.zip_code}
                  invalidFeedback={formik.errors.zip_code}
                  validFeedback='Assim está bom!'
                  placeholder='39100-000'
                />
              </FormGroup>

              {(formik.values.state && !formik.errors.zip_code) && (
                <FormGroup
                  id='state'
                  label='Estado'
                  className='col-md-3'
                >
                  <Input
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.state}
                    isTouched={formik.touched.state}
                    invalidFeedback={formik.errors.state}
                    disabled
                  />
                </FormGroup>
              )}

              {(formik.values.city && !formik.errors.zip_code) && (
                <FormGroup
                  id='city'
                  label='Cidade'
                  className='col-md-6'
                >
                  <Input
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.city}
                    isTouched={formik.touched.city}
                    invalidFeedback={formik.errors.city}
                    disabled
                  />
                </FormGroup>
              )}

              {(formik.values.city && !formik.errors.zip_code) && (
                <FormGroup
                  id='neighborhood'
                  label='Bairro'
                  className='col-md-3'
                >
                  <Input
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.neighborhood}
                    isTouched={formik.touched.neighborhood}
                    invalidFeedback={formik.errors.neighborhood}
                    placeholder='Ex: Centro'
                  />
                </FormGroup>
              )}

              {(formik.values.city && !formik.errors.zip_code) && (
                <FormGroup
                  id='street'
                  label='Logradouro'
                  className='col-md-3'
                >
                  <Input
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.street}
                    isTouched={formik.touched.street}
                    invalidFeedback={formik.errors.street}
                    placeholder='Ex: Rua Principal'
                  />
                </FormGroup>
              )}

              {(formik.values.city && !formik.errors.zip_code) && (
                <FormGroup
                  id='number'
                  label='Número'
                  className='col-md-2'
                >
                  <Input
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.number}
                    isTouched={formik.touched.number}
                    invalidFeedback={formik.errors.number}
                    placeholder='0'
                  />
                </FormGroup>
              )}

              {(formik.values.city && !formik.errors.zip_code) && (
                <FormGroup
                  id='complement'
                  label='Complemento'
                  className='col-md-4'
                >
                  <Input
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.complement}
                    isTouched={formik.touched.complement}
                    invalidFeedback={formik.errors.complement}
                    placeholder='Ex: Casa'
                  />
                </FormGroup>
              )}
            </div>
          </WizardItem>

          <WizardItem isLoading={isLoading} id='step3' title='Dados Fiscais'>
            <div className="row g-4">

              {/* Código do Município */}
              <FormGroup
                id='state_code'
                label='Código do Estado'
                className='col-md-3 mb-4'
              >
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.state_code}
                  isValid={formik.isValid}
                  isTouched={formik.touched.state_code}
                  invalidFeedback={formik.errors.state_code}
                  validFeedback='Assim está bom!'
                  placeholder='Ex: 35'
                  disabled
                />
              </FormGroup>
              {/* Código do Município */}
              <FormGroup
                id='municipal_code'
                label='Código do Município'
                className='col-md-3 mb-4'
              >
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.municipal_code}
                  isValid={formik.isValid}
                  isTouched={formik.touched.municipal_code}
                  invalidFeedback={formik.errors.municipal_code}
                  validFeedback='Assim está bom!'
                  placeholder='Ex: 9999'
                  disabled
                />
              </FormGroup>
              {/* Nome Fantasia */}
              <FormGroup
                id='fantasy_name'
                label='Nome Fantasia'
                className='col-md-6 mb-4 '
              >
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fantasy_name}
                  isValid={formik.isValid}
                  isTouched={formik.touched.fantasy_name}
                  invalidFeedback={formik.errors.fantasy_name}
                  validFeedback='Assim está bom!'
                  placeholder='Ex: Loja do Jão'
                />
              </FormGroup>

              {/* Código CSC */}
              <FormGroup
                id='csc_code'
                label='Código CSC (Código de Segurança do Contribuinte)'
                className='col-md-6 mb-4'
              >
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.csc_code}
                  isValid={formik.isValid}
                  isTouched={formik.touched.csc_code}
                  invalidFeedback={formik.errors.csc_code}
                  validFeedback='Assim está bom!'
                  placeholder='Código CSC (36 caracteres)'
                />
              </FormGroup>

              {/* Surname */}
              <FormGroup
                id='surname'
                label='Nome do Responsável'
                className='col-md-6 mb-4'
              >
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.surname}
                  isValid={formik.isValid}
                  isTouched={formik.touched.surname}
                  invalidFeedback={formik.errors.surname}
                  validFeedback='Assim está bom!'
                  placeholder='Nome do Responsável'
                />
              </FormGroup>

              {/* Tipo de Certificado */}
              <FormGroup
                id='type_certified'
                label='Tipo do Certificado'
                className={formik.values.tax_regime === '3' || formik.values.tax_regime === '4' ? 'col-md-3 mb-4' : 'col-md-6 mb-4'}
              >
                <Select
                  style={{ cursor: 'pointer' }}
                  id="type_certified"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  isValid={formik.isValid}
                  isTouched={formik.touched.type_certified}
                  value={formik.values.type_certified}
                >
                  <Options
                    list={[
                      {
                        text: 'A1',
                        value: 'a1'
                      },
                      {
                        text: 'A3',
                        value: 'a3'
                      },
                    ]}
                  />
                </Select>
              </FormGroup>

              {/* Regime de Apuração */}
              <FormGroup
                id='tax_regime'
                label='Regime de Apuração'
                className={formik.values.tax_regime === '3' || formik.values.tax_regime === '4' ? 'col-md-5 mb-4' : 'col-md-6 mb-4'}
              >
                <Select
                  style={{ cursor: 'pointer' }}
                  id="tax_regime"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  isValid={formik.isValid}
                  isTouched={formik.touched.tax_regime}
                  value={formik.values.tax_regime}
                >
                  <Options
                    list={[
                      {
                        text: 'MEI',
                        value: '1',
                      },
                      {
                        text: 'Simples Nacional',
                        value: '2'
                      },
                      {
                        text: 'Lucro Real',
                        value: '3'
                      },
                      {
                        text: 'Lucro Presumido',
                        value: '4'
                      },
                    ]}
                  />
                </Select>
              </FormGroup>


              {(formik.values.tax_regime === '3' || formik.values.tax_regime === '4') && (
                <>
                  <FormGroup
                    id='pis'
                    label='PIS'
                    className='col-md-2 mb-4'
                  >
                    <Input
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.pis}
                      isValid={formik.isValid}
                      isTouched={formik.touched.pis}
                      invalidFeedback={formik.errors.pis}
                      validFeedback='Assim está bom!'
                      placeholder='PIS'
                    />
                  </FormGroup>

                  <FormGroup
                    id='cofins'
                    label='COFINS'
                    className='col-md-2 mb-4'
                  >
                    <Input
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.cofins}
                      isValid={formik.isValid}
                      isTouched={formik.touched.cofins}
                      invalidFeedback={formik.errors.cofins}
                      validFeedback='Assim está bom!'
                      placeholder='COFINS'
                    />
                  </FormGroup>
                </>
              )}

              {/* Certificado */}
              <FormGroup
                id='certified_digital'
                label='Certificado Digital'
                className='col-md-6 mb-4'
              >
                <Input
                  type="file"
                  onChange={handleCertifiedDigital}
                  onBlur={formik.handleBlur}
                  // value={formik.values.certified_digital}
                  isValid={formik.isValid}
                  isTouched={formik.touched.certified_digital}
                  invalidFeedback={formik.errors.certified_digital}
                  validFeedback='Assim está bom!'
                  placeholder='Selecione o certificado digital'
                />
              </FormGroup>

              {/* Senha */}
              <FormGroup
                id='password'
                label='Senha Certificado Digital'
                className='col-md-6 mb-4'
              >
                <Input
                  type="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  isValid={formik.isValid}
                  isTouched={formik.touched.password}
                  invalidFeedback={formik.errors.password}
                  validFeedback='Assim está bom!'
                  placeholder='Senha do Certificado Digital'
                />
              </FormGroup>
            </div>
          </WizardItem>
        </Wizard>
        {/* </form> */}
      </ModalBody>
    </Modal >
  )
}

export default memo(ModalRegisterStores);