import React from "react";
import Button from "../../bootstrap/Button";

export default function BtnCancel({ className, setIsClose }) {
  return (
    <Button
      className={className}
      color="danger"
      icon="cancel"
      rounded={1}
      onClick={setIsClose}
      shadow="sm"
      hoverShadow="sm"
      size="sm"
    >
      Cancelar
    </Button>
  );
}