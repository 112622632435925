import Modal, { ModalBody, ModalHeader, ModalTitle } from "../../../bootstrap/Modal";
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useContext, useState, useMemo } from 'react';
import FormGroup from '../../../bootstrap/forms/FormGroup';
import Avatar from '../../../MyCustom/Avatar';
import Button from "../../../bootstrap/Button";
import CategoryComplementDB from "../../../../database/wrappers/categoryComplement";
import { DefaultContext } from "../../../../contexts/default";
import PreAlert from "../../../../helpers/utils/preAlert";
import Spinner from "../../../bootstrap/Spinner";
import Input from "../../../bootstrap/forms/Input";





const ModalImageComplements = ({ open, setIsOpen, setIsClose, categoryComplementSelected, complement }) => {
  const { establishment, onShowAlert } = useContext(DefaultContext)
  const [isLoading, setIsLoading] = useState(false);
  const [selectCategoryComplement, setselectCategoryComplement] = useState({})


  useEffect(() => {
    const fetchData = async () => {
      if (open && categoryComplementSelected) {
        const categoryComplement = await new CategoryComplementDB(establishment.id).get(categoryComplementSelected.id);
        setselectCategoryComplement(categoryComplement);
      }
    };
    fetchData();
  }, [open, categoryComplementSelected]);

  const complementExists = useMemo(() => {
    if (selectCategoryComplement && complement) {
      return selectCategoryComplement?.complements_picture?.find((item) => item.id === complement?.id);
    }
    return null;
  }, [selectCategoryComplement, complement]);

  useEffect(() => {
    if (!open) return formik.resetForm();
    if (selectCategoryComplement) {
      formik.setValues({
        sku: complementExists?.sku || '',
        image_complement_url: complementExists?.image_complement_url || null,
        image_complement_ref: complementExists?.image_complement_ref || null,
      });
    }

  }, [selectCategoryComplement, complementExists, open])

  const formik = useFormik({
    initialValues: {
      sku: '',
      image_complement: null,
      image_complement_ref: null,
      image_complement_url: null
    },
    onSubmit: values => {
      setIsLoading(true);
      const { image_complement, sku ,image_complement_url} = values;
      const complementsPicture = selectCategoryComplement?.complements_picture || [];
      const complementsPictureFilter = complementsPicture.filter(item => item.id !== complement.id);
      if (image_complement) {
        complementsPictureFilter.push({
          id: complement.id,
          image_complement,
          sku
        },);
      } else if (image_complement_url) {
        complementsPictureFilter.push({
          id: complement.id,
          image_complement_url,
          sku
        },);
      } else {
        complementsPictureFilter.push({
          id: complement.id,
          image_complement_url: null,
          sku,
        })
      }
   

      const data = {
        ...selectCategoryComplement,
        complements_picture: complementsPictureFilter

      };

      const onSuccess = () => {
        onShowAlert(PreAlert.success('Registro cadastrado com sucesso'))
        setIsClose();
      }
      const onSuccessUpdate = () => {
        onShowAlert(PreAlert.success('Registro atualizado com sucesso'))
        setIsClose();
      }
      const onError = (e) => {
        console.error(e)
        onShowAlert(PreAlert.error('Falhou ao cadastrar o registro'))
      }

      if (selectCategoryComplement) {
        new CategoryComplementDB(establishment.id)
          .update(categoryComplementSelected.id, data)
          .then(onSuccessUpdate)
          .catch((e) => console.error(e))
          .finally(() => setIsLoading(false))
      } else {
        new CategoryComplementDB(establishment.id)
          .create(data)
          .then(onSuccess)
          .catch((e) => onError(e))
          .finally(() => setIsLoading(false))
      }
    },
  })
  const handleImage = useCallback((e) => {
    const [file] = Array.from(e.target.files)
    formik.setValues({
      ...formik.values,
      image_complement: file,
      image_complement_url: URL.createObjectURL(file)
    })
  }, [formik.values])

  return (
    <Modal
      id={'modal-register-category-complement'}
      titleId={'Cadastro de Categoria de Complemento'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isCentered={true}
      size="sm" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
      onSubmit={formik.handleSubmit}
    >
      <ModalHeader setIsOpen={setIsOpen} >
        <ModalTitle id="register-category-complement">{`Imagem complemento - ${complement?.name}`}</ModalTitle>

      </ModalHeader>
      <ModalBody className='px-5 pt-5'>
        <form noValidate onSubmit={formik.handleSubmit}>
          {/* Imagem do Complemento */}
          <div className='row align-items-center justify-content-center'>
            <FormGroup
              id='image_complement_url'
              ariaLabel='Logo'
            >
              <Avatar
                id='image_complement_url'
                value={formik.values.image_complement_url}
                onChange={handleImage} />
            </FormGroup>

          </div>
          <FormGroup id="sku" label="SKU" className='col-md-8 '>
            <Input
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.sku}
              isValid={formik.isValid}
              isTouched={formik.touched.sku}
              invalidFeedback={formik.errors.sku}
              validFeedback='Assim está bom!'
              placeholder='000'
            />
          </FormGroup>
          <div className="row pt-4">
            <div className='d-flex justify-content-end gap-2'>
              <Button
                color="danger"
                icon="cancel"
                rounded={1}
                onClick={setIsClose}
                shadow="sm"
                hoverShadow="sm"
                size="sm"
              >
                Cancelar
              </Button>
              {isLoading ? (
                <Button
                  color="success"
                  rounded={1}
                  hoverShadow="sm"
                  shadow="sm"
                  size="sm"
                >
                  <Spinner
                    color="light"
                    inButton
                    isGrow
                    isSmall
                    size={10}
                    tag="span"
                  >
                    Carregando...
                  </Spinner>
                  Carregando...
                </Button>
              ) : (
                <Button
                  type='submit'
                  color='success'
                  icon="check"
                  rounded={1}
                  hoverShadow="sm"
                  shadow="sm"
                  size="sm"
                >
                  Confirmar
                </Button>
              )}
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default ModalImageComplements;