import { QueryConstraint, Unsubscribe } from "firebase/firestore";
import Consumption from "../entities/sales/consumption.entity";
import FirestorePipe from "../utils/pipe";
import EstablishmentDB from "./establishment";

class ConsumptionDB extends FirestorePipe {

  public static readonly colName = 'Consumptions'

  constructor(idEstab: string) {
    if(!idEstab)
      throw new Error('Informe o id da loja e do estabelecimento')
    super(`${EstablishmentDB.colName}/${idEstab}/${ConsumptionDB.colName}`);
  }

  public create(data: Consumption): Promise<any> {
    throw new Error('Method not exists')
  }
  public update(id: string, data: Consumption): Promise<any> {
    throw new Error('Method not exists')
  }
  public delete(id: string): Promise<any> {
    throw new Error('Method not exists')
  }
  public getAll(...params: QueryConstraint[]): Promise<Consumption[]> {
    return this._getAll<Consumption>(...params);
  }
  public get(id: string): Promise<Consumption> {
    return this._get(id);
  }
  public on(listener: (data: Consumption[]) => void, ...params: QueryConstraint[]): Unsubscribe {
    return this._on(listener, ...params);
  }

}

export default ConsumptionDB;