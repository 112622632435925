import React, { useState, useContext, useEffect, memo } from 'react';
import { useFormik } from 'formik';
import Button from '../../bootstrap/Button';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import Label from '../../bootstrap/forms/Label';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import Spinner from '../../bootstrap/Spinner';
import { serverTimestamp } from 'firebase/firestore'

import { DefaultContext } from '../../../contexts/default';
import PreAlert from '../../../helpers/utils/preAlert';
import StockProductDB from '../../../database/wrappers/stockProduct';

const validate = (values) => {
  const errors = {};

  if (!values.qtd || isNaN(parseInt(values.qtd))) {
    errors.qtd = 'Informe um número válido';
  }

  return errors;
}

const ModalRegisterStockProducts = ({ open, setIsOpen, setIsClose, stockSelected }) => {

  const { establishment, store, onShowAlert, user } = useContext(DefaultContext)

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if(!open) return formik.resetForm();
    if (stockSelected) {
      const { qtd } = stockSelected;

      formik.setValues({
        qtd,
        // active: active ? 'ativo' : 'inativo',
      });
    }
  }, [stockSelected, open])

  const formik = useFormik({
    initialValues: {
      active: '',
      otp: '',
    },
    validate,
    onSubmit: values => {
      const { qtd } = values;

      const data = {
        // active: active === 'ativo',
        qtd,
        authorEdit: user.uid,
        updated_at: serverTimestamp(),
      }

      const onSuccess = () => {
        onShowAlert(PreAlert.success('Registro cadastrado com sucesso'))
        setIsClose();
      }
      const onSuccessUpdate = () => {
        onShowAlert(PreAlert.success('Registro atualizado com sucesso'))
        setIsClose();
      }
      const onError = (error) => {
        console.error(error);
        onShowAlert(PreAlert.error('Falhou ao cadastrar o registro'))
      }

      setIsLoading(true)
      if (stockSelected.stockExist) {
        new StockProductDB(establishment.id, store.id)
          .update(stockSelected.id, data)
          .then(onSuccessUpdate)
          .catch(onError)
          .finally(() => setIsLoading(false))
      } else {
        new StockProductDB(establishment.id, store.id)
          .define(stockSelected.id, data)
          .then(onSuccess)
          .catch(onError)
          .finally(() => setIsLoading(false))
      }
      
    },
  })

  return (
    <Modal
      id={'modal-register-totem'}
      titleId={'Cadastro de Totem'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
      onSubmit={formik.handleSubmit}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="register-totem">{stockSelected ? 'Atualização Estoque de Produto' : 'Cadastro Estoque de Produto'}</ModalTitle>
      </ModalHeader>
      <ModalBody className='p-5'> 
        <form noValidate onSubmit={formik.handleSubmit}>
          <div className='d-flex flex-column align-items-center col-md-12'>
            <img src={stockSelected?.image_url} height={100} width={100} />
            <h3 style={{ marginTop: 20, marginBottom: 20 }}>{stockSelected?.name}</h3>
          </div>
          {/* Inputs */}
          <div className="row pb-2 d-flex">
            {/* key */}
            <FormGroup
              id='qtd' label='Quantidade' className='col-md-4 mb-4'
            >
              <Input
                type="number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.qtd}
                isValid={formik.isValid}
                isTouched={formik.touched.qtd}
                invalidFeedback={formik.errors.qtd}
                validFeedback='Assim está bom!'
                placeholder='Insira a Key'
              />
            </FormGroup>

            {/* Status */}
            {/* <FormGroup className='col-md-4'>
              <Label>Status</Label>
              <ChecksGroup
                isValid={formik.isValid}
                isTouched={formik.touched.active}
                invalidFeedback={formik.errors.active}
              >
                <Checks
                  type="radio"
                  id="active"
                  label="Ativo"
                  name="active"
                  value="ativo"
                  onChange={formik.handleChange}
                  checked={formik.values.active}
                  isInline
                />
                <Checks
                  type="radio"
                  id="active2"
                  label="Inativo"
                  name="active"
                  value="inativo"
                  onChange={formik.handleChange}
                  checked={formik.values.active}
                  isInline
                />
              </ChecksGroup>
            </FormGroup> */}
          </div>



          {/* Buttons */}
          <div className="row pt-4">
              <div className='d-flex justify-content-end gap-2'>
                <Button
                  color="danger"
                  icon="cancel"
                  rounded={1}
                  onClick={setIsClose}
                  shadow="sm"
                  hoverShadow="sm"
                  size="sm"
                >
                  Cancelar
                </Button>
                {isLoading ? (
                  <Button
                    color="success"
                    rounded={1}
                    hoverShadow="sm"
                    shadow="sm"
                    size="sm"
                  >
                    <Spinner
                      color="light"
                      inButton
                      isGrow
                      isSmall
                      size={10}
                      tag="span"
                    >
                      Carregando...
                    </Spinner>
                    Carregando...
                  </Button>
                ) : (
                  <Button
                    isDisable={!formik.isValid && !!formik.submitCount}
                    type='submit'
                    color='success'
                    icon="check"
                    rounded={1}
                    hoverShadow="sm"
                    shadow="sm"
                    size="sm"
                  >
                    Confirmar
                  </Button>
                )}
              </div>
            </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default memo(ModalRegisterStockProducts);