import React, { useCallback, useContext, useEffect, useState } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft } from '../../../../layout/SubHeader/SubHeader';
import Page from '../../../../layout/Page/Page';
import Breadcrumb from '../../../../components/bootstrap/Breadcrumb';
import { demoPages } from '../../../../menu';
import { DefaultContext } from '../../../../contexts/default';
import Card, { CardBody, CardHeader, CardLabel, CardSubTitle, CardTitle } from '../../../../components/bootstrap/Card';
import Button from '../../../../components/bootstrap/Button';
import SelectEstablishment from '../../../../components/MyCustom/SelectEstablishment';
import SelectStore from '../../../../components/MyCustom/SelectStore';
import SelectYear from '../../../../components/MyCustom/SelectYear';
import Input from '../../../../components/bootstrap/forms/Input';
import Label from "../../../../components/bootstrap/forms/Label";
import PreAlert from '../../../../helpers/utils/preAlert';
import GoalDB from '../../../../database/wrappers/goal';
import { useFormik } from 'formik';
import masks, { unmask } from '../../../../helpers/utils/masks';
import Spinner from '../../../../components/bootstrap/Spinner';

const Goals = () => {
  
  const { establishment, store, onShowAlert } = useContext(DefaultContext)
  
  const [year, setyear] = useState('2022')
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!establishment || !store) return;
    new GoalDB(establishment.id)
      .get(`${year}-${store.id}`)
      .then(data => {
        if(data) {
          formik.setValues({
            January: String(data.January),
            February: String(data.February),
            March: String(data.March),
            April: String(data.April),
            May: String(data.May),
            June: String(data.June),
            July: String(data.July),
            August: String(data.August),
            September: String(data.September),
            October: String(data.October),
            November: String(data.November),
            December: String(data.December),
          })
        } else {
          formik.resetForm();
        }
      })
      .catch(error => {
        console.error(error)
        formik.resetForm();
      })
  }, [establishment, store, year])

  const validate = useCallback((values) => {
    const errors = {};

    if(!values.January || isNaN(Number(unmask(values.January)))) {
      errors.January = 'Informe um valor válido'
    }
    if(!values.February || isNaN(Number(unmask(values.February)))) {
      errors.February = 'Informe um valor válido'
    }
    if(!values.March || isNaN(Number(unmask(values.March)))) {
      errors.March = 'Informe um valor válido'
    }
    if(!values.April || isNaN(Number(unmask(values.April)))) {
      errors.April = 'Informe um valor válido'
    }
    if(!values.May || isNaN(Number(unmask(values.May)))) {
      errors.May = 'Informe um valor válido'
    }
    if(!values.June || isNaN(Number(unmask(values.June)))) {
      errors.June = 'Informe um valor válido'
    }
    if(!values.July || isNaN(Number(unmask(values.July)))) {
      errors.July = 'Informe um valor válido'
    }
    if(!values.September || isNaN(Number(unmask(values.September)))) {
      errors.September = 'Informe um valor válido'
    }
    if(!values.October || isNaN(Number(unmask(values.October)))) {
      errors.October = 'Informe um valor válido'
    }
    if(!values.November || isNaN(Number(unmask(values.November)))) {
      errors.November = 'Informe um valor válido'
    }
    if(!values.December || isNaN(Number(unmask(values.December)))) {
      errors.December = 'Informe um valor válido'
    }

    return errors;
  }, [])

  const formik = useFormik({
    initialValues: {
      January: '',
      February: '',
      March: '',
      April: '',
      May: '',
      June: '',
      July: '',
      August: '',
      September: '',
      October: '',
      November: '',
      December: '',
    },
    validate,
    onSubmit: values => {
      const { 
        January,
        February,
        March,
        April,
        May,
        June,
        July,
        August,
        September,
        October,
        November,
        December,
      } = values;

      const data = {
        January: Number(unmask(January)),
        February: Number(unmask(February)),
        March: Number(unmask(March)),
        April: Number(unmask(April)),
        May: Number(unmask(May)),
        June: Number(unmask(June)),
        July: Number(unmask(July)),
        August: Number(unmask(August)),
        September: Number(unmask(September)),
        October: Number(unmask(October)),
        November: Number(unmask(November)),
        December: Number(unmask(December)),
      }

      const onSuccess = () => {
        onShowAlert(PreAlert.success('Registro cadastrado com sucesso'))
      }
      const onError = () => {
        onShowAlert(PreAlert.error('Falhou ao cadastrar o registro'))
      }

      setIsLoading(true);
      new GoalDB(establishment.id)
        .define(`${year}-${store.id}`, data)
        .then(onSuccess)
        .catch(onError)
        .finally(() => setIsLoading(false))
    },
  })

  const onChangeYear = useCallback((e) => {
    setyear(e.target.value)
  },[])

  return (
    <>
      <PageWrapper title={demoPages.store.subMenu.stockProducts.text}>
        <SubHeader>
          <SubHeaderLeft>
            <Breadcrumb
              list={[
                { title: 'Cadastros', to: '/register/goals' },
                { title: 'Metas', to: '/register/goals' },
              ]}
            />
          </SubHeaderLeft>
        </SubHeader>

        <div className='row d-flex align-items-center mt-4 mx-1' >
          <SelectEstablishment />
          <SelectStore />
          <SelectYear
            value={year}
            onChange={onChangeYear}
          />
        </div>

        <Page container='fluid'>
          <Card>
            <CardHeader>
              <CardLabel icon='AutoGraph'>
                <CardTitle>Cadastro de metas</CardTitle>
                <CardSubTitle>Cadastrar o valor das metas das lojas</CardSubTitle>
              </CardLabel>
            </CardHeader>
            <CardBody className='p-5'>
              <form onSubmit={formik.handleSubmit} >
                <div className="row col-md-12 mb-4">
                  <div className="col-md-4">
                    <Label>Janeiro</Label>
                    <Input 
                      class="form-control" 
                      id='January'
                      type="text" 
                      placeholder="R$ 0,00"
                      isValid={formik.isValid}
                      isTouched={formik.touched.January}
                      invalidFeedback={formik.errors.January}
                      value={masks.money(formik.values.January)}
                      onChange={formik.handleChange} 
                    />
                  </div>
                  <div className="col-md-4">
                    <Label>Fevereiro</Label>
                    <Input 
                      class="form-control" 
                      id='February'
                      type="text" 
                      placeholder="R$ 0,00"
                      isValid={formik.isValid}
                      isTouched={formik.touched.February}
                      invalidFeedback={formik.errors.February}
                      value={masks.money(formik.values.February)}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <Label>Março</Label>
                    <Input 
                      class="form-control" 
                      id='March'
                      type="text" 
                      placeholder="R$ 0,00"
                      isValid={formik.isValid}
                      isTouched={formik.touched.March}
                      invalidFeedback={formik.errors.March}
                      value={masks.money(formik.values.March)}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
                <div className="row col-md-12 mb-4">
                  <div className="col-md-4">
                    <Label>Abril</Label>
                    <Input 
                      class="form-control" 
                      id='April'
                      type="text" 
                      placeholder="R$ 0,00"
                      isValid={formik.isValid}
                      isTouched={formik.touched.April}
                      invalidFeedback={formik.errors.April}
                      value={masks.money(formik.values.April)}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <Label>Maio</Label>
                    <Input 
                      class="form-control" 
                      id='May'
                      type="text" 
                      placeholder="R$ 0,00"
                      isValid={formik.isValid}
                      isTouched={formik.touched.May}
                      invalidFeedback={formik.errors.May}
                      value={masks.money(formik.values.May)}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <Label>Junho</Label>
                    <Input 
                      class="form-control" 
                      id='June'
                      type="text" 
                      placeholder="R$ 0,00"
                      isValid={formik.isValid}
                      isTouched={formik.touched.June}
                      invalidFeedback={formik.errors.June}
                      value={masks.money(formik.values.June)}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
                <div className="row col-md-12 mb-4">
                  <div className="col-md-4">
                    <Label>Julho</Label>
                    <Input 
                      class="form-control" 
                      id='July'
                      type="text" 
                      placeholder="R$ 0,00"
                      isValid={formik.isValid}
                      isTouched={formik.touched.July}
                      invalidFeedback={formik.errors.July}
                      value={masks.money(formik.values.July)}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <Label>Agosto</Label>
                    <Input 
                      class="form-control" 
                      id='August'
                      type="text" 
                      placeholder="R$ 0,00"
                      isValid={formik.isValid}
                      isTouched={formik.touched.August}
                      invalidFeedback={formik.errors.August}
                      value={masks.money(formik.values.August)}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <Label>Setembro</Label>
                    <Input 
                      class="form-control" 
                      id='September'
                      type="text" 
                      placeholder="R$ 0,00"
                      isValid={formik.isValid}
                      isTouched={formik.touched.September}
                      invalidFeedback={formik.errors.September}
                      value={masks.money(formik.values.September)}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
                <div className="row col-md-12 mb-4">
                  <div className="col-md-4">
                    <Label>Outubro</Label>
                    <Input 
                      class="form-control" 
                      id='October'
                      type="text" 
                      placeholder="R$ 0,00"
                      isValid={formik.isValid}
                      isTouched={formik.touched.October}
                      invalidFeedback={formik.errors.October}
                      value={masks.money(formik.values.October)}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <Label>Novembro</Label>
                    <Input 
                      class="form-control" 
                      id='November'
                      type="text" 
                      placeholder="R$ 0,00"
                      isValid={formik.isValid}
                      isTouched={formik.touched.November}
                      invalidFeedback={formik.errors.November}
                      value={masks.money(formik.values.November)}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <Label>Dezembro</Label>
                    <Input 
                      class="form-control" 
                      id='December'
                      type="text" 
                      placeholder="R$ 0,00"
                      isValid={formik.isValid}
                      isTouched={formik.touched.December}
                      invalidFeedback={formik.errors.December}
                      value={masks.money(formik.values.December)}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>

                <div className='d-flex justify-content-end' style={{ marginTop: 20 }}>
                  <Button
                    type='submit'
                    color={isLoading ? 'success' : 'primary'}
                    icon={isLoading ? '': 'Save'}
                    shadow="sm"
                    hoverShadow="sm"
                    isDisable={isLoading}
                  >
                    {isLoading ? (
                      <>
                      <Spinner
                        color="light"
                        inButton
                        isGrow
                        isSmall
                        size={10}
                        tag="span"
                      />
                      Carregando...
                      </>
                    ) : (
                      'Salvar'
                    )}
                  </Button>
                </div>
              </form>
            </CardBody>
          </Card>
        </Page>
      </PageWrapper>


    </>
  );
};

export default Goals;
