import { QueryConstraint, Unsubscribe } from "firebase/firestore";
import StockComplement from "../entities/stock/stockComplement";
import FirestorePipe from "../utils/pipe";
import EstablishmentDB from "./establishment";
import StoreDB from "./store";

class StockComplementDB extends FirestorePipe {

  public static readonly colName = 'StockComplements'

  constructor(idEstab: string, idStore: string) {
    if(!idEstab)
      throw new Error('Informe o id do estabelecimento')
    super(`${EstablishmentDB.colName}/${idEstab}/${StoreDB.colName}/${idStore}/${StockComplementDB.colName}`);
  }

  public define(id: string, data: StockComplement): Promise<any> {
    return this._define(id, data);
  }
  public create(data: StockComplement): Promise<any> {
    return this._create(data);
  }
  public update(id: string, data: StockComplement): Promise<any> {
    return this._update(id, data);
  }
  public delete(id: string): Promise<any> {
    return this._delete(id);
  }
  public getAll(...params: QueryConstraint[]): Promise<StockComplement[]> {
    return this._getAll<StockComplement>(...params);
  }
  public get(id: string): Promise<StockComplement> {
    return this._get(id);
  }
  public on(listener: (data: StockComplement[]) => void, ...params: QueryConstraint[]): Unsubscribe {
    return this._on(listener, ...params);
  }

}

export default StockComplementDB;