import React, { useState, useContext, useEffect, useMemo, useCallback } from 'react';
import { useFormik } from 'formik';
import Button from '../../bootstrap/Button';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import Spinner from '../../bootstrap/Spinner';
import Textarea from '../../bootstrap/forms/Textarea';
import Select from '../../bootstrap/forms/Select';
import { Options } from '../../bootstrap/Option';

import { DefaultContext } from '../../../contexts/default';

import api from '../../../services/api';
import ListCheckbox from '../../MyCustom/ListCheckbox';
import StoreDB from '../../../database/wrappers/store';
import { orderBy } from 'firebase/firestore';
import PreAlert from '../../../helpers/utils/preAlert';

const ModalRegisterNotification = ({ open, setIsOpen, setIsClose, editData, loadUsers }) => {

  const { stores, totenSelected, settotenSelected, accessLevel, establishments, onShowAlert } = useContext(DefaultContext)

  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      stores:[],
      title: '',
      description: '',
    },
    onSubmit: async values => {
      const { title, description,stores } = values;

      const data = {
        stores: stores,
        title: title,
        description: description,
      }

      const onSuccess = () => {
        onShowAlert(PreAlert.success('Notificação enviada com sucesso'))
        loadUsers();
        setIsClose();
      }
      const onError = () => {
        onShowAlert(PreAlert.error('Falha no envio da notificação'))
      }

      setIsLoading(true);
      api.post('routes/users', data)
      .then(onSuccess)
      .catch(onError)
      .finally(() => setIsLoading(false))
    },
  })



  const options = useMemo(() => stores.map(item => ({ value: item.id, text: item.name })), [stores])


  return (
    <Modal
      id={'modal-Cashier-Opening'}
      titleId={'Abertura de caixa'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
      onSubmit={formik.handleSubmit}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="register-user">Disparo de notificações</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <form noValidate onSubmit={formik.handleSubmit}>
          {/* Inputs */}
          <div className="row g-4">

          <FormGroup id="stores" label="Lojas" className='col-md-12'> 
                <Select
                  style={{ cursor: 'pointer' }}
                  id="example"
                  onBlur={formik.handleBlur}
                  multiple={true}
                  onChange={formik.handleChange}
                  isValid={formik.isValid}
                  isTouched={formik.touched.stores}
                  value={formik.values.stores}
                >
                  <Options
                     list={options}
                     onChange={e => settotenSelected(e.target.value)}
                  />
                </Select>
            </FormGroup>

            <FormGroup id="title" label="Titulo" className='col-md-12'>
              <Input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.title}
                isValid={formik.isValid}
                isTouched={formik.touched.title}
                invalidFeedback={formik.errors.title}
                validFeedback='Assim está bom!'
                placeholder='Titulo da notificação'
              />
            </FormGroup>

            <FormGroup id="description" label="Mensagem" className='col-md-12'>
            <Textarea
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
                isValid={formik.isValid}
                isTouched={formik.touched.description}
                invalidFeedback={formik.errors.description}
                validFeedback='Assim está bom!'
                placeholder='mensagem....'
              />
              </FormGroup>
          </div>



          {/* Buttons */}
          <div className="row pt-4">
            <div className="col-md-4 offset-md-8">
              <div className='d-flex justify-content-evenly'>
                <Button
                  color="danger"
                  icon="cancel"
                  rounded={1}
                  onClick={setIsClose}
                  shadow="sm"
                  hoverShadow="sm"
                  size="sm"
                >
                  Cancelar
                </Button>
                {isLoading ? (
                  <Button
                    color="success"
                    rounded={1}
                    hoverShadow="sm"
                    shadow="sm"
                    size="sm"
                  >
                    <Spinner
                      color="light"
                      inButton
                      isGrow
                      isSmall
                      size={10}
                      tag="span"
                    >
                      Carregando...
                    </Spinner>
                    Carregando...
                  </Button>
                ) : (
                  <Button
                    isDisable={!formik.isValid || isLoading}
                    type='submit'
                    color='success'
                    icon="check"
                    rounded={1}
                    hoverShadow="sm"
                    shadow="sm"
                    size="sm"
                  >
                    Confirmar
                  </Button>
                )}
              </div>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default ModalRegisterNotification