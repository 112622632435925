import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../../layout/SubHeader/SubHeader';
import Page from '../../../../layout/Page/Page';
import Button from '../../../../components/bootstrap/Button';
import { demoPages } from '../../../../menu';
import { DefaultContext } from '../../../../contexts/default';
import { orderBy } from 'firebase/firestore';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../../../components/bootstrap/Card';
import TableCustom from '../../../../components/MyCustom/TableCustom';
import showNotification from '../../../../components/extras/showNotification';
import notificationBody from '../../../../helpers/utils/functions/notificationBody';
import Breadcrumb from '../../../../components/bootstrap/Breadcrumb';
import useDarkMode from '../../../../hooks/useDarkMode';
import SelectEstablishment from '../../../../components/MyCustom/SelectEstablishment';
import SelectStore from '../../../../components/MyCustom/SelectStore';
import CouponDB from '../../../../database/wrappers/coupon';
import ModalRegisterCoupon from '../../../../components/modals/Coupon/ModalRegisterCoupon';

const Cupons = () => {
    const { darkModeStatus } = useDarkMode();
    const { estabSelected, storeSelected, onShowQuestion } = useContext(DefaultContext)
    const [openModalRegister, setOpenModalRegister] = useState(false);
    const [cupons, setCupons] = useState([])
    const [couponSelected, setcouponSelected] = useState(null)

    useEffect(() => {
        if (!estabSelected || !storeSelected) return console.error('estabSelected or storeSelected not found');
        const onSubscriber = new CouponDB(estabSelected, storeSelected).on(setCupons, orderBy('name', 'asc'))
        return onSubscriber;
    }, [estabSelected, storeSelected])

    const handleCloseModal = useCallback(() => {
        setOpenModalRegister(false)
    }, [])

    const handleRegister = useCallback(() => {
        setcouponSelected(null);
        setOpenModalRegister(true)
    }, [])

    const handleEdit = useCallback((item) => {
        setcouponSelected(item);
        setOpenModalRegister(true)
    }, [])

    const handleChangeItem = useCallback((id, key, value) => {
        new CouponDB(estabSelected, storeSelected)
            .update(id, {
                [key]: value
            })
            .then(() => showNotification('', notificationBody('Registro alterado com sucesso'), 'success'))
            .catch(() => showNotification('', notificationBody('Falha ao atualizar o registro'), 'danger'))
    }, [estabSelected, storeSelected])

    const handleOpenModalDelete = useCallback((id) => {
        onShowQuestion({
            title: 'Exclusão de Cupom de desconto',
            message: 'Você está excluindo um Cupom de desconto. Está ação é irreversível. Deseja continuar?',
            onConfirm: () => {
                new CouponDB(estabSelected, storeSelected)
                    .delete(id)
                    .then(res => showNotification('', notificationBody('Registro excluído com sucesso'), 'success'))
                    .catch(err => showNotification('', notificationBody('Falha excluir o registro'), 'danger'))
            }
        })
    }, [estabSelected, storeSelected, onShowQuestion])

    const rowsRender = useMemo(() =>
        cupons.map(row => ({
            ...row,
            sellerName:row.sellerName,
            name: row.name,
            cod: row.cod,
            percentage: row.percentage,
            active: row.active,
        }))
        , [cupons])

    const columns = useMemo(() => ([

        {
            label: 'Nome',
            field: 'sellerName',
        },
        {
            label: 'Cupom',
            field: 'name',
        },
        {
            label: 'Local',
            field: 'local',
        },
        {
            label: 'Codigo',
            field: 'cod',
        },
        {
            label: 'Valor (%)',
            field: 'percentage',
            format: row => row.percentage ? row.percentage + ' %' : ''
        },
        {
            label: 'Status',
            field: 'active',
            format: row => (
                <Button
                    isLink
                    color={row.active ? 'success' : 'danger'}
                    icon='Circle'
                    className='d-flex justify-content-center align-items-center text-nowrap'
                    onClick={() => handleChangeItem(row.id, 'active', !row.active)}
                >
                    {row.active ? 'Ativo' : 'Inativo'}
                </Button>
            )
        },
        {
            label: 'Ações',
            field: 'actions',
            format: row => (
                <div className='w-75 d-flex gap-2'>
                    <Button
                        color={darkModeStatus ? 'light' : 'dark'}
                        icon="edit"
                        shadow="sm"
                        hoverShadow="sm"
                        size="sm"
                        onClick={() => handleEdit(row)}
                    />
                    {/* <Button
                        color="danger"
                        icon="Trash"
                        shadow="sm"
                        hoverShadow="sm"
                        size="sm"
                        onClick={() => handleOpenModalDelete(row.id)}
                    /> */}
                </div>
            ),
            noExport: true
        },
    ]), [darkModeStatus, handleOpenModalDelete, handleChangeItem, handleEdit])

    return (
        <>
            <PageWrapper title={demoPages.store.subMenu.cupons.text}>
                <SubHeader>
                    <SubHeaderLeft>
                        <Breadcrumb
                            list={[
                                { title: 'Evento', to: '/event/bilheteria' },
                                { title: 'Cupons', to: '/event/bilheteria' },
                            ]}
                        />
                    </SubHeaderLeft>
                    <SubHeaderRight>
                        <Button
                            color='primary'
                            icon='plus'
                            shadow="sm"
                            hoverShadow="sm"
                            onClick={handleRegister}
                        >
                            Cadastrar
                        </Button>
                    </SubHeaderRight>
                </SubHeader>

                <Page container="fluid">
                    <Card>
                        <CardHeader>
                            <CardLabel icon='confirmationNumber' iconColor={darkModeStatus ? 'light' : 'dark'}>
                                <CardTitle>Cadastro de Cupons</CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody className='table-responsive'>
                            <div className='row'>
                                <SelectEstablishment />
                                <SelectStore />
                            </div>

                            <TableCustom
                                rows={rowsRender}
                                columns={columns}
                                keyExtractor={row => row.id}
                                fileName={'Cadastro-de-cupons'}
                            />
                        </CardBody>
                    </Card>
                </Page>

                <ModalRegisterCoupon
                    open={openModalRegister}
                    setIsOpen={setOpenModalRegister}
                    setIsClose={handleCloseModal}
                    couponSelected={couponSelected}
                />
            </PageWrapper>
        </>
    );
};

export default Cupons;
