import { where } from "firebase/firestore";
import ComplementSold from "../../../database/entities/sales/complementSold";
import ProductSold from "../../../database/entities/sales/productSolds.entity";
import User from "../../../interface/user";
import { ROLE } from "../../../types/roles";

export function mountWhereDateFilter(dataFilter: any, user: User) {
  const firstDate = new Date(dataFilter.firstDate)
  firstDate.setDate(firstDate.getDate() + 1)
  firstDate.setHours(0,0,0);

  const secondDate = new Date(dataFilter.secondDate)
  secondDate.setDate(secondDate.getDate() + 1)
  secondDate.setHours(23,59,59);
  
  const wheres = [
    where('created_at', '>', firstDate),
    where('created_at', '<', secondDate),
  ];
  
  if(dataFilter.store !== '#') 
    wheres.push(where('storeId', '==', dataFilter.store))
  else if(user.role === ROLE.COORDINATOR) 
    wheres.push(where('storeId', 'in', user.stores))

  return wheres;
}

export function processProductsConsolid(productsSold: ProductSold[], productsDicionary: any) {
  const productDicionaryClone = structuredClone(productsDicionary)
  Object.keys(productDicionaryClone).forEach(key => {
    productDicionaryClone[key].id = key
    productDicionaryClone[key].qtd = 0
    productDicionaryClone[key].total = 0
  })
  productsSold.forEach((item: any) => {
    if(!item) return;
    if(productDicionaryClone[item.id]) {
      productDicionaryClone[item.id].qtd += item.qtd
      productDicionaryClone[item.id].total += item.total - item.complements_total
    }
  })
  const rows = Object.keys(productDicionaryClone).map(key => productDicionaryClone[key])
  return rows.filter(row => row.qtd > 0)
}


export function processComplementsConsolid(complementsSold: ComplementSold[], complamentsDicionary: any) {
  const complementDicionaryClone = structuredClone(complamentsDicionary)
  Object.keys(complementDicionaryClone).forEach(key => {
    complementDicionaryClone[key].id = key
    complementDicionaryClone[key].qtd = 0
    complementDicionaryClone[key].total = 0
  })
  complementsSold.forEach(item => {
    if(complementDicionaryClone[item.id]) {
      complementDicionaryClone[item.id].qtd += item.qtd
      complementDicionaryClone[item.id].total += item.qtd * item.price
    }
  })
  const rows = Object.keys(complementDicionaryClone).map(key => complementDicionaryClone[key])
  return rows.filter(row => row.qtd > 0)
}