import React, { memo, useContext, useEffect, useMemo, useState } from 'react'
import moment from 'moment';
import Button from '../../../components/bootstrap/Button';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../../../components/bootstrap/Card';
import Chart from '../../../components/extras/Chart';
import { DefaultContext } from '../../../contexts/default';
import { ResultsContext } from '../../../contexts/results';
import { computeSalesStore } from '../../../helpers/utils/functions/computeStoreSales';
import { computeOrdersIfood } from '../../../helpers/utils/functions/computeOrderIfood';

const GraficoFaturamentoMedio = () => {

  const { storesDicionary } = useContext(DefaultContext)
  const { sales, dataFilter, ordersIfood, ordersRappi } = useContext(ResultsContext)
  
  const [series, setseries] = useState([
    {
      name: 'Loja 1',
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    }
  ]);

  useEffect(() => {
    if(storesDicionary) {
      const storesData = {}
      Object.keys(storesDicionary).forEach(key => {
        storesData[key] = {
          id: storesDicionary[key].id,
          name: storesDicionary[key].name,
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        }
      })

      if (sales) {
        computeSalesStore(storesData, sales);
      }

      // if (ordersIfood) {
      //   computeOrdersIfood(storesData, ordersIfood);
      // }

      // if (ordersRappi) {
      //   computeOrdersIfood(storesData, ordersRappi);
      // }

      const storesDataArray = Object.keys(storesData).map(key => storesData[key]);
      setseries(storesDataArray)
    }    
  },[sales, storesDicionary, ordersIfood])

  const seriesRender = useMemo(() => {
    if(dataFilter.store === '#') return series;
    return series.filter(item => item.id === dataFilter.store)
  }, [series, dataFilter])

  const chartOptions = {
    chart: {
      height: 400,
      type: 'area',
      toolbar: {
        show: false,
      },
    },
    // colors: [process.env.REACT_APP_SUCCESS_COLOR, process.env.REACT_APP_INFO_COLOR],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm',
      },
      theme: 'dark',
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 100],
      },
    },
  };

  function getDate(day) {
    const arr = [];
    for (let i = 0; i < day; i += 1) {
      arr.push(
        moment()
          .add(-1 * i, 'day')
          .format('ll'),
      );
    }
    return arr.reverse();
  }

  const DUMMY_DATA = {
    MONTH: {
      series: seriesRender,
      options: {
        ...chartOptions,
        xaxis: {
          categories: getDate(28),
        },
      },
    },
  };

  const SALE_PER_TAB = {
    DAY: 'Day',
    WEEK: 'Week',
    MONTH: 'Month',
  };
  const [activeSalePerTab, setActiveSalePerTab] = useState(SALE_PER_TAB.MONTH);

  return (
    <Card stretch>
      <CardHeader>
        <CardLabel icon='AccessTime' iconColor='light'>
          <CardTitle>Ranking Faturamento Loja</CardTitle>
        </CardLabel>
        <CardActions>
          <Button
            color='info'
            onClick={() => {}}
            isLink={activeSalePerTab !== SALE_PER_TAB.MONTH}
            isLight={activeSalePerTab === SALE_PER_TAB.MONTH}>
            Mês
          </Button>
        </CardActions>
      </CardHeader>
      <CardBody>
        <Chart
          series={DUMMY_DATA.MONTH.series}
          options={DUMMY_DATA.MONTH.options}
          type={DUMMY_DATA.MONTH.options.chart.type}
          height={DUMMY_DATA.MONTH.options.chart.height}
        />
      </CardBody>
    </Card>
  )
}

export default memo(GraficoFaturamentoMedio);
