export const PAYMENT_TYPE = {
	CARD_DEBIT: 0,
	CARD_CREDIT: 1,
	CASH: 2,
	PRODUCTION: 3,
	COURTESY: 4,
	DIVIDED: 5,
	PIX: 6,
	MEAL_TICKET: 7,
	FREE: 8,
	CASHLESS: 9,
	WEB_RECHARGE: 10,
};
export const PAYMENT_TYPE_PTBR = {
	[PAYMENT_TYPE.CARD_DEBIT]: "Débito",
	[PAYMENT_TYPE.CARD_CREDIT]: "Crédito",
	[PAYMENT_TYPE.CASH]: "Dinheiro",
	[PAYMENT_TYPE.PRODUCTION]: "Produção",
	[PAYMENT_TYPE.COURTESY]: "Cortesia",
	[PAYMENT_TYPE.DIVIDED]: "Dividido",
	[PAYMENT_TYPE.PIX]: "Pix",
	[PAYMENT_TYPE.MEAL_TICKET]: "Vale Refeição",
	[PAYMENT_TYPE.CASHLESS]: "Cashless",
	[PAYMENT_TYPE.FREE]: "Sem consumo",
	[PAYMENT_TYPE.WEB_RECHARGE]: "Recarga Web",
};

export const DELIVERY_PAYMENT = {
	IFOOD_METHOD: 100,
	RAPPI_METHOD: 101,
};

export const PAYMENT_TYPE_DELIVERY = {
	CARD_DEBIT: 'DEBITO',
	CARD_CREDIT: 'CREDITO',
	CASH: 'DINHEIRO',
	PIX: 'PIX',
	MEAL_TICKET: 'VALE',
	RAPPI_PAY: 'RAPPI PAY'
}

export const PAYMENT_TYPE_DELIVERY_PTBR = {
	[PAYMENT_TYPE_DELIVERY.CARD_DEBIT]: "Débito",
	[PAYMENT_TYPE_DELIVERY.CARD_CREDIT]: "Crédito",
	[PAYMENT_TYPE_DELIVERY.CASH]: "Dinheiro",
	[PAYMENT_TYPE_DELIVERY.PIX]: "Pix",
	[PAYMENT_TYPE_DELIVERY.MEAL_TICKET]: "Vale Refeição",
	[PAYMENT_TYPE_DELIVERY.RAPPI_PAY]: "Rappi Pay",
};

export const EAT_OR_TAKE = {
	UNINFORMED: 0,
	EAT_HERE: 1,
	TO_TAKE: 2,
} as const;
