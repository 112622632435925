import Button from "../../bootstrap/Button"
import Modal, { ModalHeader, ModalTitle } from "../../bootstrap/Modal"
import { useContext, useState } from "react"
import { DefaultContext } from "../../../contexts/default"
import api from "../../../services/api"
import StoreDB from "../../../database/wrappers/store"
import PreAlert from "../../../helpers/utils/preAlert"

const ModalImportProducts = ({open, setIsOpen, setIsClose}) => {
    const { establishment, storeSelected, onShowAlert } = useContext(DefaultContext)
    const [loading, setLoading] = useState(false)

    const importProducts = async () => {
        setLoading(true)

        try {
            const storeInfo = await new StoreDB(establishment.id).get(storeSelected)

            await api.post("routesIfood/catalogSyncro", {
                storeInfo: {
                    merchantID: storeInfo.id_loja_ifood,
                    catalogID: storeInfo.id_catalog,
                    establishmentID: establishment.id,
                    storeID: storeInfo.id
                },
            })
            .then(() => {
                
                onShowAlert(PreAlert.success('Produtos importados.'))
            
            })
            .catch((error) => {
                throw error
            })
            } catch (error) {
                setLoading(false)
                onShowAlert(PreAlert.error('erro ao importar.'))
            }
        setLoading(false)
        setIsClose()

    }

    return(
        <Modal
        id={'modal-import-products'}
        titleId={'import IFOOD'}
        isOpen={open}
        setIsOpen={setIsOpen}
        isStaticBackdrop={true}
        isScrollable={true}
        isCentered={true}
        size='lg'
        isAnimation={true}
        >
            <ModalHeader setIsOpen={setIsOpen}>
                <ModalTitle id='import-products'>IMPORTAR</ModalTitle>
            </ModalHeader>
        <>
             <div className='container text-center p-2'>
                <h1>IMPORTAR PRODUTOS IFOOD ?</h1>
            </div>
            {
                loading === true ? 
                <div className='container text-center p-2'>
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
                :
                <div className='container text-center p-2'>
                    <Button type='button' className='btn mx-3 w-25 btn-danger' onClick={() => {setIsClose()} }>NAO</Button>
                    <Button type='button' className='btn mx-3 w-25 btn-success' onClick={() => {importProducts()} }>SIM</Button> 
                </div>
            }
            
        </>
           
        </Modal>
    )
}

export default ModalImportProducts