import { QueryConstraint, Unsubscribe } from "firebase/firestore";
import FirestorePipe from "../utils/pipe";
import EstablishmentDB from "./establishment";
import { OrderRappi } from "../../types/orderRappi";

class OrdersRappiDB extends FirestorePipe {

  public static readonly colName = 'OrdersRappi'

  constructor(idEstab: string) {
    if(!idEstab)
      throw new Error('Informe o id do estabelecimento')
    super(`${EstablishmentDB.colName}/${idEstab}/${OrdersRappiDB.colName}`);
  }

  public async create(data: any): Promise<any> {
    throw new Error('Method not exists')
  }
  public async update(id: string, data: any): Promise<any> {
    throw new Error('Method not exists')
  }
  public delete(id: string): Promise<any> {
    throw new Error('Method not exists')
  }
  public getAll(...params: QueryConstraint[]): Promise<OrderRappi[]> {
    return this._getAll<any>(...params);
  }
  public get(id: string): Promise<any> {
    return this._get(id);
  }
  public on(listener: (data: any[]) => void, ...params: QueryConstraint[]): Unsubscribe {
     return this._on(listener, ...params); 
  }

}

export default OrdersRappiDB;