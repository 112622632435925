import Complement from "../../../database/entities/pdv/complement.entity";
import Product from "../../../database/entities/pdv/product.entity";
import ComplementSold from "../../../database/entities/sales/complementSold";
import Consumption from "../../../database/entities/sales/consumption.entity";
import ProductSold from "../../../database/entities/sales/productSolds.entity";

class ConsumptionsProcess {

  private productsSold: ProductSold[] = []
  private complementsSold: ComplementSold[] = []

  constructor(datas: Consumption[]) {
    datas.forEach((sale: Consumption) => {
      if(sale.reversed) return;
      sale.products.forEach((product: any) => {
        if(product.reversed) return;
        product.complements.forEach((comp: any) => {
          comp.qtd = comp.qtd * product.qtd
        })

        this.complementsSold.push(...product.complements)
        // return product;
      })

      this.productsSold.push(...sale.products)
    })
  }

  getProductsSold(): ProductSold[] {
    return this.productsSold;
  }

  getComplementsSold(): ComplementSold[] {
    return this.complementsSold;
  }

}

export default ConsumptionsProcess;