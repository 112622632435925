import React, { useState, useContext, useEffect } from 'react';
import { useFormik } from 'formik';
import Button from '../../bootstrap/Button';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import Label from '../../bootstrap/forms/Label';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import Spinner from '../../bootstrap/Spinner';
// Database
import PrinterDB from '../../../database/wrappers/printer';
import ListProducts from '../../MyCustom/ListProducts';
import ProductDB from '../../../database/wrappers/product';
import { where } from 'firebase/firestore';
import { DefaultContext } from '../../../contexts/default';
import PreAlert from '../../../helpers/utils/preAlert';


const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Este campo é necessário';
  }

  if (!values.active) {
    errors.active = 'Você precisa escolher o status da Impressora';
  }

  return errors;
}

const ModalRegisterPrinters = ({ open, setIsOpen, setIsClose, printersSelected }) => {

  const { establishment, store, onShowAlert } = useContext(DefaultContext)

  const [isLoading, setIsLoading] = useState(false);
  const [products, setproducts] = useState([]);

  useEffect(() => {
    if (!open) return formik.resetForm();
    if (printersSelected) {
      const { name, active, products } = printersSelected;

      formik.setValues({
        name,
        active: active ? 'ativo' : 'inativo',
        products,
      });
    }
  }, [printersSelected, open])

  useEffect(() => {
    if (establishment) {
      function sort(a, b) {
        if(a.name > b.name) return 1;
        if(a.name < b.name) return -1;
        return 0;
      }
      async function getProducts() {
        const products = await new ProductDB(establishment.id).getAll(where('active', '==', true));
        products.sort(sort)
        setproducts(products);
      }

      getProducts();
    }
  }, [establishment])

  const formik = useFormik({
    initialValues: {
      name: '',
      active: '',
      products: [],
    },
    validate,
    onSubmit: async values => {
      const { name, active, products } = values;

      const data = {
        name,
        active: active === 'ativo',
        products,
      }

      const onSuccess = () => {
        onShowAlert(PreAlert.success('Registro cadastrado com sucesso'))
        setIsClose();
      }
      const onSuccessUpdate = () => {
        onShowAlert(PreAlert.success('Registro atualizado com sucesso'))
        setIsClose();
      }
      const onError = () => {
        onShowAlert(PreAlert.error('Falhou ao cadastrar o registro'))
      }

      setIsLoading(true);
      if (printersSelected) {
        new PrinterDB(establishment.id, store.id)
          .update(printersSelected.id, data)
          .then(onSuccessUpdate)
          .catch(onError)
          .finally(() => setIsLoading(false))
      } else {
        new PrinterDB(establishment.id, store.id)
          .create(data)
          .then(onSuccess)
          .catch(onError)
          .finally(() => setIsLoading(false))
      }
    },
  })

  return (
    <Modal
      id={'modal-register-printers'}
      titleId={'Cadastro de Impressoras'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
      onSubmit={formik.handleSubmit}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="register-printer">{printersSelected ? 'Atualização de Impressoras' : 'Cadastro de Impressoras'}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <form noValidate onSubmit={formik.handleSubmit}>

          {/* Inputs */}
          <div className="row g-4">
            {/* Nome */}
            <FormGroup id="name" label="Nome" className='col-md-8'>
              <Input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                isValid={formik.isValid}
                isTouched={formik.touched.name}
                invalidFeedback={formik.errors.name}
                validFeedback='Assim está bom!'
                placeholder='Eletrodomésticos, lâmpadas, etc...'
              />
            </FormGroup>
            {/* Status */}
            <FormGroup className='col-md-4'>
              <Label>Status</Label>
              <ChecksGroup
                isValid={formik.isValid}
                isTouched={formik.touched.active}
                invalidFeedback={formik.errors.active}
              >
                <Checks
                  type="radio"
                  id="active"
                  label="Ativo"
                  name="active"
                  value="ativo"
                  onChange={formik.handleChange}
                  checked={formik.values.active}
                  isInline
                />
                <Checks
                  type="radio"
                  id="active2"
                  label="Inativo"
                  name="active"
                  value="inativo"
                  onChange={formik.handleChange}
                  checked={formik.values.active}
                  isInline
                />
              </ChecksGroup>
            </FormGroup>
          </div>
          {/* List */}
          <ListProducts
            products={products}
            productsSelected={formik.values.products}
            onChange={formik.handleChange}
          />
          {/* Buttons */}
          <div className="row pt-4">
            <div className="col-md-4 offset-md-8">
              <div className='d-flex justify-content-evenly'>
                <Button
                  color="danger"
                  icon="cancel"
                  rounded={1}
                  onClick={setIsClose}
                  shadow="sm"
                  hoverShadow="sm"
                  size="sm"
                >
                  Cancelar
                </Button>
                {isLoading ? (
                  <Button
                    color="success"
                    rounded={1}
                    hoverShadow="sm"
                    shadow="sm"
                    size="sm"
                  >
                    <Spinner
                      color="light"
                      inButton
                      isGrow
                      isSmall
                      size={10}
                      tag="span"
                    >
                      Carregando...
                    </Spinner>
                    Carregando...
                  </Button>
                ) : (
                  <Button
                    isDisable={!formik.isValid && !!formik.submitCount}
                    type='submit'
                    color='success'
                    icon="check"
                    rounded={1}
                    hoverShadow="sm"
                    shadow="sm"
                    size="sm"
                  >
                    Confirmar
                  </Button>
                )}
              </div>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default ModalRegisterPrinters;