import { memo, useCallback, useEffect, useMemo, useState } from "react"
import Money from "../../../../helpers/utils/money/money";
import Button from "../../../bootstrap/Button";
import FormGroup from "../../../bootstrap/forms/FormGroup";
import Input from "../../../bootstrap/forms/Input";
import Modal, { ModalBody, ModalHeader, ModalTitle } from "../../../bootstrap/Modal";
import Icon from "../../../icon/Icon";
import ListSubCategoryItemsModal from '../../Categories/ListSubCategoryItems'

function ProductItem({product}) {
  return(
    <div className='row g-4 py-2'>
      <div className='col-md-1'>
        <img
          src={product.image_url}
          alt='Imagem'
          width={40}
          height={40}
        />
      </div>
      <div className='col-md-7'>
        <FormGroup>
          <Input value={product.name} disabled readOnly />
        </FormGroup>
      </div>
      <div className='col-md-2'>
        <FormGroup>
          <Input
            value={Money.centsToCoin(product.price).toFixed(2)}
            readOnly
          />
        </FormGroup>
      </div>
      <div className='col-md-2'>
        <FormGroup>
          <Input
            value={Money.centsToCoin(product.discount).toFixed(2)}
            readOnly
          />
        </FormGroup>
      </div>
    </div>
  );
}

function SubCategoryItem({category, showSubCategory}) {
  
  const onShowSubCategory = useCallback(() => showSubCategory(category),[category])

  return(
    <div className='row g-4 py-2'>
      <div className='col-md-1'>
        <img
          src={category.image_url}
          alt='Imagem'
          width={40}
          height={40}
        />
      </div>
      <div className='col-md-10'>
        <FormGroup>
          <Input value={category.name} disabled readOnly />
        </FormGroup>
      </div>
      <div className='col-md-1'>
        <a href='#' onClick={onShowSubCategory}>
          <Icon
            style={{ marginTop: 5 }}
            icon='RemoveRedEye'
            size='2x'// null || 'sm' || 'md' || 'lg' || '2x' || '3x' || '4x' || '5x' || '6x' || '7x' || '8x' || '9x' || '10x'
          />
        </a>
      </div>
    </div>
  );
}

const ProductElement = memo(ProductItem);

function ListCategoryItemsModal({ open, onClose, products, subCategories, category, onChangeShowSubCategory }) {

  const [productsRender, setproductsRender] = useState([])
  const [subCategoriesRender, setsubCategoriesRender] = useState([])
  const [subcategoryShow, setsubcategoryShow] = useState(null)
  const [showSubCategoryModal, setshowSubCategoryModal] = useState(false)

  useEffect(() => {
    if(category) {
      function mountProductsRender() {
        const productsInCategory = category.products;

        const productsRender = [];
        productsInCategory.forEach(productId => {
          const pFind = products.find(p => p.id === productId);
          if(pFind) {
            productsRender.push({
              name: pFind.name,
              image_url: pFind.image_url,
              price: pFind.price,
              discount: pFind.discount,
            })
          }
        })

        setproductsRender(productsRender)
      }
      function mountSubCategoriesRender() {
        const subCategoryInCategory = category.subCategories;

        const subCategoriesRender = [];
        subCategoryInCategory.forEach(id => {
          const subCatFind = subCategories.find(subCat => subCat.id === id);
          if(subCatFind) {
            subCategoriesRender.push(subCatFind)
          }
        })

        setsubCategoriesRender(subCategoriesRender)
      }

      mountProductsRender();
      mountSubCategoriesRender();
    }
  },[products, category, subCategories])

  const onShowSubCategory = useCallback((category) => {
    setsubcategoryShow(category)
    onChangeShowSubCategory(true)
    setshowSubCategoryModal(true)
  },[])

  const onCloseSubCategory = useCallback(() => {
    onChangeShowSubCategory(false)
    setshowSubCategoryModal(false)
  },[])

  const HeaderSubCategories = useMemo(() => (
    <div className='row g-4 py-2'>
      <div className='col-md-1'>
        <p>Imagem</p>
      </div>
      <div className='col-md-10'>
        <p>Nome</p>
      </div>
    </div>
  ), [])

  const Header = useMemo(() => (
    <div className='row g-4 py-2'>
      <div className='col-md-1'>
        <p>Imagem</p>
      </div>
      <div className='col-md-7'>
        <p>Nome</p>
      </div>
      <div className='col-md-2'>
        <p>Preço</p>
      </div>
      <div className='col-md-2'>
        <p>Desconto</p>
      </div>
    </div>
  ), [])

  return(
    <>
    <Modal
      id={'modal-category-items'}
      titleId={'Items da Sub-categoria'}
      isOpen={open}
      setIsOpen={onClose}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="lg" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
    >
      <ModalHeader 
        style={showSubCategoryModal ? { opacity: 0.1, backgroundColor: 'black' } : {}}
        setIsOpen={onClose}
      >
        <ModalTitle id="register-category">{category?.name}</ModalTitle>
      </ModalHeader>
      <ModalBody style={showSubCategoryModal ? { opacity: 0.1, backgroundColor: 'black' } : {}}>
        <div className='row g-4 mt-1'>
          <h5>Sub-Categorias</h5>
        </div>

        { HeaderSubCategories }

        {subCategoriesRender.map((item, index) => (
          <SubCategoryItem
            key={'catLiSubCat' + index}
            category={item}
            showSubCategory={onShowSubCategory}
          />
        ))}

        <div className='row g-4 mt-1'>
          <h5>Produtos</h5>
        </div>

        { Header }

        {productsRender.map((product, index) => (
          <ProductElement
            key={'prodLi' + index}
            product={product}
          />
        ))}

        {/* Buttons */}
        <div className="d-flex flex-row-reverse" style={{ marginTop: 10 }}>
          <Button
            color="danger"
            icon="cancel"
            rounded={1}
            onClick={onClose}
            shadow="sm"
            hoverShadow="sm"
            size="sm"
          >
            Fechar
          </Button>
        </div>
      </ModalBody>
    </Modal>

    <ListSubCategoryItemsModal
      open={showSubCategoryModal}
      onClose={onCloseSubCategory}
      products={products}
      category={subcategoryShow}
    />
    </>
  );
}

export default memo(ListCategoryItemsModal);