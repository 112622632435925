import { Timestamp, addDoc, collection, getFirestore, serverTimestamp } from 'firebase/firestore';
import app from '../config';
const firestore = getFirestore(app);

class LogFiscalReemissao {
  private readonly collection = 'LogFiscalReemissao';
  private idEstab: string;
  private idStore: string;
  private userId: string;

  constructor(idEstab: string, idStore: string, userId: string) {
    if (!idEstab) {
      throw new Error('Informe o id do estabelecimento');
    }
    if (!idStore) {
      throw new Error('Informe o id da loja');
    }
    if (!userId) {
      throw new Error('Informe o id do usuário');
    }
    this.idEstab = idEstab;
    this.idStore = idStore;
    this.userId = userId;
  }

  async send(dataSent: any, fiscal: any, id: string) {
    try {
      const docRef = await addDoc(collection(firestore, `Establishments/${this.idEstab}/${this.collection}`), {
        storeId: this.idStore,
        userId: this.userId,
        dataSent: dataSent === undefined ? null : JSON.stringify(dataSent),
        local_at: Timestamp.now(),
        created_at: serverTimestamp(),
        payId: id,
        fiscal,
        by: 'Dashboard'
      });
    } catch (error) {
      console.error('Erro: ', error);
    }
  }
}

export default LogFiscalReemissao;
